<template>
  <Toast />
  <div class="card-header">
    <h4>Tipo de Documentos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Usuarios -->
      <DataTable ref="dt" :value="tipoDocumentos" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" :filters="filters"
        v-model:selection="selectedTipoDocumentos" responsiveLayout="scroll" breakpoint="990px">

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>

            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
              &nbsp;
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectedTipoDocumentos || !selectedTipoDocumentos.length" />
              &nbsp;
              <Button label="Descargar" icon="pi pi-download" class="p-button-help" @click="exportCSV($event)" />
            </template>
          </Toolbar>
        </template>

        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

        <Column field="codigo" header="Código" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.codigo }}
            </span>
          </template>
        </Column>

        <Column field="nombre" header="Nombre" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </template>
        </Column>

        <Column field="codigo_sii" header="Código SII" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.codigo_sii }}
            </span>
          </template>
        </Column>
        
        <Column field="folio_actual" header="Folio Actual" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio_actual }}
            </span>
          </template>
        </Column>
        
        <Column field="folio_desde" header="Folio Desde" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio_desde }}
            </span>
          </template>
        </Column>
        
        <Column field="folio_hasta" header="Folio Hasta" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio_hasta }}
            </span>
          </template>
        </Column>

        <Column field="vigente" header="Estado" >
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>   
        </Column>
        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editTipoDocumento(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteTipoDocumento(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario TipoDocumentos en modal -->
    <Dialog v-model:visible="tipoDocumentoDialog" :style="{ width: '50%' }" header="Tipo de Documento" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-1 field">
            <label for="codigo" class="mt-3">Código</label>
            <InputText id="codigo" v-model="v$.codigo.$model"
              :class="{ 'p-invalid': v$.codigo.$invalid && submitted }" aria-describedby="codigo-error" autofocus
              autocomplete="off" />
            <span v-if="v$.codigo.$error && submitted">
              <span id="codigo-error" v-for="(error, index) of v$.codigo.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.codigo.$invalid && submitted) || v$.codigo.$pending.$response" class="p-error">
              {{ v$.codigo.required.$message.replace('Value', 'Código') }}</small>
          </div> 
          <div class="col-md-1 field">
            <label for="codigo_sii" class="mt-3">Código SII</label>
            <InputText id="codigo_sii" v-model="v$.codigo_sii.$model"
              :class="{ 'p-invalid': v$.codigo_sii.$invalid && submitted }" aria-describedby="codigo_sii-error" autofocus
              autocomplete="off" />
            <span v-if="v$.codigo_sii.$error && submitted">
              <span id="codigo_sii-error" v-for="(error, index) of v$.codigo_sii.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.codigo_sii.$invalid && submitted) || v$.codigo_sii.$pending.$response" class="p-error">
              {{ v$.codigo_sii.required.$message.replace('Value', 'Código') }}</small>            
          </div>          
          <div class="col-md-10 field">
            <label for="nombre" class="mt-3">Nombre</label>
            <InputText id="nombre" v-model="v$.nombre.$model"
              :class="{ 'p-invalid': v$.nombre.$invalid && submitted }" aria-describedby="nombre-error" autofocus
              autocomplete="off" />
            <span v-if="v$.nombre.$error && submitted">
              <span id="nombre-error" v-for="(error, index) of v$.nombre.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              {{ v$.nombre.required.$message.replace('Value', 'Nombre') }}</small>
          </div>                 
        </div>
        <div class="row mt-4">
            <Divider align="left" type="solid">
              <b class="text-muted">Control de Folios</b>
            </Divider>
        </div>
        <div class="row">
          <div class="col-md-4 field">
            <label for="folio_actual">Folio Actual</label>
            <InputNumber id="folio_actual" v-model="v$.folio_actual.$model" :class="{ 'p-invalid': v$.folio_actual.$invalid && submitted }"
              aria-describedby="folio_actual-error" autofocus autocomplete="off" />
            <span v-if="v$.folio_actual.$error && submitted">
              <span id="folio_actual-error" v-for="(error, index) of v$.folio_actual.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.folio_actual.$invalid && submitted) || v$.folio_actual.$pending.$response" class="p-error">
              {{ v$.folio_actual.required.$message.replace('Value', 'Nombre') }}</small>
          </div>
          <div class="col-md-4 field">
            <label for="folio_desde">Folio Desde</label>
            <InputNumber id="folio_desde" v-model="v$.folio_desde.$model" :class="{ 'p-invalid': v$.folio_desde.$invalid && submitted }"
              aria-describedby="folio_desde-error" autofocus autocomplete="off" />
            <span v-if="v$.folio_desde.$error && submitted">
              <span id="folio_desde-error" v-for="(error, index) of v$.folio_desde.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.folio_desde.$invalid && submitted) || v$.folio_desde.$pending.$response" class="p-error">
              {{ v$.folio_desde.required.$message.replace('Value', 'Nombre') }}</small>
          </div>   
          <div class="col-md-4 field">
            <label for="folio_hasta">Folio Hasta</label>
            <InputNumber id="folio_hasta" v-model="v$.folio_hasta.$model" :class="{ 'p-invalid': v$.folio_hasta.$invalid && submitted }"
              aria-describedby="folio_hasta-error" autofocus autocomplete="off" />
            <span v-if="v$.folio_hasta.$error && submitted">
              <span id="folio_hasta-error" v-for="(error, index) of v$.folio_hasta.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.folio_hasta.$invalid && submitted) || v$.folio_hasta.$pending.$response" class="p-error">
              {{ v$.folio_hasta.required.$message.replace('Value', 'Nombre') }}</small>
          </div>                 
        </div>

        <div class="row">        
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveTipoDocumento" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un tipoDocumento -->
    <Dialog v-model:visible="deleteTipoDocumentoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="tipoDocumento"> ¿Está seguro de borrar el Tipo de Documento?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteTipoDocumentoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteTipoDocumento" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente tipoDocumentos -->
    <Dialog v-model:visible="deleteTipoDocumentosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedTipoDocumentos"> ¿Está seguro de borrar los tipos de documentos seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteTipoDocumentosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedTipoDocumentos" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useTipoDocumentos from '../../composables/cruds/tdocumentos'
import { reactive, onMounted } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      tipoDocumento,
      tipoDocumentos,
      dt, 
      loading, 
      saving,
      filters,
      selectedTipoDocumentos,
      deleteTipoDocumentosDialog,
      deleteTipoDocumentoDialog,
      tipoDocumentoDialog,
      submitted,
      storeTipoDocumento,      
      deleteSelectedTipoDocumentos,
      getCRUD,
      deleteTipoDocumento
    } = useTipoDocumentos()

    const form = reactive({
      codigo: '',
      codigo_sii: '',
      nombre: '',
      folio_desde: '',
      folio_hasta: '',
      folio_actual: '',
      observacion: '',
      vigente: ''
    })

    const rules = {
      codigo: { required: helpers.withMessage('El campo es requerido', required)},
      codigo_sii: { required: helpers.withMessage('El campo es requerido', required)},
      nombre: { required: helpers.withMessage('El campo es requerido', required)},
      folio_desde: { required: helpers.withMessage('El campo es requerido', required), },
      folio_hasta: { required: helpers.withMessage('El campo es requerido', required), },
      folio_actual: { required: helpers.withMessage('El campo es requerido', required), },
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      // console.info(dt.value)
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteTipoDocumentosDialog.value = true
    }

    const confirmDeleteTipoDocumento = (desp) => {
      tipoDocumento.value = desp
      deleteTipoDocumentoDialog.value = true
    }

    const openNew = () => {
      tipoDocumento.value = {}
      submitted.value = false
      tipoDocumentoDialog.value = true
      saving.value = false

      form.codigo = ''
      form.nombre = ''
      form.codigo_sii = ''
      form.folio_desde = ''
      form.folio_hasta = ''
      form.folio_actual = ''
      form.vigente = true

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const hideDialog = () => {
      tipoDocumentoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const editTipoDocumento = (desp) => {
      tipoDocumento.value = { ...desp }
      tipoDocumentoDialog.value = true

      form.codigo = tipoDocumento.value.codigo
      form.nombre = tipoDocumento.value.nombre
      form.codigo_sii = tipoDocumento.value.codigo_sii
      form.folio_actual = tipoDocumento.value.folio_actual
      form.folio_desde = tipoDocumento.value.folio_desde
      form.folio_hasta = tipoDocumento.value.folio_hasta
      form.vigente = tipoDocumento.value.vigente
      
      form.created_at = tipoDocumento.value.created_at
      form.created_id = tipoDocumento.value.created_id
      form.updated_at = tipoDocumento.value.updated_at
      form.updated_id = tipoDocumento.value.updated_id
    }

    const saveTipoDocumento = () => {
      submitted.value = true
      if(!v$._value.$invalid)
        storeTipoDocumento(form)  
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      tipoDocumento,
      tipoDocumentos,
      filters,
      selectedTipoDocumentos,
      deleteTipoDocumentosDialog,
      deleteTipoDocumentoDialog,
      tipoDocumentoDialog,
      submitted,
      v$,
      form,
      hideDialog,
      openNew,
      confirmDeleteTipoDocumento,
      deleteSelectedTipoDocumentos,
      deleteTipoDocumento,
      exportCSV,
      confirmDeleteSelected,
      editTipoDocumento,
      saveTipoDocumento,
      storeTipoDocumento
    }
  },
}
</script>