<template>
  <Toast />
  <div class="card-header">
    <h4>Precios Proveedores</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>
      <!-- DT Pprecios -->
      <DataTable ref="dt" :value="pprecios" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" 
      removableSort :globalFilterFields="['id','proveedor.rut_dv','proveedor.razon_social','producto.tipoMadera.nombre', 'producto.nombre','origen.descripcion', 'vpromedio']" sortMode="multiple" dataKey="id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        v-model:selection="selectedPprecios" responsiveLayout="scroll" breakpoint="990px">
        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>

            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
            </template>
          </Toolbar>
        </template>

        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>        

        <Column field="proveedor.id" header="Cod." sortable :hidden="true">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.id }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>
        </Column>

        <Column field="proveedor.rut_dv" header="RUT" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.proveedor.rut_dv }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="proveedor.razon_social" header="Proveedor" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.proveedor.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="producto.tipoMadera.nombre" header="Tipo Madera" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.producto.tipoMadera.nombre.toUpperCase()" severity="info" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>                  
        </Column>      

        <Column field="producto.nombre" header="Producto"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.producto.nombre }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="origen.descripcion" header="Origen" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.origen.descripcion.slice(0, 23) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="vpromedio" header="Valor M3" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.vpromedio)}}
            </span>            
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>            

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editPprecio(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeletePprecio(slotProps.data)" />&nbsp;
            <Button icon="pi pi-print" class="p-button-rounded p-button-info"/>
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario Pprecios en modal -->
    <Dialog v-model:visible="pprecioDialog" :style="{ width: '100%' }" header="Valores Proveedores" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">
          <div :class="estrozo ? 'col-md-8' : 'col-md-12'" class="field">
            <div class="row">
              <div class="col-md-6 field">
                <label for="tipoMadera" class="mt-3">Tipo Madera</label>
                <AutoComplete id="tipoMadera" v-model="v$.tipoMadera.$model"
                  :class="{ 'p-invalid': v$.tipoMadera.$invalid && submitted }" :suggestions="filteredTipoMaderas"
                  @complete="searchTipoMadera($event)" field="_nombre" :dropdown="true" forceSelection
                  aria-describedby="tipoMadera-error" autofocus autocomplete="off" @change="setProducto($event, index)"/>
                <span v-if="v$.tipoMadera.$error && submitted">
                  <span id="tipoMadera-error" v-for="(error, index) of v$.tipoMadera.$errors" :key="index">
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small v-else-if="((v$.tipoMadera.$invalid && submitted) || v$.tipoMadera.$pending.$response)"
                  class="p-error">
                  {{ v$.tipoMadera.required.$message.replace('Value', 'Tipo Madera') }}</small>
              </div>
              
              <div class="col-md-6 field">
                <label for="producto" class="mt-3">Producto</label>
                <AutoComplete id="producto" v-model="v$.producto.$model"
                  :class="{ 'p-invalid': v$.producto.$invalid && submitted }" :suggestions="filteredProductos"
                  @complete="searchProducto($event)" field="_nombre" :dropdown="true" forceSelection
                  aria-describedby="producto-error" autofocus autocomplete="off" :disabled="ronly" />
                <span v-if="v$.producto.$error && submitted">
                  <span id="producto-error" v-for="(error, index) of v$.producto.$errors" :key="index">
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small v-else-if="((v$.producto.$invalid && submitted) || v$.producto.$pending.$response)"
                  class="p-error">
                  {{ v$.producto.required.$message.replace('Value', 'Madera') }}</small>
              </div>                             
            </div>             
            <div class="row">
              <div class="col-md-12 field">
                <label for="proveedor" class="mt-3">Proveedor</label>
                <AutoComplete id="proveedor" v-model="v$.proveedor.$model"
                  :class="{ 'p-invalid': v$.proveedor.$invalid && submitted }" :suggestions="filteredProveedores"
                  @complete="searchProveedor($event)" field="_razon_social" :dropdown="true" forceSelection
                  aria-describedby="proveedor-error" autofocus autocomplete="off" />
                <span v-if="v$.proveedor.$error && submitted">
                  <span id="proveedor-error" v-for="(error, index) of v$.proveedor.$errors" :key="index">
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small v-else-if="((v$.proveedor.$invalid && submitted) || v$.proveedor.$pending.$response)"
                  class="p-error">
                  {{ v$.proveedor.required.$message.replace('Value', 'Proveedor') }}</small>
              </div> 
            </div>           
      
            <div class="row">
              <div class="col-md-10 field">
                <label for="origen" class="mt-3">Origen</label>
                <AutoComplete id="origen" v-model="v$.origen.$model"
                  :class="{ 'p-invalid': v$.origen.$invalid && submitted }" :suggestions="filteredOrigenes"
                  @complete="searchOrigen($event)" field="_descripcion" :dropdown="true" forceSelection
                  aria-describedby="origen-error" autofocus autocomplete="off" />
                <span v-if="v$.origen.$error && submitted">
                  <span id="origen-error" v-for="(error, index) of v$.origen.$errors" :key="index">
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small v-else-if="((v$.origen.$invalid && submitted) || v$.origen.$pending.$response)"
                  class="p-error">
                  {{ v$.origen.required.$message.replace('Value', 'Origen') }}</small>
              </div>
              <div class="col-md-2 field">
                <label for="precio" class="mt-3">V.Promedio</label>
                <InputNumber id="precio" v-model.trim="form.vpromedio" :disabled="form.tipoMadera.id === 9" :readonly="form.tipoMadera.id === 9"/>
              </div>              
            </div>
            <div class="row">
              <div class="col-md-12 field">
                <label for="observacion" class="mt-3">Observaciones</label>
                <Textarea autoResize id="observacion" v-model.trim="form.observacion" />
              </div>                   
            </div>
            <div class="row" v-if="estrozo">
              <div class="col-md-8 mt-4" style="text-align: left;">&nbsp;</div>
              <div class="col-md-4 mt-4" style="text-align: left;">
                <FileUpload  mode="basic" name="file_precios" uploadIcon="pi pi-dollar"
                  label="Cargar Precios" chooseLabel="Cargar Precios" class="p-button-warning mr-2 inline-block"
                  @uploader="uploadPrecios" :customUpload="true" />&nbsp;
              </div>
            </div>            
          </div>
          <div :class="estrozo ? 'col-md-4' : ''" class="field" v-if="estrozo">
            <div class="row mt-4">
              <div class="col-md-12 field">
                <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
              </div>
            </div>            
            <div class="card p-fluid table_modal">
              <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="700px" editMode="cell" @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table">
                <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :hidden="col.hidden" style="max-width: 80px">
                  <template #editor="{ data, field }">
                    <template v-if="field !== 'producto'">
                      <InputNumber else v-model="data[field]" autofocus :disabled="field === 'total_mask'"/>
                    </template>
                    <template v-else>
                      <AutoComplete v-model="data[field]" optionLabel="nombre"  :optionValue="data[field]" :suggestions="filteredProductos" @complete="searchProducto($event)"/>
                    </template>
                  </template>
                </Column>
                <Column style="max-width: 30px">
                  <template #body="{ index }">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" size="small" @click="toggleDelete(index)"/>
                  </template>
                </Column>             
              </DataTable>
            </div>          
          </div>
        </div>
      </form>

      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePprecio" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un pprecio -->
    <Dialog v-model:visible="deletePprecioDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="pprecio"> ¿Está seguro de borrar el pprecio?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePprecioDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deletePprecio" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente pprecios -->
    <Dialog v-model:visible="deletePpreciosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedPprecios"> ¿Está seguro de borrar los pprecios seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePpreciosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedPprecios" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import usePprecios from '../../composables/recepciones/pprecios'
import { ref, reactive, onMounted, computed, isProxy, toRaw} from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import readXlsxFile from 'read-excel-file'

export default {
  setup() {
    const {
      pprecio,
      tipoMadera,
      producto,
      pprecios,
      proveedores,
      tipoMaderas,
      productos,
      origenes,
      columns,
      dt, 
      loading, 
      saving,
      filters,
      selectedPprecios,
      deletePpreciosDialog,
      deletePprecioDialog,
      pprecioDialog,
      submitted,
      filteredProveedores,
      filteredTipoMaderas,
      filteredProductos,
      filteredOrigenes,
      lineas,
      messages,
      storePprecio,      
      deleteSelectedPprecios,
      getCRUD,
      deletePprecio,
      selectedProductos,
      ronly
    } = usePprecios()

    const estrozo = ref(false)

    const form = reactive({
      proveedor: '',
      origen: '',      
      tipoMadera: '',
      producto: '',
      observacion: '',
      detalles: [],
      vpromedio: 0
    })
  
    const openNew = () => {
      ronly.value = true
      pprecio.value = {}
      submitted.value = false
      pprecioDialog.value = true
      saving.value = false
      lineas.value = []

      form.proveedor = ''
      form.origen = ''      
      form.tipoMadera = ''
      form.producto = ''
      form.valor_m3 = 0
      form.observacion = ''
      form.detalles = []
      form.vpromedio = 0
      estrozo.value = false
    }

    const setProducto = (event) => {
      selectedProductos.value = []
      form.producto = null
      productos.value.map((val) => { if(val.tipoMadera.id === event.value.id) selectedProductos.value.push(val) })  
      ronly.value = false
      estrozo.value = event.value.id === 9 
      console.info(estrozo.value)
    }

    const editPprecio = (emp) => {      
      lineas.value = []
      selectedProductos.value = []
      ronly.value = true
      pprecio.value = { ...emp }
      pprecioDialog.value = true
      form.value = {} 
      form.vpromedio = emp.vpromedio
      estrozo.value = emp.producto.tipoMadera.id === 9 

      let detalles = isProxy(pprecio.value.precioDiametros) ? toRaw(pprecio.value.precioDiametros) : []

      lineas.value = detalles.reduce((acc, ln) => {
        acc.push({
          'diametro' : ln.diametro,
          'valor_m3' : ln.valor_m3    
        });
        return acc;
      }, [])

      form.detalles = lineas.value
      form.proveedor = pprecio.value.proveedor
      form.origen = pprecio.value.origen
      form.tipoMadera = pprecio.value.producto.tipoMadera
      form.producto = pprecio.value.producto
      form.valor_m3 = 0     
      form.observacion = pprecio.value.observacion

      form.created_at = pprecio.value.created_at
      form.created_id = pprecio.value.created_id
      form.updated_at = pprecio.value.updated_at
      form.updated_id = pprecio.value.updated_id
    }

    const rules = {
      proveedor: { required: helpers.withMessage('El campo es requerido!', required), },
      origen: { required: helpers.withMessage('El campo es requerido!', required), },                          
      tipoMadera: { required: helpers.withMessage('El campo es requerido!', required), },                 
      producto: { required: helpers.withMessage('El campo es requerido!', required), },                 
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deletePpreciosDialog.value = true
    }

    const confirmDeletePprecio = (emp) => {
      pprecio.value = emp
      deletePprecioDialog.value = true
    }

    const hideDialog = () => {
      pprecioDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const searchProveedor = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProveedores.value = [...proveedores.value]
        }
        else {
          filteredProveedores.value = proveedores.value.filter((proveedor) => {
            return proveedor._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTipoMadera = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoMaderas.value = [...tipoMaderas.value]
        }
        else {
          filteredTipoMaderas.value = tipoMaderas.value.filter((tmadera) => {
            return tmadera._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProducto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProductos.value = [...selectedProductos.value]
        }
        else {
          filteredProductos.value = selectedProductos.value.filter((prod) => {
            return prod._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchOrigen = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredOrigenes.value = [...origenes.value]
        }
        else {
          filteredOrigenes.value = origenes.value.filter((origen) => {
            return origen._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const onCellEditComplete = (event) => {
      let { data, newValue, field } = event
      if(!field.includes('producto')){
        if (newValue > 0) {
          data[field] = newValue
          messages.value = []
          if(field.includes('cant_pprecio') || field.includes('precio')){
            data['total'] = data['precio'] * data['cant_pprecio']
            data['total_mask'] = data['total']
          }
        }
        else event.preventDefault()
      }else 
        if(newValue.nombre !== undefined)
          if (newValue.nombre.trim().length > 0) {
            data[field] = newValue.nombre
            data['producto_obj'] = newValue
            messages.value = []
          }else event.preventDefault()   
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value)
    }

    const toggleDelete= (index) => {
      lineas.value.splice(index, 1)
      messages.value = []
    }

    const savePprecio = () => {
      submitted.value = true
      if(!v$._value.$invalid){
        if(form.tipoMadera.id !== 9){
          messages.value = []
          form.detalles = []
          storePprecio(form)  
          // console.info('form 1: ', form)
        }else
          if(lineas.value.length > 0){
            messages.value = []
            form.detalles = lineas.value
            // console.info('form 2: ', form)
            storePprecio(form)  
          }else
            messages.value = [{ content: 'Debe ingresar al menos un precio', id: lineas.value.length }]
      }
    }

    const uploadPrecios = (event) => { 
      readXlsxFile(event.files[0]).then((rows) => {
        if(rows.length > 0){
          lineas.value = []
          rows.map((row) => {lineas.value.push({'diametro': row[0],'valor_m3': row[1]})})
          // console.info('lineas.value: ', lineas.value)
        }
      })
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      pprecio,
      tipoMadera,
      producto,
      pprecios,
      lineas,
      columns,
      tipoMaderas,
      productos,
      proveedores,
      filters,
      selectedPprecios,
      selectedProductos,
      deletePpreciosDialog,
      deletePprecioDialog,
      pprecioDialog,
      submitted,
      filteredProveedores,
      filteredTipoMaderas,
      filteredProductos,
      filteredOrigenes,
      v$,
      form,
      messages,     
      hideDialog,
      openNew,
      confirmDeletePprecio,
      deleteSelectedPprecios,
      deletePprecio,
      exportCSV,
      confirmDeleteSelected,
      searchProveedor,
      searchOrigen,
      searchTipoMadera,
      searchProducto,
      editPprecio,
      savePprecio,
      storePprecio,
      formatCurrency,
      onCellEditComplete,
      toggleDelete,
      uploadPrecios,
      setProducto, 
      ronly,
      estrozo
    }
  },
}
</script>