import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useUsers() {
  const user = ref([])
  const users = ref([])
  const usersDelete = ref([])

  const client = useClient()
  const roles = ref([])

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedUsers = ref()
  const deleteUsersDialog = ref(false)
  const deleteUserDialog = ref(false)
  const userDialog = ref(false)
  const submitted = ref(false)

  const filteredRoles = ref()

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/users/all/crud`).then(response => {
      users.value = response.data.data.users
      roles.value = response.data.data.roles
      loading.value = false
    })
  }

  const storeUser = async (form) => {
    saving.value = true
    let id = user.value.id
    if (id) {
      //Esta editando el usuario
      client.put(`/api/users/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          userDialog.value = false
          //client.saving.value = false ?? getCRUD()
          getCRUD()
        })
    } else {
      //Esta creando el usuario
      client.post(`/api/users`, { 'data': { ...form } })
        .then(() => {
          userDialog.value = client.dialog.value
          user.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteUser = async () => {
    client.remove(`/api/users/${user.value.id}`)
      .then(response => {
        users.value = users.value.filter(val => val.id !== user.value.id)
        deleteUserDialog.value = false
        user.value = {}
      })
  }

  const deleteSelectedUsers = async () => {
    usersDelete.value = selectedUsers.value.map(({ id }) => id)
    client.remove(`/api/users/destroy/mass`, { data: usersDelete.value })
      .then(response => {
        users.value = users.value.filter(
          (val) => !selectedUsers.value.includes(val)
        )
        deleteUsersDialog.value = false
        selectedUsers.value = null
        usersDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    user,
    users,
    roles,
    dt,
    loading,
    saving,
    filters,
    selectedUsers,
    deleteUsersDialog,
    deleteUserDialog,
    userDialog,
    submitted,
    filteredRoles,
    deleteSelectedUsers,
    getCRUD,
    storeUser,
    deleteUser
  }
}