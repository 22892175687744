<template>
  <Toast />
  <div class="card-header">
    <h4>Listado de Contratos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Usuarios -->
      <DataTable ref="dt" :value="contratos" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort
        :globalFilterFields="['id','producto.nombre', 'empresa.razon_social', 'odestino.descripcion','nombre', 'valor', 'vigente']" sortMode="multiple" dataKey="id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        v-model:selection="selectedContratos" responsiveLayout="scroll" breakpoint="990px">

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>
            <template #empty> Sin rergistros. </template>
            <template #loading> Cargando registros. </template>
            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
            </template>
          </Toolbar>
        </template>

        <Column field="producto.nombre" header="Producto"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.producto.nombre }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="empresa.razon_social" header="Cliente" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.empresa.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="odestino.descripcion" header="Destino" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.odestino.descripcion }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="nombre" header="Contrato" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="valor" header="Valor" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.valor,false) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="vigente" header="Estado" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editContrato(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteContrato(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario Contratos en modal -->
    <Dialog v-model:visible="contratoDialog" :style="{ width: '50%' }" header="Datos de La Contrato" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-12 field">
            <label for="producto" class="mt-3">Producto</label>
            <AutoComplete id="producto" v-model="v$.producto.$model"
              :class="{ 'p-invalid': v$.producto.$invalid && submitted }" :suggestions="filteredProductos"
              @complete="searchProducto($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="producto-error" autofocus autocomplete="off" />
            <span v-if="v$.producto.$error && submitted">
              <span id="producto-error" v-for="(error, index) of v$.producto.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.producto.$invalid && submitted) || v$.producto.$pending.$response)"
              class="p-error">
              {{ v$.producto.required.$message.replace('Value', 'Producto') }}</small>
          </div> 
        </div>        

        <div class="row">
          <div class="col-md-12 field">
            <label for="empresa" class="mt-3">Empresa</label>
            <AutoComplete id="empresa" v-model="v$.empresa.$model"
              :class="{ 'p-invalid': v$.empresa.$invalid && submitted }" :suggestions="filteredEmpresas"
              @complete="searchEmpresa($event)" field="_razon_social" :dropdown="true" forceSelection
              aria-describedby="empresa-error" autofocus autocomplete="off" />
            <span v-if="v$.empresa.$error && submitted">
              <span id="empresa-error" v-for="(error, index) of v$.empresa.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.empresa.$invalid && submitted) || v$.empresa.$pending.$response)"
              class="p-error">
              {{ v$.empresa.required.$message.replace('Value', 'Empresa') }}</small>
          </div> 
        </div>   

        <div class="row">
          <div class="col-md-12 field">
            <label for="odestino" class="mt-3">Destino</label>
            <AutoComplete id="odestino" v-model="v$.odestino.$model"
              :class="{ 'p-invalid': v$.odestino.$invalid && submitted }" :suggestions="filteredDestinos"
              @complete="searchDestino($event)" field="_descripcion" :dropdown="true" forceSelection
              aria-describedby="odestino-error" autofocus autocomplete="off" />
            <span v-if="v$.odestino.$error && submitted">
              <span id="odestino-error" v-for="(error, index) of v$.odestino.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.odestino.$invalid && submitted) || v$.odestino.$pending.$response)"
              class="p-error">
              {{ v$.odestino.required.$message.replace('Value', 'Destino') }}</small>
          </div> 
        </div> 

        <div class="row">
          <div class="col-md-12 field">
            <label for="nombre" class="mt-3">Contrato</label>
            <InputText id="nombre" v-model="v$.nombre.$model" :class="{ 'p-invalid': v$.nombre.$invalid && submitted }"
              aria-describedby="nombre-error" autofocus autocomplete="off" />
            <span v-if="v$.nombre.$error && submitted">
              <span id="nombre-error" v-for="(error, index) of v$.nombre.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              {{ v$.nombre.required.$message.replace('Value', 'Contrato') }}</small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 field">
            <label for="valor" class="mt-3">Valor</label>
            <InputNumber id="valor" v-model="v$.valor.$model" :class="{ 'p-invalid': v$.valor.$invalid && submitted }"
              aria-describedby="valor-error" autofocus autocomplete="off" />
            <span v-if="v$.valor.$error && submitted">
              <span id="valor-error" v-for="(error, index) of v$.valor.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.valor.$invalid && submitted) || v$.valor.$pending.$response" class="p-error">
              {{ v$.valor.required.$message.replace('Value', 'Valor') }}</small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <InputText id="observacion" v-model.trim="form.observacion" />
          </div>          
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveContrato" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un contrato -->
    <Dialog v-model:visible="deleteContratoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="contrato"> ¿Está seguro de borrar el contrato?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteContratoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteContrato" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente contratos -->
    <Dialog v-model:visible="deleteContratosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedContratos"> ¿Está seguro de borrar los contratos seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteContratosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedContratos" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useContratos from '../../composables/cruds/contratos'
import { reactive, onMounted } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      contrato,
      contratos,
      productos,
      empresas,
      destinos,
      dt, 
      loading, 
      saving,
      filters,
      selectedContratos,
      deleteContratosDialog,
      deleteContratoDialog,
      contratoDialog,
      submitted,
      filteredProductos,
      filteredEmpresas,
      filteredDestinos,
      storeContrato,      
      deleteSelectedContratos,
      getCRUD,
      deleteContrato
    } = useContratos()

    const form = reactive({
      producto: '',
      empresa: '',
      odestino: '',
      nombre: '',
      valor: '',
      observacion: '',
      vigente: ''
    })
    
    const rules = {
      producto: {
        required: helpers.withMessage('El campo es requerido o no corresponde a la contrato', required)
      },
      empresa: {
        required: helpers.withMessage('El campo es requerido!', required),
      },
      nombre: { required: helpers.withMessage('El campo es requerido', required), },
      valor: { required: helpers.withMessage('El campo es requerido', required), },
      odestino: {
        required: helpers.withMessage('El campo es requerido!', required),
      },      
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteContratosDialog.value = true
    }

    const confirmDeleteContrato = (emp) => {
      contrato.value = emp
      deleteContratoDialog.value = true
    }

    const openNew = () => {
      contrato.value = {}
      submitted.value = false
      contratoDialog.value = true
      saving.value = false

      form.producto = ''
      form.empresa = ''      
      form.odestino = ''
      form.nombre = ''
      form.valor = ''
      form.observacion = ''
      form.vigente = true

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const hideDialog = () => {
      contratoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const searchProducto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProductos.value = [...productos.value]
        }
        else {
          filteredProductos.value = productos.value.filter((producto) => {
            return producto._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchEmpresa = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredEmpresas.value = [...empresas.value]
        }
        else {
          filteredEmpresas.value = empresas.value.filter((empresa) => {
            return empresa._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchDestino = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredDestinos.value = [...destinos.value]
        }
        else {
          filteredDestinos.value = destinos.value.filter((destino) => {
            return destino._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const editContrato = (emp) => {
      contrato.value = { ...emp }
      contratoDialog.value = true

      form.producto = null
      form.empresa = null      
      form.odestino = null
      form.nombre = null
      form.valor = null
      form.observacion = null
      form.vigente = true

      form.created_at = null
      form.created_id = null
      form.updated_at = null
      form.updated_id = null        

      form.producto = contrato.value.producto
      form.empresa = contrato.value.empresa
      form.odestino = contrato.value.odestino
      form.nombre = contrato.value.nombre
      form.valor = Number(contrato.value.valor)
      form.observacion = contrato.value.observacion
      form.vigente = contrato.value.vigente
      
      form.created_at = contrato.value.created_at
      form.created_id = contrato.value.created_id
      form.updated_at = contrato.value.updated_at
      form.updated_id = contrato.value.updated_id
    }

    const saveContrato = () => {
      submitted.value = true
        if(!v$._value.$invalid)
          storeContrato(form)  
    }

    const formatCurrency = (value, style = true) => {
      
      return style ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(Number(value))
                   : new Intl.NumberFormat('es-CL').format(Number(value.toFixed(0)))
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      contrato,
      contratos,
      productos,
      empresas,
      filters,
      selectedContratos,
      deleteContratosDialog,
      deleteContratoDialog,
      contratoDialog,
      submitted,
      filteredProductos,
      filteredEmpresas,
      filteredDestinos,
      v$,
      form,
      hideDialog,
      openNew,
      confirmDeleteContrato,
      deleteSelectedContratos,
      deleteContrato,
      exportCSV,
      confirmDeleteSelected,
      searchProducto,
      searchEmpresa,
      searchDestino,
      editContrato,
      saveContrato,
      storeContrato,
      formatCurrency
    }
  },
}
</script>