import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function usePprecios() {

  const pprecio = ref([])
  const tipoMadera = ref([])
  const producto = ref([])
  const pprecios = ref([])
  const tipoMaderas = ref([])
  const productos = ref([])
  const ppreciosDelete = ref([])
  const messages = ref([])

  const client = useClient()

  const proveedores = ref([])
  const origenes = ref([])
  const lineas = ref([])

  const columns = ref([
    { field: 'diametro', header: 'Diametro', hidden: false },
    { field: 'valor_m3', header: 'Valor M3', hidden: false }
  ])

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)
  const ronly = ref(true)

  const selectedPprecios = ref()
  const selectedProductos = ref()
  const deletePpreciosDialog = ref(false)
  const deletePprecioDialog = ref(false)
  const pprecioDialog = ref(false)
  const submitted = ref(false)

  const filteredProveedores = ref()
  const filteredTipoMaderas = ref()
  const filteredProductos = ref()
  const filteredOrigenes = ref()

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/pprecios/all/crud`).then(response => {
      pprecios.value = response.data.data.pprecios
      proveedores.value = response.data.data.proveedores
      tipoMaderas.value = response.data.data.tipoMaderas
      productos.value = response.data.data.productos
      origenes.value = response.data.data.origenes
      loading.value = false
    })
  }

  const storePprecio = async (form) => {
    saving.value = true
    let id = pprecio.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/pprecios/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          pprecioDialog.value = false
          //client.saving.value = false ?? getCRUD()
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/pprecios`, { 'data': { ...form } })
        .then(() => {
          pprecioDialog.value = client.dialog.value
          pprecio.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deletePprecio = async () => {
    client.remove(`/api/pprecios/${pprecio.value.id}`)
      .then(response => {
        pprecios.value = pprecios.value.filter(val => val.id !== pprecio.value.id)
        deletePprecioDialog.value = false
        pprecio.value = {}
      })
  }

  const deleteSelectedPprecios = async () => {
    ppreciosDelete.value = selectedPprecios.value.map(({ id }) => id)
    client.remove(`/api/pprecios/destroy/mass`, { data: ppreciosDelete.value })
      .then(response => {
        pprecios.value = pprecios.value.filter(
          (val) => !selectedPprecios.value.includes(val)
        )
        deletePpreciosDialog.value = false
        selectedPprecios.value = null
        ppreciosDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'proveedor.id': { value: null, matchMode: FilterMatchMode.EQUALS },
    'proveedor.rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },
    'proveedor.razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'producto.tipoMadera.nombre': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'producto.nombre': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'origen.descripcion': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'vpromedio': { value: null, matchMode: FilterMatchMode.EQUALS }
  })

  const altaPrecios = async(event) => {
    //Esta creando la carga mass de precios
    // error.value.progress = true
    let data = new FormData()
    data.append('file_precios', event.files[0])
    console.log('form data');
    console.log(data);
    client.post(`pprecios/${pprecio.value.id}/cmass`, { 'data': data, 'headers': { "Content-Type": "multipart/form-data" } })
      .then((response) => {
          // error.value.progress = false
          getCRUD()
      })
  }

  return {
    pprecio,
    tipoMadera,
    producto,
    pprecios,
    tipoMaderas,
    productos,
    proveedores,
    origenes,
    dt,
    loading,
    saving,
    filters,
    selectedPprecios,
    selectedProductos,
    deletePpreciosDialog,
    deletePprecioDialog,
    pprecioDialog,
    submitted,
    filteredProveedores,
    filteredTipoMaderas,
    filteredProductos,
    filteredOrigenes,
    lineas,
    columns,
    messages,
    deleteSelectedPprecios,
    getCRUD,
    storePprecio,
    deletePprecio,
    altaPrecios,
    ronly
  }
}