import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import useClient from '../client/apiClient'

export default function useComprasClasif() {
  const comprasSII = ref([])
  const rcompras = ref([])
  const informeCompras = ref([])
  const gastos = ref([])
  const expandedRows = ref([])
  const tgastoDialog = ref(false)
  const submitted = ref(false)
  const lineas = ref([])
  const messages = ref([])
  const compra = ref({})
  const router = useRouter()
  const client = useClient()

  const filteredGastos = ref()

  const tipoClasif = ref([
    {name: "Clasificados", key: "C"},
    {name: "Proveedor", key: "P"}
  ])

  const clasifTipo = ref({ name: 'Clasificados', key: 'C' })
  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)
  const deleteDialog = ref({visible:false, index:null, id: null})

  const getCompraSII = async (periodo) => {
    loading.value = true

    client.get(`/api/compras/${periodo}/${clasifTipo.value.key}/resumen`).then(response => {
      let todas = []
      comprasSII.value = []
      gastos.value = []
      rcompras.value = []

      informeCompras.value.data = response.data.data.compras

      informeCompras.value.header = {
        'Tipo Gasto': 'tipo_gasto',
        'Tipo Doc': 'tipo_doc',
        'Folio': 'folio',
        'Fecha Docto.': 'fecha_docto',
        'Fecha Recepcion': 'fecha_recepcion',
        'RUT': 'rut_proveedor',
        'Proveedor': 'razon_social',
        'Mnt.Exento': 'monto_exento',
        'Mnt.IVA No Recupera': 'monto_iva_no_recupera',
        'Mnt.IVA': 'destino_id',
        'Neto': 'monto_neto', 
        'Total': 'monto_total'
      }
      
      gastos.value = response.data.data.gastos
      rcompras.value = response.data.data.rcompras

      // let detalles
      rcompras.value.map((gasto) => {
        let detalles = response.data.data.compras.filter(compra => Number(compra.gasto_id) === Number(gasto.gasto_id))
        todas.push({
          gasto_id: gasto.gasto_id,
          tipo_gasto: gasto.tipo_gasto,
          nombre: gasto.nombre,
          neto: gasto.gasto_neto,
          exento: gasto.gasto_exento,
          iva_recupera: gasto.gasto_iva_recupera,
          total: gasto.gasto_total,
          iva_no_recupera: gasto.gasto_iva_no_recupera,
          iva_uso_comun: gasto.gasto_iva_uso_comun,
          iva_no_retenido:gasto.gasto_iva_no_retenido,
          impuesto_sin_credito: gasto.gasto_impuesto_sin_credito,
          tasa_otro_impuesto: gasto.gasto_tasa_otro_impuesto ?? '',
          cod_otro_impuesto: gasto.gasto_cod_otro_impuesto ?? '',
          valor_otro_impuesto: gasto.gasto_valor_otro_impuesto,
          neto_activo_fijo: gasto.gasto_neto_activo_fijo,
          iva_activo_fijo: gasto.gasto_iva_activo_fijo,

          detalles: detalles
        })
      })
      // console.info('todas: ', todas)
      comprasSII.value = todas
      loading.value = false
    })
  }

  const storeTipoGastos = async (form, aniomes) => {
    saving.value = true
    // console.info('form', form)
    client.post(`/api/compras/${form.compra_id}/clasif`, { 'data': { ...form } })
      .then(() => {
        saving.value = false
        getCompraSII(aniomes)
        tgastoDialog.value = false
      })
  }

  const getPDFSII = async (d) => {
    loading.value = true
    if(d.folio !== undefined)
      axios.get(`/api/sii/${d.codigo_sii}/${d.folio}/R/${d.rut_proveedor}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }

  const getCompra = async (id) => {
    loading.value = true
    client.get(`/api/compras/${id}/show`).then(response => {
      compra.value = response.data.data.compra
    })
    loading.value = false
  }
  
  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'nombre': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'exento': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_no_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'total': { value: null, matchMode: FilterMatchMode.EQUALS }
  })
  
  const dfilters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    
    'tipo_doc': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'fecha_docto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'rut_proveedor': { value: null, matchMode: FilterMatchMode.EQUALS },
    'razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_exento': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_total': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva_no_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_uso_comun': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_no_retenido': { value: null, matchMode: FilterMatchMode.EQUALS },
    'impuesto_sin_credito': { value: null, matchMode: FilterMatchMode.EQUALS },
    'cod_otro_impuesto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'tasa_otro_impuesto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'valor_otro_impuesto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_activo_fijo': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto_activo_fijo': { value: null, matchMode: FilterMatchMode.EQUALS },
  })

  const deleteClasif = async () => {
    console.info('deleteDialog: ', deleteDialog)
    client.remove(`/api/compras/${deleteDialog.value.id}/clasif`)
    .then(response => {
      let row = {
        'id': null,
        'compra_id': null,
        'gasto_id': null,
        'tipo_gasto': '',
        'monto_neto': 0,
        'porc_iva': 19,
        'monto_iva': 0,
        'monto_total': 0,
        'observacion': ''
      }
      lineas.value[deleteDialog.value.index] = row
      lineas.value.splice(deleteDialog.value.index, 1)
      if (lineas.value.length === 0) lineas.value.pop()
      messages.value = []
      deleteDialog.value.visible = false
    })
  }  

  return {
    router,
    comprasSII,
    compra,
    rcompras,
    informeCompras,
    gastos,
    dt,
    submitted,
    loading,
    saving,
    filters,
    dfilters,
    filteredGastos,
    expandedRows,
    tipoClasif,
    clasifTipo,
    tgastoDialog,
    lineas,
    messages,
    getCompraSII,
    storeTipoGastos,
    getPDFSII,
    getCompra,
    deleteDialog,
    deleteClasif
  }
}
