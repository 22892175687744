<template>
  <Toast />
  <div class="card-header">
    <h4>Listado de Despachadores</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Usuarios -->
      <DataTable ref="dt" :value="despachadores" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" :filters="filters"
        v-model:selection="selectedDespachadores" responsiveLayout="scroll" breakpoint="990px">

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-headercflex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>

            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
              &nbsp;
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectedDespachadores || !selectedDespachadores.length" />
                &nbsp;
              <Button label="Descargar" icon="pi pi-download" class="p-button-help" @click="exportCSV($event)" />
            </template>
          </Toolbar>
        </template>

        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

        <Column field="rut_dv" header="RUT"  style="white-space: nowrap;">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.rut_dv }}
            </span>
          </template>
        </Column>
        <Column field="nombre" header="Nombre" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </template>
        </Column>
        <Column field="vigente" header="Estado" >
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>   
        </Column>
        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editDespachador(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteDespachador(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario Despachadores en modal -->
    <Dialog v-model:visible="despachadorDialog" :style="{ width: '50%' }" header="Datos del Despachador" :modal="true"
      class="p-fluid" :maximizable="true">

      <form class="p-fluid">
        <div class="row">
          <div class="col-md-12 field">
            <label for="rut_dv" class="mt-3">RUT</label>
            <InputText id="rut_dv" v-model="v$.rut_dv.$model"
              :class="{ 'p-invalid': v$.rut_dv.$invalid && submitted }" aria-describedby="rut_dv-error" autofocus
              autocomplete="off" />
            <span v-if="v$.rut_dv.$error && submitted">
              <span id="rut_dv-error" v-for="(error, index) of v$.rut_dv.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.rut_dv.$invalid && submitted) || v$.rut_dv.$pending.$response" class="p-error">
              {{ v$.rut_dv.required.$message.replace('Value', 'RUT') }}</small>
          </div>        
        </div>

        <div class="row">
          <div class="col-md-12 field">
            <label for="nombre" class="mt-3">Nombre</label>
            <InputText id="nombre" v-model="v$.nombre.$model" :class="{ 'p-invalid': v$.nombre.$invalid && submitted }"
              aria-describedby="nombre-error" autofocus autocomplete="off" />
            <span v-if="v$.nombre.$error && submitted">
              <span id="nombre-error" v-for="(error, index) of v$.nombre.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              {{ v$.nombre.required.$message.replace('Value', 'Nombre') }}</small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 field" >
            <label for="email" class="mt-3">Email</label>
            <InputText id="email" v-model="v$.email.$model"
              :class="{ 'p-invalid': v$.email.$invalid && submitted }" aria-describedby="email-error"
              autocomplete="off" />
            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
              {{ v$.email.required.$message.replace('Value', 'Email') }}</small>
          </div>
          <div class="col-md-4 field">
            <label for="telefono" class="mt-3">Teléfono</label>
            <InputText id="telefono" v-model.trim="form.telefono" />
          </div>        
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <InputText id="observacion" v-model.trim="form.observacion" />
          </div>          
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveDespachador" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un despachador -->
    <Dialog v-model:visible="deleteDespachadorDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="despachador"> ¿Está seguro de borrar el despachador?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDespachadorDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteDespachador" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente despachadors -->
    <Dialog v-model:visible="deleteDespachadoresDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedDespachadores"> ¿Está seguro de borrar los despachadores seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDespachadoresDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedDespachadores" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useDespachadores from '../../composables/cruds/despachadores'
import { reactive, onMounted } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, email, helpers, requiredIf } from '@vuelidate/validators'

export default {
  setup() {
    const {
      despachador,
      despachadores,
      dt, 
      loading, 
      saving,
      filters,
      selectedDespachadores,
      deleteDespachadoresDialog,
      deleteDespachadorDialog,
      despachadorDialog,
      submitted,
      storeDespachador,      
      deleteSelectedDespachadores,
      getCRUD,
      deleteDespachador
    } = useDespachadores()

    const form = reactive({
      rut_dv: '',
      nombre: '',
      telefono: '',
      email: '',
      observacion: '',
      vigente: ''
    })

    const rut_format = helpers.regex(/^(\d{1,3}(?:\d{1,3}){2}-[\dkK])$/)
    
    const rules = {
      rut_dv: {
        required: helpers.withMessage('El campo es requerido o no corresponde al despachador', required),
        rut_rule: helpers.withMessage('Ingrese un rut válido. Ej: 12345678-0', rut_format),
      },
      nombre: { required: helpers.withMessage('El campo es requerido', required), },
      email: {
        email: helpers.withMessage('Ingrese un email válido. Ej: buzon@transcon.com', email),
      },
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      // console.info(dt.value)
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteDespachadoresDialog.value = true
    }

    const confirmDeleteDespachador = (desp) => {
      despachador.value = desp
      deleteDespachadorDialog.value = true
    }

    const openNew = () => {
      despachador.value = {}
      submitted.value = false
      despachadorDialog.value = true
      saving.value = false

      form.rut_dv = ''
      form.nombre = ''
      form.telefono = ''
      form.email = ''
      form.observacion = ''
      form.vigente = true

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const hideDialog = () => {
      despachadorDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const editDespachador = (desp) => {
      despachador.value = { ...desp }
      despachadorDialog.value = true

      form.rut_dv = despachador.value.rut_dv
      form.nombre = despachador.value.nombre
      form.telefono = despachador.value.telefono      
      form.email = despachador.value.email
      form.observacion = despachador.value.observacion
      form.vigente = despachador.value.vigente
      
      form.created_at = despachador.value.created_at
      form.created_id = despachador.value.created_id
      form.updated_at = despachador.value.updated_at
      form.updated_id = despachador.value.updated_id
    }

    const saveDespachador = () => {
      submitted.value = true
      if(!v$._value.$invalid)
        storeDespachador(form)  
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      despachador,
      despachadores,
      filters,
      selectedDespachadores,
      deleteDespachadoresDialog,
      deleteDespachadorDialog,
      despachadorDialog,
      submitted,
      v$,
      form,
      hideDialog,
      openNew,
      confirmDeleteDespachador,
      deleteSelectedDespachadores,
      deleteDespachador,
      exportCSV,
      confirmDeleteSelected,
      editDespachador,
      saveDespachador,
      storeDespachador
    }
  },
}
</script>