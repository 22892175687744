<template>
  <Toast />
  <div class="card-header">
    <h4>Actualización Guia de Despacho</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <form class="p-fluid">
      <div class="row">
        <div class="col-md-4 field">
          <label for="cliente">Cliente</label>
          <AutoComplete
            id="cliente"
            v-model="v$.cliente.$model"
            :class="{ 'p-invalid': v$.cliente.$invalid && submitted }"
            :suggestions="filteredClientes"
            @complete="searchCliente($event)"
            field="_razon_social"
            :dropdown="true"
            forceSelection
            aria-describedby="cliente-error"
            autofocus
            autocomplete="off"/>
          <span v-if="v$.cliente.$error && submitted">
            <span id="cliente-error" v-for="(error, index) of v$.cliente.$errors" :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small v-else-if="(v$.cliente.$invalid && submitted) || v$.cliente.$pending.$response" class="p-error">
            {{v$.cliente.required.$message.replace("Value", "Cliente")}}</small>
        </div>
        <div class="col-md-1 field ms-4">
          <label for="fecha">Fecha Desde</label>
          <Calendar
            id="fdesde"
            v-model="v$.fdesde.$model"
            :class="{ 'p-invalid': v$.fdesde.$invalid && submitted }"
            aria-describedby="fdesde-error"
            autocomplete="off"
            :showIcon="false"
            dateFormat="dd/mm/y"
            autofocus/>
          <span v-if="v$.fdesde.$error && submitted">
            <span
              id="fdesde-error"
              v-for="(error, index) of v$.fdesde.$errors"
              :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small
            v-else-if="(v$.fdesde.$invalid && submitted) || v$.fdesde.$pending.$response"
            class="p-error">
            {{ v$.fdesde.required.$message.replace("Value", "Fecha Desde") }}</small>
        </div>
        <div class="col-md-1 field ms-4">
          <label for="fhasta">Fecha Hasta</label>
          <Calendar
            id="fhasta"
            v-model="v$.fhasta.$model"
            :class="{ 'p-invalid': v$.fhasta.$invalid && submitted }"
            aria-describedby="fhasta-error"
            autocomplete="off"
            :showIcon="false"
            dateFormat="dd/mm/y"
            autofocus/>
          <span v-if="v$.fhasta.$error && submitted">
            <span
              id="fhasta-error"
              v-for="(error, index) of v$.fhasta.$errors"
              :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small v-else-if="(v$.fhasta.$invalid && submitted) || v$.fhasta.$pending.$response" class="p-error">
            {{ v$.fhasta.required.$message.replace("Value", "Fecha Hasta") }}</small>
        </div>
        <div class="col-md-1" style="max-width: 50px; text-align: right;">
          <Checkbox
            title="Mostrar sólo un producto"
            v-model="un_producto"
            inputId="un_producto"
            name="un_producto"
            :binary="true"
            @input="unSelectProductos($event)"/>
        </div>
        <div class="col-md-4 field">
          <label for="un_producto">Mostrar sólo un producto</label>
          <AutoComplete
            id="producto"
            v-model.trim="form.producto"
            :suggestions="filteredProductos"
            @complete="searchProducto($event)"
            field="_nombre"
            :dropdown="true"
            autocomplete="off"
            placeholder="Seleccione Producto"
            :disabled="!un_producto"
            @update:modelValue="buscarGuia"/>
        </div>
        <div class="col-md-1 field mt-3 ms-4" style="text-align: right; padding-top: 5px;">
          <Button
            icon="pi pi-search"
            class="p-button-primary"
            label="Buscar"
            @click="buscarGuia"
            outlined
            :disabled="form.fdesde === null || form.fhasta === null || form.cliente === null"/>
        </div>        
      </div>
      <div class="row mt-4">
        <DataTable
          ref="dt"
          v-model:editingRows="editingRows"
          :value="guias"
          scrollable 
          scrollHeight="600px"
          style="zoom: 0.80"
          :loading="loading"
          class="sm"
          showGridlines
          stripedRows
          editMode="row"
          sortMode="multiple" removableSort
          dataKey="detalle_id"
          csvSeparator=";" 
          @row-edit-save="onRowEditSave"
          tableClass="editable-cells-table">

          <Column field="despacho_id" header="despacho_id" :hidden="true">
            <template #body="slotProps">
              <span>
                {{ slotProps.data.despacho_id }}
              </span>
            </template>
          </Column>
          <Column field="detalle_id" header="detalle_id" :hidden="true">
            <span>
              {{ slotProps.data.detalle_id }}
            </span>
          </Column>          
          <Column field="folio" header="Folio" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.folio }}
              </span>
            </template>
          </Column>
          <Column field="fecha" header="Fecha" sortable>
            <template #body="slotProps">
              <span>
                {{ formatFecha(slotProps.data.fecha) }}
              </span>
            </template>
          </Column>
          <Column field="id" header="Cód." sortable>
            <span>
              {{ slotProps.data.id }}
            </span>
          </Column>
          <Column field="nombre" header="Producto" sortable>
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </Column>
          <Column field="fsc.nombre" header="FSC Nombre" style="white-space: nowrap;" :exportable="true" :hidden="true"></Column>    
          <Column field="fsc" header="Fsc" :exportable="false" sortable> 
            <template #body="slotProps">
              <span>
                {{ slotProps.data.fsc ? slotProps.data.fsc.nombre : ''}}
              </span>
            </template>
            <template #editor="{ data, field, index }">
              <AutoComplete
                id="fsc"
                v-model="data[field]"
                :suggestions="filteredFscs"
                @complete="searchFsc($event)"
                field="nombre"
                :dropdown="true"
                forceSelection
                autofocus
                autocomplete="off"
                @change="setFscCodigo($event, index)"
              />
            </template>
          </Column>
          <Column field="fsc_codigo" header="Cod.Fsc" sortable>
            <span>
              <InputText v-model="data[field]" />
            </span>
          </Column>
          <Column field="precio" header="Val.Unitario" bodyStyle="text-align:right" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>          
          <Column field="cant_recepcion" header="Cantidad" bodyStyle="text-align:right" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>          
          <Column field="mc_despacho" header="M3" bodyStyle="text-align:right" sortable>
            <span>
              {{ slotProps.data.mc_despacho }}
            </span>
          </Column>
          <Column field="pulg_despacho" header="Pulgadas" bodyStyle="text-align:right" sortable>
            <span>
              {{ slotProps.data.pulg_despacho }}
            </span>
          </Column>
          <Column field="neto_detalle" header="Neto.Detalle" bodyStyle="text-align:right" sortable>
            <template #body="slotProps">
              <span>
                {{ formatCurrency(slotProps.data.neto_detalle) }}
              </span>
            </template>
          </Column>
          <Column field="neto_guia" header="Neto.Guía" bodyStyle="text-align:right" sortable>
            <template #body="slotProps">
              <span>
                {{ formatCurrency(slotProps.data.neto_guia) }}
              </span>
            </template>
          </Column>
          <Column field="iva_guia" header="IVA.Guía" bodyStyle="text-align:right" sortable>
            <template #body="slotProps">
              <span>
                {{ formatCurrency(slotProps.data.iva_guia) }}
              </span>
            </template>
          </Column>
          <Column field="total_guia" header="Total.Guía" bodyStyle="text-align:right" sortable>
            <template #body="slotProps">
              <span>
                {{ formatCurrency(slotProps.data.total_guia) }}
              </span>
            </template>
          </Column>
          <Column
            header="Editar"
            :rowEditor="true"
            style="width: 10% min-width: 8rem"
            bodyStyle="text-align:center"></Column>
          <ColumnGroup type="footer">
            <Row>
              <Column footer="Totales" colspan="7" footerStyle="text-align:right"/>
              <Column :footer="totCantidad" footerStyle="text-align:right"/>
              <Column :footer="totM3" footerStyle="text-align:right"/>
              <Column :footer="totPulgadas" footerStyle="text-align:right"/>
              <Column :footer="totNetoDet" footerStyle="text-align:right"/>
              <Column :footer="totNetoGuia" footerStyle="text-align:right"/>
              <Column :footer="totIvaGuia" footerStyle="text-align:right"/>
              <Column :footer="totGuia" footerStyle="text-align:right"/>
              <Column footer="" footerStyle="text-align:right"/>
            </Row>
          </ColumnGroup>            
        </DataTable>
      </div>
      <div class="row mt-4">
        <div class="col-md-8 field">
            <i class="pi pi-info-circle text-danger mt-1"></i>&nbsp;<u>Para Actualizar guias masivamente, siga los pasos:</u>
            <ol>
              <li>Obtenga el set de datos que desa actualizar, aplicando los filtros.</li>
              <li>Descargue el archivo en formato csv, haciendo clic en el botón <strong>Exportar CSV</strong>.</li>
              <li>Abra Excel e importe los datos del archivo descargado.</li>
              <li>Realice los ajustes que crea necesario y guarde los cambios.<br>
                NOTAS:
                <ul>
                  <li>El archivo debe estar en formato <strong>csv</strong>, separado por comas.</li>
                  <li>El archivo debe mantener las cabeceras exportadas, por lo cual no debe eliminar la primera linea.</li>
                  <li>Al igual que la edición en linea, solo se consideran cambios en las columnas <strong>FSC Nombre, FSC Código, Val.Unitario y Cantidad</strong>.</li>
                  <li>Eliminar columnas al archivo exportado, generará problemas en la modificación masiva.</li>                    
                  <li>Las lineas que no tengan datos válidos: guia de despacho, producto o FSC no existentes no se procesarán y seran marcadas como erradas.</li>                    
                </ul>
              </li>
              <li>Haga clic en el botón <strong>Importar Cambios</strong>.</li>
              <li>Haga clic nuevamente, cuando el nombre del archivo seleccionado aparezca en el botón, para iniciar la carga y actualización masiva de la guias modificadas en el csv.</li>
              <li>Para ver las lineas que no pudieron ser procesadas haga clic en el icono <i class="pi pi-bell text-danger mt-1" style="font-size: 1rem; cursor: pointer;"></i></li>
            </ol>
        </div>
        <div class="col-md-2 field">
          <Button icon="pi pi-download" label="Exportar CSV" @click="exportCSV($event)"/>
        </div>
        <div class="col-md-2 field">
          <form class="p-fluid" name="altasForm">
            <FileUpload v-if="!error.state && !error.progress" mode="basic" name="file_guias" accept=".csv" uploadIcon="pi pi-upload"
              label="Importar Ajustes" chooseLabel="Importar Cambios" class="p-button-warning mr-2 inline-block"
              @uploader="uploadCarga($event, form)" :customUpload="true" />&nbsp;
          </form>
          <ProgressBar v-if="error.progress" mode="indeterminate" style="height: 15px"></ProgressBar>
          <i v-if="resultado.length" class="pi pi-bell text-danger mt-1" @click="logFleteMasivo(resultado)"
          style="font-size: 1.7rem; cursor: pointer;right: 30px;" v-badge.warning="resultado.length"></i>
          <span v-if="error.progress" class="text-primary mt-2">&nbsp;Cargando&nbsp;</span>
        </div>
      </div>      
    </form>
  </div>
</template>

<script>
  import useAGDespachos from "../../composables/despachos/agdespacho"
  import { reactive, onMounted, isProxy, toRaw, computed } from "vue"
  import moment from "moment"
  import useVuelidate from "@vuelidate/core"
  import { required, helpers } from "@vuelidate/validators"

  export default {
    setup() {
      const {
        porciva,
        factura,
        editingRows,
        fsc,
        facturas,
        nextid,
        clientes,
        fscs,
        productos,
        dt,
        dtg,
        loading,
        saving,
        guias,
        submitted,
        filteredClientes,
        filteredFscs,
        filteredProductos,
        selectedProductos,
        selectedGuias,
        messages,
        detalleTipo,
        tipoDetalle,
        un_producto,
        actualizaGuia,
        deleteSelectedFacturas,
        getCRUD,
        getGuias,
        deleteFactura,
        error,
        uploadCarga,
        resultado
      } = useAGDespachos()

      const form = reactive({
        cliente: null,
        producto: null,
        origen: "",
        destino: "",
        transportista: "",
        chofer: "",
        despachador: "",
        fsc: "",
        fsc_codigo: "",
        folio: null,
        fecha: moment().toDate(),
        patente_camion: "",
        patente_carro: "",
        flete: null,
        poriva: porciva.value,
        guias_despacho: "",
        descuento: 0,
        neto: null,
        iva: null,
        total: null,
        nro_oc: null,
        fecha_oc: "",
        observacion: "",
        monto_letras: null,
        detalles: [],
        tipo_pago: null,
        guias_despacho: null,
        guias: [],
        //para busqueda de guias
        fdesde: null,
        fhasta: null,
      })

      const rules = {
        cliente: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        origen: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        destino: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        transportista: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        chofer: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        despachador: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        fsc: {
          required: helpers.withMessage("El campo es requerido!", required),
        },
        folio: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        fecha: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        patente_camion: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        patente_carro: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        flete: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        neto: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        iva: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        total: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        //para validar parametro de busqueda guias
        fdesde: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        fhasta: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        tipo_pago: {
          required: helpers.withMessage("El campo es requerido", required),
        },
        guias_despacho: {
          required: helpers.withMessage(
            "Debe Seleccionar al menos una Guía de Despacho",
            required
          ),
        },
      }

      const onRowEditSave = (event) => {
        let { newData, index } = event  
        let neto_guia_new = 0 
        let guiasCambio = isProxy(guias.value) ? toRaw(guias.value) : []

        guias.value[index] = newData
        guias.value[index].precio = parseFloat(guias.value[index].precio).toFixed(3)
        guias.value[index].neto_detalle = parseFloat(guias.value[index].precio).toFixed(3) * parseFloat(guias.value[index].cant_recepcion).toFixed(3)

        //recalcula valores segun cambios
        guiasCambio.map((guia) => { 
          if(guias.value[index].folio === guia.folio) neto_guia_new = neto_guia_new + guia.neto_detalle 
        })
        
        let iva_guia_new = (porciva.value * neto_guia_new) / 100      
        let total_guia_new = neto_guia_new + iva_guia_new
        let _fsc_codigo = newData.fsc_codigo !== null ? newData.fsc.codigo : event.data.fsc_codigo
        let _fsc = newData.fsc !== null ? newData.fsc : event.data.fsc

        // guias.value.forEach(function (guia, i) {
        //   guia = toRaw(guia)
          // if(guia.folio === guias.value[index].folio){
            guias.value[index].neto_guia = neto_guia_new
            guias.value[index].iva_guia = iva_guia_new
            guias.value[index].total_guia = total_guia_new
            guias.value[index].fsc_codigo = _fsc_codigo
            guias.value[index].fsc = _fsc
        //   }
        // })
        //Store cambios solo para guias con cambios
        guiasCambio = []
        // animals.push('cows');
        guiasCambio.push(guias.value[index])
        // guias.value.forEach(function (guia, i) {
        //   guia = toRaw(guia)
        //   if(guia.folio === guias.value[index].folio){
        //     guiasCambio[i] = guia
        //   }
        // })
        console.info('guiasCambio', guiasCambio)
        // console.info('form', form)
        actualizaGuia(form, guiasCambio)
      }

      const setFscCodigo = (event, idx) => {  
        guias.value[idx].fsc_codigo = event.value.codigo
      }

      const searchFsc = (event) => {
        setTimeout(() => {
          if (!event.query.trim().length) {
            filteredFscs.value = [...fscs.value]
          } else {
            filteredFscs.value = fscs.value.filter((fsc) => {
              return fsc.nombre
                .toLowerCase()
                .startsWith(event.query.toLowerCase())
            })
          }
        }, 250)
      }

      const unSelectProductos = (idx) => {
        if (!idx) form.producto = []
      }

      const searchCliente = (event) => {
        setTimeout(() => {
          if (!event.query.trim().length) {
            filteredClientes.value = [...clientes.value]
          }
          else {
            filteredClientes.value = clientes.value.filter((cliente) => {
              return cliente._razon_social.toLowerCase().includes(event.query.toLowerCase())
            })
          }
        }, 250)
      }

      const searchProducto = (event) => {
        setTimeout(() => {
          if (!event.query.trim().length) {
            filteredProductos.value = [...productos.value]
          }
          else {
            filteredProductos.value = productos.value.filter((producto) => {
              return producto._nombre.toLowerCase().includes(event.query.toLowerCase())
            })
          }
        }, 250)
      }

      const formatCurrency = (value) => {
        return new Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
        }).format(value)
      }

      const formatNumber = (value) => {
        return new Intl.NumberFormat('es-CL').format(Number(value))
      }

      const formatFecha = (value) => {
        return moment(value).format("DD-MM-YY")
      }

      const buscarGuia = () => {
        messages.value = []
        let cliente = isProxy(form.cliente) ? toRaw(form.cliente) : null
        let producto = isProxy(form.producto) ? toRaw(form.producto).id : 0
        getGuias(
          cliente.id,
          form.fdesde.toLocaleDateString("es-CL"),
          form.fhasta.toLocaleDateString("es-CL"),
          producto
        )
      }

      const totCantidad = computed(() => {
        let tcantidad = 0;
        for(let ln of guias.value) {
          tcantidad += Number(ln.cant_recepcion)
        }
        return formatNumber(tcantidad)
      })    

      const totM3 = computed(() => {
        let tm3 = 0;
        for(let ln of guias.value) {
          tm3 += Number(ln.mc_despacho)
        }
        return formatNumber(tm3)
      })    

      const totPulgadas = computed(() => {
        let tpulgadas = 0;
        for(let ln of guias.value) {
          tpulgadas += Number(ln.pulg_despacho)
        }
        return formatNumber(tpulgadas)
      })    

      const totNetoDet = computed(() => {
        let tndetalles = 0;
        for(let ln of guias.value) {
          tndetalles += Number(ln.neto_detalle)
        }
        return formatCurrency(tndetalles)
      })    

      const totNetoGuia = computed(() => {
        let tnguias = 0;
        for(let ln of guias.value) {
          tnguias += Number(ln.neto_guia)
        }
        return formatCurrency(tnguias)
      })    

      const totIvaGuia = computed(() => {
        let tivaguia = 0;
        for(let ln of guias.value) {
          tivaguia += Number(ln.iva_guia)
        }
        return formatCurrency(tivaguia)
      })    

      const totGuia = computed(() => {
        let tguia = 0;
        for(let ln of guias.value) {
          tguia += Number(ln.total_guia)
        }
        return formatCurrency(tguia)
      }) 
      
      const exportCSV = () => {
        dt.value.exportCSV()
      }

      const logFleteMasivo = (log) => {
        let logM = ''
        if(log.length > 0){
          logM +='Lineas no procesadas:'
          logM +='\r-----------------------------------'
          logM += log.map((ln) => '\r'+ ln)    
        }
        let FileSaver = require('file-saver');
        let blob = new Blob([logM], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "logActGuias.txt");
      }
      
      const v$ = useVuelidate(rules, form)

      onMounted(getCRUD)

      return {
        dt,
        dtg,
        loading,
        saving,
        factura,
        fsc,
        fscs,
        facturas,
        productos,
        guias,
        clientes,
        submitted,
        filteredClientes,
        filteredFscs,
        filteredProductos,
        selectedProductos,
        selectedGuias,
        porciva,
        v$,
        form,
        messages,
        detalleTipo,
        tipoDetalle,
        nextid,
        un_producto,
        editingRows,
        searchCliente,
        onRowEditSave,
        deleteSelectedFacturas,
        deleteFactura,
        searchProducto,
        unSelectProductos,
        searchFsc,
        setFscCodigo,
        actualizaGuia,
        formatCurrency,
        formatNumber,
        formatFecha,
        buscarGuia,
        totCantidad,
        totM3,
        totPulgadas,
        totNetoDet,
        totNetoGuia,
        totIvaGuia,
        totGuia,
        exportCSV,
        error,
        uploadCarga,
        resultado,
        logFleteMasivo
      }
    },
  }
</script>