<template>
  <Toast/>
  <div class="card-header row">
    <div class="col-md-3">
      <h4>Ingresos - Estado de Cobros </h4>
      <div class="line_title" style="width: 20%;"></div>
    </div>
    <div class="col-md-2">
      <label for="cliente">Cliente:</label>
      <AutoComplete id="cliente" name="cliente" v-model.trim="cliente"
        :suggestions="filteredClientes" field="_razon_social"
        @complete="searchCliente($event)" :dropdown="true"
        autofocus autocomplete="off"/>
    </div>
    <div class="col-md-2">
      <label for="fdesde">Desde:&nbsp;</label>
      <Calendar
        id="fdesde"
        aria-describedby="fdesde-error"
        v-model="fdesde"
        inputClass="bg-input"
        autocomplete="off"
        dateFormat="dd-mm-yy"
        :showIcon="true"
      />&nbsp;
    </div>
    <div class="col-md-2">
      <label for="fhasta">Hasta:&nbsp;</label>
      <Calendar
        id="fhasta"
        aria-describedby="fhasta-error"
        v-model="fhasta"
        inputClass="bg-input"
        autocomplete="off"
        dateFormat="dd-mm-yy"
        :showIcon="true"
      />&nbsp;
    </div>
    <div class="col-md-1">
      <label for="estado">Estado:</label>
      <AutoComplete id="estado" name="estado" v-model.trim="estado" :suggestions="filteredEstados"
                    @complete="searchEstado($event)" @change="dummyClick" dropdown/>
    </div>
    <div style="text-align: left" class="col-md-1"><br>
      <Button icon="pi pi-search" class="p-button-primary" @click="buscarVentas" outlined
              :disabled="(fdesde === null || fhasta === null)"/>
    </div>
    <div style="text-align: right" class="col-md-1"><br>
      <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
    </div>
  </div>
  <div class="card-body">
    <div>
      <!-- DT Ventas SII -->
      <DataTable v-model:expandedRows="expandedRows" ref="dt" :value="ventasSII" 
        :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort
        :globalFilterFields="['tipo_doc','tipo_venta','fecha_docto','fecha_recepcion','folio','rut_cliente','razon_social','monto_exento','monto_iva','monto_neto','monto_total','estado']" sortMode="multiple" dataKey="id" filterDisplay="row" v-model:filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
        responsiveLayout="scroll" breakpoint="990px">          
        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Buscar..."/>
                </span>
              </div>
            </template>
          </Toolbar>
        </template>
        <Column expander/>

        <Column field="tipo_doc" header="Tipo" style="width:2%;" sortable>
          <template #body="slotProps">
            <Tag :value="getTipoDocumento(slotProps.data.tipo_doc)" severity="info" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>        

        <Column field="tipo_venta" header="T.Venta" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.tipo_venta }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="fecha_docto" header="F.Docto" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatFecha(slotProps.data.fecha_docto) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="fecha_recepcion" header="F.Recep." style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatFecha(slotProps.data.fecha_recepcion) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="folio" header="Folio" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="rut_cliente" header="RUT.Cliente" style="width: 8%" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.rut_cliente }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="razon_social" header="Razon Social" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="monto_exento" header="Mnt.Exento" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(Number(slotProps.data.monto_exento)) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="monto_iva" header="Mnt.IVA" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(Number(slotProps.data.monto_iva)) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="monto_neto" header="Mnt.Neto" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(Number(slotProps.data.monto_neto)) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="monto_total" header="Total" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.monto_total) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="estado" header="Estado" :exportable="true" :hidden="true" sortable>
          <template #body="slotProps">
            <span>{{ slotProps.data.estado ?? '' }}</span>
          </template>
        </Column>

        <Column field="estado" header="Estado" :exportable="false" style="white-space: nowrap;text-align:center" sortable>
          <template #body="slotProps">
            <Tag severity="success" v-if="slotProps.data.estado === 'PAGADA'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="warning" v-if="slotProps.data.estado === 'PENDIENTE'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="danger" v-if="slotProps.data.estado === 'VENCIDA'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="primary" v-if="slotProps.data.estado === 'REBAJADO'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="info" v-if="slotProps.data.estado === null" rounded style="background-color: #838576;color: #fff;"><h5 style="text-aling-center;">COBRAR</h5></Tag>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column header="Acc" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="getPDFSII(slotProps.data)" title="Ver Doc" />&nbsp;
            <Button icon="pi pi-wallet" class="p-button-rounded p-button-primary" @click="setCobros(slotProps.data)" title="Registrar Pagos"/>
          </template>
        </Column>
        <!-- Expansion de Folio para ver las ND-NC asociadas -->
        <template #expansion="slotProps">
          <DataTable :value="slotProps.data.notasdc"
            tableStyle="padding-left:0px !important;padding-right:0px !important;">

            <Column field="tipo_doc" header="Tipo" style="width:2%;">
              <template #body="slotProps">
                <Tag :value="getTipoDocumento(slotProps.data.tipo_doc)" severity="info" />
              </template>
            </Column>

            <Column field="folio" header="Folio" style="width:5%;">
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.folio }}
                </span>
              </template>
            </Column>

            <Column field="fecha_docto" header="Fecha" style="width:8%;">
              <template #body="slotProps">
                <span>
                  {{ formatFecha(slotProps.data.fecha_docto) }}
                </span>
              </template>
            </Column>

            <Column field="monto_iva" header="IVA" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_iva) }}
                </span>
              </template>
            </Column>

            <Column field="monto_neto" header="Neto" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_neto) }}
                </span>
              </template>
            </Column>

            <Column field="monto_total" header="Total" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_total) }}
                </span>
              </template>
            </Column>

            <Column header="" :exportable="false" style="width:5%;white-space: nowrap;">
              <template #body="slotProps">
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info me-2"
                  @click="getPDFSII(slotProps.data)" title="Descarga Folio" />
              </template>
            </Column>
          </DataTable>          
        </template>
      </DataTable>
    </div>
    <!-- Formulario Despachos en modal -->
    <Dialog v-model:visible="cobroDialog" header="Datos del Ingreso" :modal="true" class="p-fluid" :maximizable="true"
            position="topleft">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-6 field">
            <h4>Cliente</h4>
            <span>{{ form.venta.rut_cliente }} {{ form.venta.razon_social }}</span>
          </div>
          <div class="col-md-2 field">
            <h4>Folio</h4>
            <span>N°: {{ form.venta.folio }}</span>
          </div>
          <div class="col-md-2 field">
            <h4>Fecha</h4>
            <span>{{ formatFecha(form.venta.fecha_docto) }}</span>
          </div>
          <div class="col-md-2 field">
            <h4>Monto Documento</h4>
            <span style="text-aling-right">{{ formatCurrency(form.venta.monto_total) }}</span>
          </div>
          <div class="col-md-2 field mt-3">
            <h4>Tipo de Cobro</h4>
            <AutoComplete id="tipo_cobro" v-model="v$.tipo_cobro.$model" v-if="form.venta.tcobro === null"
              :class="{ 'p-invalid': v$.tipo_cobro.$invalid && submitted }" :suggestions="filteredTipoCobros"
              @complete="searchTCobro($event)" field="nombre" :dropdown="true" forceSelection
              aria-describedby="tipo_cobro-error" autofocus autocomplete="off"
              @change="setTipoCobro($event, index)"/>
            <span v-if="v$.tipo_cobro.$error && submitted">
              <span id="tipo_cobro-error" v-for="(error, index) of v$.tipo_cobro.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.tipo_cobro.$invalid && submitted) || v$.tipo_cobro.$pending.$response)"
                  class="p-error">
              {{ v$.tipo_cobro.required.$message.replace('Value', 'Tipo Cobro') }}</small>
            <Tag severity="info" rounded v-if="form.venta.tcobro !== null">
              <h5 style="text-aling-center;"> {{form.venta.tcobro.nombre.toUpperCase()}}</h5>
            </Tag> 
          </div>
          <div class="col-md-8 field" style="text-aling-right"></div>
          <div class="col-md-2 field mt-3" id="dummy">
            <h4>Estado</h4>
            <Tag severity="success" v-if="form.venta.estado === 'PAGADA'" rounded><h5 style="text-aling-center;">
              {{ form.venta.estado }}</h5></Tag>
            <Tag severity="warning" v-if="form.venta.estado === 'PENDIENTE'" rounded><h5 style="text-aling-center;">
              {{ form.venta.estado }}</h5></Tag>
            <Tag severity="danger" v-if="form.venta.estado === 'VENCIDO'" rounded><h5 style="text-aling-center;">
              {{ form.venta.estado }}</h5></Tag>
            <Tag severity="info" v-if="form.venta.estado === null" rounded style="background-color: #838576;color: #fff;"><h5 style="text-aling-center;">COBRAR</h5></Tag>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-10 field">
            <Divider align="left" type="solid">
              <b class="text-muted">Cobros</b>
            </Divider>
          </div>
          <div class="col-md-2 d-flex flex-row-reverse" v-if="form.tipo_cobro !== null">&nbsp;
            <Button icon="pi pi-plus" class="p-button-rounded p-button me-4" size="small" @click="addLinea"
              v-if="(form.tipo_cobro.id === 2 && (totalAbonos < (form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112'))))"/>
          </div>
          <div class="col-md-12 field">
            <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
          </div>
        </div>
        <div class="card p-fluid table_modal">
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px" editMode="cell"
            @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table" tableStyle="min-width: 50%">
            <Column field="cuota" header="Cuota" style="max-width: 1%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>            
            <Column field="id" header="ID" style="max-width: 14%" :hidden="true">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column> 
            <Column field="modalidad" :colspan="4" header="Modalidad" style="max-width: 14%">
              <template #editor="{ data, field }">
                <AutoComplete v-model="data[field]" :optionValue="data[field]" :suggestions="filteredModalidades"
                  @complete="searchModalidad($event)" dropdown/>
              </template>
            </Column>                                   
            <Column field="monto_abono" header="Monto Abono" style="text-align:right; max-width: 14%" v-if="venta.tcobro_id === 2">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" @click="onCellEditInit"/>
              </template>
            </Column>    
            <Column field="monto_abono" header="Monto Abono" style="text-align:right; max-width: 14%" v-else>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>                    
            <Column field="porc_abono" header="% Abono" style="text-align:right; max-width: 14%">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>            
            <Column field="referencia" header="Referencia" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>
            <Column field="entidad" header="Banco / Caja" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>
            <Column field="fecha_vence" header="Fecha Vencimiento" style="max-width: 14%">
              <template #editor="{ data, field }">
                <Calendar v-model="data[field]" :showIcon="false" dateFormat="dd/mm/y"/>
              </template>
            </Column>
            <Column field="fecha_cobro" header="Fecha Cobro" style="max-width: 14%">
              <template #editor="{ data, field }">
                <Calendar v-model="data[field]" :showIcon="false" dateFormat="dd/mm/y"/>
              </template>
            </Column>
            <Column field="observacion" header="Observación" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" style="max-width: 14%">
              <template #editor="{ data, field }">
                <AutoComplete v-model="data[field]" :optionValue="data[field]" :suggestions="filteredEstados"
                              @complete="searchEstado($event)" dropdown/>
              </template>
            </Column>
            <Column style="max-width: 1%; text-align: center;">
              <template #body="{ data, index }">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" size="small"
                        @click="toggleDelete(index)" v-if="data['id'] === null"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePago(index, data['id'], form)" v-else/>
              </template>
            </Column>   
            <ColumnGroup type="footer">
              <Row>
                <Column colspan="15" footer="" footerStyle="text-align:left;color:#FFF; background-color:#FFF;" />         
              </Row>
              <Row>
                <Column footer="FE/BE" footerStyle="text-align:left;color:#FFF; background-color:#5081b1;"/>
                <Column :footer="formatCurrency(form.venta.monto_total)" footerStyle="text-align:left; background-color:#e8eff7;color:#3e3ec2;"/>
                <Column footer="ND/NC" footerStyle="text-align:left;color:#FFF; background-color:#73a1ce;"/>
                <Column :footer="formatCurrency(getSumNota(form.venta.notasdc, '55 56 111'))" footerStyle="text-align:left; background-color:#e8eff7;color:#006110;"/>
                <Column :footer="'- '+formatCurrency(getSumNota(form.venta.notasdc, '60 61 112'))" footerStyle="text-align:left; background-color:#e8eff7;color:#b81414;"/>
                <Column :footer="formatCurrency(form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112'))" footerStyle="text-align:right; background-color:#e8eff7;"/>
                <Column footer="COBRAR" footerStyle="text-align:right;color:#FFF; background-color:#838576;"/>
                <Column :footer="formatCurrency(totalXCobrar)" footerStyle="text-align:right; background-color:#f3f1ee;"/>
                <Column footer="ABONADO" footerStyle="text-align:right;color:#FFF; background-color:#30904b;"/>
                <Column :footer="formatCurrency(totalCobrado)" footerStyle="text-align:right; background-color:#deecdf;"/>
                <Column footer="PENDIENTE" footerStyle="text-align:right;color:#fff; background-color:#eaa03e;"/>
                <Column :footer="formatCurrency(totalPendiente)" footerStyle="text-align:right; background-color:#fdf5e6;"/>
                <Column footer="VENCIDO" footerStyle="text-align:right;color:#FFF; background-color:#d9252b;"/>
                <Column :footer="formatCurrency(totalVencido)" footerStyle="text-align:right; background-color:#fbdedf;"/>                
              </Row>
            </ColumnGroup> 
          </DataTable>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog"/>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveCobro"/>
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>
    
    <!-- Confirmar elimina un abono ya almacenado -->
    <Dialog v-model:visible="deletePagoDialog.visible" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span> ¿Está seguro de borrar el abono?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePagoDialog.visible = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deletePago(index,id, form)" />
      </template>
    </Dialog>       
  </div>
</template>

<script>
import useVentasCobro from "../../composables/ingresos/vcobros"
import { reactive, ref, onMounted, computed, toRaw } from "vue"
import moment from "moment"
import useVuelidate from '@vuelidate/core'
import {required, helpers} from '@vuelidate/validators'

export default {
  setup() {
    const {
      ventasSII,
      dt,
      loading,
      saving,
      submitted,
      filters,
      cobroDialog,
      venta,
      tcobros,
      filteredTipoCobros,
      tipoDocumentos,
      lineas,
      messages,
      modalidades,
      estados,
      filteredEstados,
      filteredModalidades,
      clientes,
      getVentaSII,
      storeCobros,
      getClientes,
      getPDFSII,
      deletePagoDialog,
      deletePago,
      expandedRows
    } = useVentasCobro()

    moment.locale('es')

    const filteredClientes = ref()
    const fdesde = ref(moment().startOf('month').toDate())
    const fhasta = ref(moment().endOf('month').toDate())
    const cliente = ref(null)
    const estado = ref(null)

    const form = reactive({
      venta: null,
      venta_id: null,
      cobros: [],
      tipo_pago: null
    })

    const abono = ref(0)

    const rules = {
      tipo_cobro: {required: helpers.withMessage('El campo es requerido', required),},
    }

    const searchModalidad = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredModalidades.value = [...modalidades.value]
        } else {
          filteredModalidades.value = modalidades.value.filter((modalidad) => {
            return modalidad.toUpperCase().startsWith(event.query.toUpperCase())
          })
        }
      }, 250)
    }

    const searchEstado = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredEstados.value = [...estados.value]
        } else {
          filteredEstados.value = estados.value.filter((estado) => {
            return estado.toUpperCase().startsWith(event.query.toUpperCase())
          })
        }
      }, 250)
    }

    const formatFecha = (value) => {
      return moment(value).format("DD-MM-YY")
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'}).format(value)
    }

    const setCobros = (venta) => {
      lineas.value = []
      form.venta = {...venta}
      form.tipo_cobro = venta.tcobro
      abono.value = venta.tcobro ? venta.tcobro.id : 0

      venta.ventas_cobros.map((ln) => {
        lineas.value.push({
          'id': ln.id,
          'modalidad': ln.modalidad,
          'referencia': ln.referencia,
          'porc_abono': parseInt(Math.round(ln.porc_abono)),
          'monto_abono': ln.monto_abono,
          'fecha_vence': ln.fecha_vence ? moment(ln.fecha_vence,'YYYY-MM-DD').format('DD-MM-YYYY') : null,
          'fecha_cobro': ln.fecha_cobro ? moment(ln.fecha_cobro,'YYYY-MM-DD').format('DD-MM-YYYY'): null,
          'cuota': ln.cuota,
          'entidad': ln.entidad,
          'observacion': ln.observacion,
          'estado': ln.estado
        })
      })
      messages.value = []
      cobroDialog.value = true
    }

    const hideDialog = () => {
      cobroDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const searchTCobro = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoCobros.value = [...tcobros.value]
        } else {
          filteredTipoCobros.value = tcobros.value.filter((tcobro) => {
            return tcobro.nombre.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchCliente = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredClientes.value = [...clientes.value]
        } else {
          filteredClientes.value = clientes.value.filter((cliente) => {
            return cliente._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const setTipoCobro = (event) => {
      form.venta.tcobro_id = event.value.id
      form.venta.tipo_cobro = event.value.nombre
      while (lineas.value.length > 0) lineas.value.pop()
      addLinea()
    }

    const totalAbonos = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let cobro of lineas.value) {
          if(cobro.monto_abono !== null)
            total += cobro.monto_abono
        }
      return total
    })

    const totalCobrado = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let cobro of lineas.value) {
          if(cobro.monto_abono !== null && cobro.estado === 'PAGADO')
            total += cobro.monto_abono
        }
      return total
    })

    const totalXCobrar = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let cobro of lineas.value) {
          if(cobro.monto_abono !== null && cobro.estado === 'PAGADO')
            total += cobro.monto_abono
        }      
        let xcobrar = form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112')-total
      return xcobrar > 0 ? xcobrar : 0
    })

    const totalXDocs = computed(() => {
      return form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112')
    })

    const totalPendiente = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let cobro of lineas.value) {
          if(cobro.monto_abono !== null && cobro.estado === 'PENDIENTE')
            total += cobro.monto_abono
        }
      return total
    })

    const totalVencido = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let cobro of lineas.value) {
          if(cobro.monto_abono !== null && cobro.estado === 'VENCIDO')
            total += cobro.monto_abono
        }
      return total
    })

    const onCellEditComplete = (event) => {
      let {data, newValue, field} = event
      data[field] = newValue
      messages.value = []

      if (field.includes('fecha_vence')) {
        if(abono.value === 2) 
          if((moment(data['fecha_vence']).format("DD-MM-YYYY") < moment(new Date()).format("DD-MM-YYYY")) && data['fecha_cobro'] === null)
            data['estado'] = 'VENCIDO'
          else if((moment(data['fecha_vence']).format("DD-MM-YYYY") > moment(new Date()).format("DD-MM-YYYY")) && data['fecha_cobro'] === null)
            data['estado'] = 'PENDIENTE'        
          else if(data['fecha_cobro'] !== null)
            data['estado'] = 'PAGADO'
      }
      
      if (field.includes('fecha_cobro')) {
        if(abono.value === 2) 
          if(data['fecha_cobro'] !== null)
            data['estado'] = 'PAGADO'
      }

      if (field.includes('fecha_')) {
        if(typeof newValue === 'string')
          data[field] = moment(data[field],'DD-MM-YYYY').format('DD-MM-YYYY')
        else if(typeof newValue === 'object' && newValue !== null)
          data[field] = moment(newValue).format('DD-MM-YYYY')
        else
          data[field] = null
      }

      if (field.includes('monto_abono')) {
        data['monto_abono'] = Math.round(newValue)
        data['porc_abono'] = parseInt(Math.round((newValue * 100) / (form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112'))))
      }
    }

    const onCellEditInit = (event) => {
      let {data, newValue, field} = event

      if (field.includes('monto_abono')) {
        data[field] = new Intl.NumberFormat({style: 'currency'}).format(Math.round(newValue))
      }
    } 

    const buscarVentas = () => {
      messages.value = []
      let rut = cliente.value === undefined || cliente.value === null ? '0' : cliente.value.rut_dv
      let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))
      getVentaSII(rut, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'), edo)
    }

    const addLinea = () => {
      let row = {
        'id': null,
        'modalidad': '',
        'referencia': 0,
        'porc_abono': form.venta.tcobro_id === 1 ? 100 : 0,
        'monto_abono': form.venta.tcobro_id === 1 ? Math.round(form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112')) : 0,
        'fecha_vence': form.venta.tcobro_id === 1 ? moment(new Date()).format("DD-MM-YY") : null,
        'fecha_cobro': form.venta.tcobro_id === 1 ? moment(new Date()).format("DD-MM-YY") : null,
        'cuota': form.venta.tcobro_id === 1 ? 1 : null,
        'entidad': '',
        'observacion': '',
        'estado': form.venta.tcobro_id === 1 ? 'PAGADO' : null
      }

      if (lineas.value.length === 0)
        lineas.value.push(row)
      else if (lineas.value[lineas.value.length - 1].modalidad !== '')
        lineas.value.push(row)
      else
        messages.value = [{
          content: 'Debe seleccionar la modalidad antes de agregar nueva línea',
          id: lineas.value.length
        }]
    }

    const toggleDelete = (index) => {
      let row = {
        'id': null,
        'modalidad': '',
        'referencia': 0,
        'porc_abono': 0,
        'monto_abono': 0,
        'fecha_vence': null,
        'fecha_cobro': null,
        'cuota': '',
        'entidad': '',
        'observacion': '',
        'estado': ''
      }
      lineas.value[index] = row
      lineas.value.splice(index, 1)
      if (lineas.value.length === 0) lineas.value.pop()
      messages.value = []
    }

    const saveCobro = () => {
      dummyClick()
      submitted.value = true
      messages.value = []                                             
      if (!v$._value.$invalid) {
        if(lineas.value.filter(function (itm) { return itm.modalidad !== 'SALDO NOTA CRÉDITO/DÉBITO'}).length > 0){
          if(totalAbonos.value > (form.venta.monto_total+getSumNota(form.venta.notasdc, '55 56 111')-getSumNota(form.venta.notasdc, '60 61 112')))
            messages.value = [{content: 'La suma de los abonos, no puede ser mayor al monto total del documento', id: lineas.value.length}]
        }
        if (lineas.value.length > 0) {
          //valida que esten modalidad / monto_abono / porc_abono para cada linea
          lineas.value.map((ln) => {
            if (ln.modalidad === '') messages.value = [{
              content: 'Debe seleccionar los datos del cobro antes de guardar',
              id: lineas.value.length
            }]
          })
        } else
          messages.value = [{content: 'Debe seleccionar los datos del cobro antes de guardar', id: lineas.value.length}]

        if (messages.value.length === 0) {
          let rut = cliente.value === undefined || cliente.value === null ? '0' : cliente.value.rut_dv
          let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))
          form.cobros = lineas
          form.venta_id = form.venta.id
          form.xcobrar = totalXCobrar
          storeCobros(form, rut, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'), edo)
        }
      }
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const dummyClick = () => {
      document.getElementById('dummy').click()
    }

    
    const confirmDeletePago = (index, id) => {
      deletePagoDialog.value.visible = true
      deletePagoDialog.value.index = index
      deletePagoDialog.value.id = id
      deletePagoDialog.value.fdesde = fdesde.value
      deletePagoDialog.value.fhasta = fhasta.value     
      deletePagoDialog.value.rut = form.venta.rut_cliente    
    }    

    const getSumNota = (notas,tdocs) => {
      return notas.reduce(function(acc, nt) {return tdocs.match(nt.tipo_doc) ? acc + nt.monto_total : acc + 0 }, 0)
    }

    const getTipoDocumento = (tdoc) => {
      return toRaw(tipoDocumentos.value.filter(function (itm) { return [tdoc].indexOf(itm.codigo_sii) > -1 }))[0].codigo              
    }
    
    const v$ = useVuelidate(rules, form)

    onMounted(getClientes)

    return {
      dt,
      form,
      loading,
      saving,
      ventasSII,
      filters,
      fdesde,
      fhasta,
      cliente,
      venta,
      lineas,
      cobroDialog,
      rules,
      tcobros,
      filteredTipoCobros,
      v$,
      submitted,
      messages,
      modalidades,
      estados,
      filteredEstados,
      filteredModalidades,
      filteredClientes,
      estado,
      setTipoCobro,
      formatCurrency,
      formatFecha,
      onCellEditComplete,
      onCellEditInit,
      searchTCobro,
      setCobros,
      hideDialog,
      addLinea,
      searchEstado,
      searchModalidad,
      searchCliente,
      toggleDelete,
      saveCobro,
      getClientes,
      buscarVentas,
      exportCSV,
      abono,
      totalCobrado,
      totalPendiente,
      totalVencido,
      totalAbonos,
      getPDFSII,
      dummyClick,
      totalXCobrar,
      deletePago,
      confirmDeletePago,
      deletePagoDialog,
      getSumNota,
      totalXDocs,
      expandedRows,
      getTipoDocumento
    }
  },
}
</script>