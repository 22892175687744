<template>	
	<div class="main-wrapper">
		<!-- @include('layout.header') -->
		<div class="horizontal-menu">
			<nav class="navbar top-navbar">
				<div class="container-fluid">
					<div class="navbar-content">
						<a href="/home"><img src="/assets/images/logo_new.png"></a>
						<Menu></Menu>		
						<ul class="navbar-nav">
							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="#" id="appsDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i data-feather="layers"></i>
									<span class="menu-title">&nbsp;Informes</span>
								</a>
								<div class="dropdown-menu p-0" aria-labelledby="appsDropdown">
								<div class="row g-0 p-1">
									<div v-if="[0, 1, 2, 3].includes(userRole)" class="col-4 text-center">
										<a href="#" class="dropdown-item d-flex flex-column 
											align-items-center justify-content-center wd-70 ht-70">
												<router-link to="/informes/recepcion" :class="routeName === 'clientes' ? 'active' : ''">
													<i data-feather="package" class="icon-lg mb-1"></i><p class="tx-12">Recepción</p>
												</router-link>
										</a>
									</div>

									<div v-if="[0, 1, 3].includes(userRole)" class="col-4 text-center">
										<a href="#" class="dropdown-item d-flex flex-column 
											align-items-center justify-content-center wd-70 ht-70">
												<router-link to="/informes/despacho" :class="routeName === 'clientes' ? 'active' : ''">
													<i data-feather="package" class="icon-lg mb-1"></i><p class="tx-12">Despacho</p>
												</router-link>
										</a>
									</div>

									<div v-if="[0, 1, 3].includes(userRole)" class="col-4 text-center">
										<a href="#" class="dropdown-item d-flex flex-column 
											align-items-center justify-content-center wd-70 ht-70">
												<router-link to="/informes/prod" :class="routeName === 'clientes' ? 'active' : ''">
													<i data-feather="calendar" class="icon-lg mb-1"></i><p class="tx-12">Producción</p>
												</router-link>
										</a>
									</div>
								</div>
								</div>
							</li>
							
							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i data-feather="user"></i>
								</a>
								<div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
									<div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
										<div class="text-center">
											<p class="tx-16 fw-bolder">{{ userName }}&nbsp;</p>
											<p class="tx-12 text-muted">
												<span class="nav-item nav-category alinear" v-if="userRole === 0">[ Super Admin ]</span>
												<span class="nav-item nav-category alinear" v-else-if="userRole === 1">[ Master SalcaS ]</span>
												<span class="nav-item nav-category alinear" v-else-if="userRole === 2">[ Recepcionista ]</span>
												<span class="nav-item nav-category alinear" v-else-if="userRole === 3">[ Consulta ]</span>
											</p>
										</div>
									</div>
								<ul class="list-unstyled p-1">
									<li class="dropdown-item py-2">
									<a href="/general/profile" class="text-body ms-0">
										<i class="me-2 icon-md" data-feather="smile"></i>
										<span>Perfil</span>
									</a>
									</li>
									<li class="dropdown-item py-2" @click="logout">
										<a href="javascript:;" class="text-body ms-0">
											<i class="me-2 icon-md" data-feather="log-out"></i>
											<span>Salir</span>
										</a>
									</li>
								</ul>
								</div>
							</li>
						</ul>
						<button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
							<i data-feather="menu"></i>					
						</button>
					</div>
				</div>
			</nav>
		</div>	
		<div class="page-wrapper">
			<div class="page-content">
				<router-view />
			</div>
			<!-- @include('layout.footer') -->
			<footer
				class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
				<p class="text-muted mb-1 mb-md-0">Copyright © 2023 <a href="http://aserraderolc.cl"
					target="_blank">Aserradero Los Castaños Ltda</a>.</p>
				<p class="text-muted">G-Talent <i class="mb-1 text-primary ms-1 icon-sm" data-feather="terminal"></i></p>
			</footer>
		</div>
	</div>
</template>
<script>
	import Menu from './components/seguridad/Menu.vue'
	import useClient from '../../resources/js/composables/client/apiClient'

	export default {
		name: "App",
		components: {
			Menu,
			useClient,			
		},
		
		data() {
			return {
				isLoggedIn: false,
				userName: window.Laravel.user.name,
				userRole: window.Laravel.user.seg_rol_id,
				routeName: null, //se usa para marcar la opcion activa
				//opciones por submenus, se usa para marcar el submenu activo
				SM_despachos: 'despachos agdespachos',
				SM_recepciones: 'recepciones agrecepcion /informes/recepcion',
				SM_procesos: 'contratos facturas demitidos',
				SM_rpasii: 'rpacompras rpaventas',			
				SM_gastos: 'cpagos cclasif',			
				SM_produccion: 'produccion /informes/prod',
				SM_informes: '/informes/recepcion /informes/prod /informes/despacho',
				SM_cruds: 'users empresas pprecios choferes despachadores odestinos productos tdocumentos gastos'						
			}
		},
		created() {
			if (window.Laravel.isLoggedin) {
				this.isLoggedIn = true
			}
		},	
		methods: {
			logout(e) {
				window.location.href = window.location.origin + '/logout'
			},
		},
	}
</script>