<template>
  <Toast />
  <div class="card-header">
    <Toolbar class="mb-0">
      <template #start>
        <h4>Listado de Despachos</h4>
      </template>
      <template #end>
        <label>Período</label>&nbsp;
        <Calendar id="periodo" aria-describedby="periodo-error" v-model="periodo.label" inputClass="bg-input"
          autocomplete="off" view="month" dateFormat="mm/yy" :showIcon="true" @date-select="selectPeriodo" />&nbsp;&nbsp;
      </template>      
    </Toolbar>
  </div>
  <div class="card-body">
    <div>
      <!-- DT Despachos -->
      <DataTable ref="dt" :value="despachos" lazy paginator :first="first" :loading="loading" 
        removableSort sortMode="multiple" @sort="onSort($event)" 
        :rows="10" :totalRecords="totalRecords" @page="onPage($event)" @filter="onFilter($event)" 
        v-model:filters="filters" :globalFilterFields="['folio', 'cliente', 'rut_dv', 'fecha','tipoDocumento', 'nro_oc', 'origen', 'destino','transportista', 'neto', 'iva', 'total', 'es_venta', 'facturada']" filterDisplay="row" 
        tableStyle="min-width: 75rem" breakpoint="990px" style="zoom: 0.80;" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">        
        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." @keydown.enter="onFilter($event)"/>
                </span>
              </div>
            </template>
            <template #empty> Sin registros. </template>
            <template #loading> Cargando registros. </template>
            <template #end>
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" outlined @click="initFilters()" class="p-button-secondary"/>&nbsp;
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
            </template>
          </Toolbar>
        </template>

        <Column header="Duplicar" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-clone" class="p-button-rounded p-button-primary" 
              @click="duplicarGuia(slotProps.data)" title="Duplicar Guía" />
          </template>
        </Column>        

        <Column field="tipoDocumento" header="Tipo" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.tipoDocumento.codigo === 'GD' ? 'GUIA' : 'BOLETA'" :severity="slotProps.data.tipoDocumento.codigo === 'GD' ? 'info' : 'warning'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>
        </Column>

        <Column field="folio" header="Folio"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="fecha" header="Fecha"  style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.fecha }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="nro_oc" header="Nro.OC" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nro_oc }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="rut_dv" header="RUT" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.cliente.rut_dv }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="cliente" header="Cliente" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.cliente.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="origen" header="Origen" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.origen.descripcion.slice(0, 23) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="destino" header="Destino" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.destino.descripcion.slice(0, 23) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="transportista" header="Transportista" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.transportista ? slotProps.data.transportista.razon_social : '' }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="neto" header="Neto" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.neto, false) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="iva" header="IVA" style="text-align: right;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.iva, false) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="total" header="Total" style="text-align: right;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.total, false) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template> 
        </Column>

        <Column field="es_venta" header="Venta" sortable style="white-space: nowrap;text-align:center">
          <template #body="slotProps">
            <span>
              <i class="pi text-center" :class="{ 'pi-check-circle text-success': slotProps.data.es_venta, 'pi-times-circle text-danger': !slotProps.data.es_venta }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="facturada" header="Facturada" style="white-space: nowrap;text-align:center">
          <template #body="slotProps">
            <span>
              <i class="pi text-center" :class="{ 'pi-check-circle text-success': slotProps.data.facturada, 'pi-times-circle text-danger': !slotProps.data.facturada }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info"
              @click="getPDFSII(slotProps.data)" title="Ver Guia" />&nbsp;
            <Button icon="pi pi-eye" class="p-button-rounded p-button-warning"
              @click="editDespacho(slotProps.data)" title="Ver" />&nbsp;
            <Button icon="pi pi-dollar" class="p-button-rounded p-button-danger" v-if="slotProps.data.factura === null"
              @click="getFactura(slotProps.data)" title="Facturar Guía" />
            <Button icon="pi pi-dollar" class="p-button-rounded p-button-success" v-else
              @click="getPDFSII(slotProps.data.factura)" title="Ver Factura" />
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- Formulario Despachos en modal -->
    <Dialog v-model:visible="despachoDialog" header="Datos del Despacho" :modal="true" class="p-fluid" :maximizable="true" position="topleft">
      <Divider align="left" type="solid">
        <b class="text-muted">Antecedentes Despacho</b>
      </Divider>
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-4 field">
            <label for="tipoDocumento" class="mt-3">Tipo Documento</label>
            <AutoComplete id="tipoDocumento" v-model="v$.tipoDocumento.$model"
              :class="{ 'p-invalid': v$.tipoDocumento.$invalid && submitted }" :suggestions="filteredTipoDocumentos"
              @complete="searchTipoDocumento($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="tipoDocumento-error" autofocus autocomplete="off" />
            <span v-if="v$.tipoDocumento.$error && submitted">
              <span id="tipoDocumento-error" v-for="(error, index) of v$.tipoDocumento.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.tipoDocumento.$invalid && submitted) || v$.tipoDocumento.$pending.$response)"
              class="p-error">
              {{ v$.tipoDocumento.required.$message.replace('Value', 'Tipo Documento') }}</small>
          </div>
          <div class="col-md-4 field"><!--   v-if="form.folio !== 0" -->
            <label for="folio" class="mt-3">N°Folio</label>
            <InputNumber id="folio" v-model="v$.folio.$model" :class="{ 'p-invalid': v$.folio.$invalid && submitted }"
              aria-describedby="folio-error" autofocus autocomplete="off" />
            <span v-if="v$.folio.$error && submitted">
              <span id="folio-error" v-for="(error, index) of v$.folio.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.folio.$invalid && submitted) || v$.folio.$pending.$response" class="p-error">
              {{ v$.folio.required.$message.replace('Value', 'Valor') }}</small>
          </div>
          <div class="col-md-4 field">
            <label for="fecha" class="mt-3">Fecha</label>
            <Calendar id="fecha" v-model="v$.fecha.$model"
              :class="{ 'p-invalid': v$.fecha.$invalid && submitted }" aria-describedby="fecha-error"
              autocomplete="off" :showIcon="true" dateFormat="dd/mm/yy"/>
            <span v-if="v$.fecha.$error && submitted">
              <span id="fecha-error" v-for="(error, index) of v$.fecha.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.fecha.$invalid && submitted) || v$.fecha.$pending.$response"
              class="p-error">
              {{ v$.fecha.required.$message.replace('Value', 'Fecha') }}</small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="cliente" class="mt-3">Cliente</label>
            <AutoComplete id="cliente" v-model="v$.cliente.$model"
              :class="{ 'p-invalid': v$.cliente.$invalid && submitted }" :suggestions="filteredClientes"
              @complete="searchCliente($event)" field="_razon_social" :dropdown="true" forceSelection
              aria-describedby="cliente-error" autofocus autocomplete="off" />
            <span v-if="v$.cliente.$error && submitted">
              <span id="cliente-error" v-for="(error, index) of v$.cliente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.cliente.$invalid && submitted) || v$.cliente.$pending.$response)"
              class="p-error">
              {{ v$.cliente.required.$message.replace('Value', 'Cliente') }}</small>
          </div>
          <div class="col-md-4 field">
            <label for="despachador" class="mt-3">Despachador</label>
            <AutoComplete id="despachador" v-model="v$.despachador.$model"
              :class="{ 'p-invalid': v$.despachador.$invalid && submitted }" :suggestions="filteredDespachadores"
              @complete="searchDespachador($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="despachador-error" autofocus autocomplete="off" />
            <span v-if="v$.despachador.$error && submitted">
              <span id="despachador-error" v-for="(error, index) of v$.despachador.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.despachador.$invalid && submitted) || v$.despachador.$pending.$response)"
              class="p-error">
              {{ v$.despachador.required.$message.replace('Value', 'Despachador') }}</small>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6 field">
            <label for="origen" class="mt-3">Origen</label>
            <AutoComplete id="origen" v-model="v$.origen.$model"
              :class="{ 'p-invalid': v$.origen.$invalid && submitted }" :suggestions="filteredOrigenes"
              @complete="searchOrigen($event)" field="_descripcion" :dropdown="true" forceSelection
              aria-describedby="origen-error" autofocus autocomplete="off" />
            <span v-if="v$.origen.$error && submitted">
              <span id="origen-error" v-for="(error, index) of v$.origen.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.origen.$invalid && submitted) || v$.origen.$pending.$response)"
              class="p-error">
              {{ v$.origen.required.$message.replace('Value', 'Origen') }}</small>
          </div>
          <div class="col-md-6 field">
            <label for="destino" class="mt-3">Destino</label>
            <AutoComplete id="destino" v-model="v$.destino.$model"
              :class="{ 'p-invalid': v$.destino.$invalid && submitted }" :suggestions="filteredDestinos"
              @complete="searchDestino($event)" field="_descripcion" :dropdown="true" forceSelection
              aria-describedby="destino-error" autofocus autocomplete="off" />
            <span v-if="v$.destino.$error && submitted">
              <span id="destino-error" v-for="(error, index) of v$.destino.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.destino.$invalid && submitted) || v$.destino.$pending.$response)"
              class="p-error">
              {{ v$.destino.required.$message.replace('Value', 'Destino') }}</small>
          </div>
        </div>

        <Divider align="left" type="solid">
          <b class="text-muted">Antecedentes Transportista</b>
        </Divider>

        <div class="row">
          <div class="col-md-12 field">
            <label for="transportista" class="mt-3">Transportista</label>
            <AutoComplete id="transportista" name="transportista"
              :suggestions="filteredTransportistas" v-model.trim="form.transportista"
              @complete="searchTransportista($event)" field="_razon_social" :dropdown="true"
              autofocus autocomplete="off" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 field">
            <label for="patente_camion" class="mt-3">Patente Camion</label>
            <InputText id="patente_camion" v-model="form.patente_camion" autocomplete="off"
            minlength="6" maxlength="6" size="6" @update:modelValue="upperCase($event,'patente_camion')"/>
          </div>
          <div class="col-md-2 field">
            <label for="patente_carro" class="mt-3">Patente Carro</label>
            <InputText id="patente_carro" v-model="form.patente_carro" autocomplete="off" minlength="6" maxlength="6" size="6" @update:modelValue="upperCase($event,'patente_carro')"/>
          </div>
          <div class="field" :class="frmchofer.visible ? 'col-md-4' : 'col-md-8'">
            <Button v-if="form.id === null" :label="frmchofer.label" link :class="frmchofer.color" @click="addChofer" style="width: 80px;padding:0px;margin:2px; height:15px;"/>
            <label  v-else for="chofer" class="mt-3">Chofer</label>
            <AutoComplete id="chofer" v-model.trim="form.chofer" @change="dummyClick"
              :suggestions="filteredChoferes" @complete="searchChofer($event)" field="_nombre"
              :dropdown="true" autocomplete="off" :disabled="frmchofer.visible" />
          </div>
          <div class="col-md-2 field" v-if="frmchofer.visible">
            <label for="chofer" class="mt-3" v-if="frmchofer.visible">RUT Chofer</label>
            <InputText id="rut_dv" v-model="v$.rut_dv.$model" @change="dummyClick"
              :class="{ 'p-invalid': v$.rut_dv.$invalid && submitted }" aria-describedby="rut_dv-error" autofocus
              autocomplete="off" />
            <span v-if="v$.rut_dv.$error && submitted">
              <span id="rut_dv-error" v-for="(error, index) of v$.rut_dv.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.rut_dv.$invalid && submitted) || v$.rut_dv.$pending.$response" class="p-error">
              {{ v$.rut_dv.required.$message.replace('Value', 'RUT') }}</small>
          </div>
          <div class="col-md-2 field" v-if="frmchofer.visible">
            <label for="chofer" class="mt-3" v-if="frmchofer.visible">Nombre Chofer</label>
            <InputText id="nombre" v-model="form.nombre" autofocus autocomplete="off" @change="dummyClick"/>
          </div>
        </div>
        <Divider align="left" type="solid">
          <b class="text-muted">Otros Antecedentes</b>
        </Divider>
        <div class="row mb-4">
          <div class="col-md-4 field">
            <label for="flete" class="mt-3">Valor Flete</label>
            <InputNumber id="flete" v-model="v$.flete.$model" :class="{ 'p-invalid': v$.flete.$invalid && submitted }"
              aria-describedby="flete-error" autofocus autocomplete="off" />
            <span v-if="v$.flete.$error && submitted">
              <span id="flete-error" v-for="(error, index) of v$.flete.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.flete.$invalid && submitted) || v$.flete.$pending.$response" class="p-error">
              {{ v$.flete.required.$message.replace('Value', 'Valor Flete') }}</small>
          </div>
          <div class="col-md-4 field">
            <label for="nro_oc" class="mt-3">Nro.OC</label>
            <InputNumber id="nro_oc" v-model.trim="form.nro_oc" />
          </div>
          <div class="col-md-4 field">
            <label for="fecha_oc" class="mt-3">Fecha OC</label>
            <Calendar id="fecha_oc" v-model="form.fecha_oc" autocomplete="off" :showIcon="true" dateFormat="dd/mm/yy"/>
          </div>
        </div>

        <Divider align="left" type="solid">
          <b class="text-muted">Clasificación FSC</b>
        </Divider>

        <div class="row">
          <div class="col-md-6 field">
            <label for="fsc" class="mt-3">FSC</label>
            <AutoComplete id="fsc" v-model.trim ="form.fsc" :suggestions="filteredFscs"
              @complete="searchFsc($event)" field="nombre" :dropdown="true"
              autocomplete="off" @change="setFscCodigo($event, index)"/>
          </div>
          <div class="col-md-6 field">
            <label for="fsc_codigo" class="mt-3">Código FSC</label>
            <InputText id="fsc_codigo" v-model.trim="form.fsc_codigo" readonly />
          </div>
        </div>
        
        <div class="row mt-4">
          <div class="col-md-10 field">
            <Divider align="left" type="solid">
              <b class="text-muted">Detalles</b>
            </Divider>
          </div>
          <div class="col-md-2 d-flex justify-content-end">
            <Button icon="pi pi-plus" class="p-button-rounded p-button" size="small" @click="addLinea()" v-if="form.id === null"/>
          </div>
          <div class="col-md-12 field">
            <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
          </div>
        </div>
        
        <!-- Detalles Despacho -->
        <div class="card p-fluid table_modal">
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px" :editMode="form.id === null ? 'cell' : null" 
            @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table" tableStyle="min-width: 50rem">
            <Column style="max-width: 2%" v-if="form.id === null">
              <template #body="{ index }">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" size="small" @click="toggleDelete(index)"/>
              </template>
            </Column>
            <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :hidden="col.hidden"
              :style="col.field !== 'producto' ? 'max-width: 8%' :'max-width: 10%'">
              <template #editor="{ data, field }">
                <template v-if="field === 'valor'">
                  <InputNumber v-model="data[field]" autofocus :useGrouping="true"
                  :maxFractionDigits="/cant_despacho|cant_recepcion|tipo_cambio/.test(field) ? 3 : 2" :readonly="/mc_|pulg_|total|_recepcion|total|total_mask/.test(field)"/>
                </template>
                <template v-else-if="(field !== 'producto' && field !== 'valor')">
                  <InputNumber v-model="data[field]" autofocus :useGrouping="true"
                  :maxFractionDigits="/cant_despacho|cant_recepcion|tipo_cambio/.test(field) ? 3 : 2" :readonly="/mc_|pulg_|total|_recepcion|total|total_mask/.test(field)"/>
                </template>
                <template v-else>
                  <AutoComplete v-model="data[field]" optionLabel="_nombre"  :optionValue="data[field]" :suggestions="filteredProductos" @complete="searchProducto($event)" @blur="buscarPrecios(event)"/>
                </template>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="Neto" :colspan="form.id === null ? 12 : 11" footerStyle="text-align:right"/>
                <Column :footer="netoGuia" />
              </Row>
              <Row>
                <Column :footer="`IVA (${form.poriva})%`" :colspan="form.id === null ? 12 : 11" footerStyle="text-align:right"/>
                <Column :footer="ivaGuia" />
              </Row>
              <Row>
                <Column footer="Total" :colspan="form.id === null ? 12 : 11" footerStyle="text-align:right"/>
                <Column :footer="totalGuia" />
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <Textarea autoResize id="observacion" v-model.trim="form.observacion" />
          </div>
          <div class="col-md-4 field">
            <label for="es_venta" class="mt-3 text-danger" v-if="form.es_venta">Haga Click para indicar si <strong>NO ES VENTA O ES NULA</strong></label>
            <label for="es_venta" class="mt-3 text-primary" v-if="!form.es_venta">Haga Click para indicar si <strong> ES VENTA </strong></label>
            <ToggleButton id="es_venta" v-model="v$.es_venta.$model" class="mt-3"
              :class="{ 'p-invalid': v$.es_venta.$invalid && submitted }" onLabel="ES VENTA" offLabel="NO ES VENTA / NULA"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="es_venta-error" autofocus
              style="width: 100%" @update:modelValue="confirmEsVentaDespacho"/>
            <span v-if="v$.es_venta.$error && submitted">
              <span id="es_venta-error" v-for="(error, index) of v$.es_venta.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.es_venta.$invalid && submitted) || v$.es_venta.$pending.$response" class="p-error">
              {{ v$.es_venta.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>

      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveDespacho" v-if="form.id === null" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
        <span id="dummy"></span>
      </template>
    </Dialog>

    <!-- Confirmar elimina un despacho -->
    <Dialog v-model:visible="deleteDespachoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="despacho"> ¿Está seguro de borrar el despacho?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDespachoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteDespacho" />
      </template>
    </Dialog>

    <!-- Confirmar cambiar tipo despacho -->
    <Dialog v-model:visible="esVentaDespachoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="despacho"> ¿Está seguro de cambiar el tipo de despacho?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="esVentaDespachoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="esVenta" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente despachos -->
    <Dialog v-model:visible="deleteDespachosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedDespachos"> ¿Está seguro de borrar los despachos seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDespachosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedDespachos" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useDespachos from '../../composables/despachos/index'
import { reactive, onMounted, computed, isProxy, toRaw} from 'vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      es_venta,
      porciva,
      despacho,
      chofer,
      fsc,
      despachos,
      tipoDocumentos,
      clientes,
      despachadores,
      choferes,
      fscs,
      destinos,
      origenes,
      transportistas,
      productos,
      precio,
      columns,
      dt,
      loading,
      saving,
      filters,
      initFilters,
      selectedDespachos,
      deleteDespachosDialog,
      deleteDespachoDialog,
      esVentaDespachoDialog,
      despachoDialog,
      submitted,
      filteredTipoDocumentos,
      filteredClientes,
      filteredDespachadores,
      filteredFscs,
      filteredChoferes,
      filteredDestinos,
      filteredOrigenes,
      filteredTransportistas,
      filteredProductos,
      lineas,
      messages,
      storeDespacho,
      deleteSelectedDespachos,
      deleteDespacho,
      esVentaDespacho,
      getFactura,
      totalRecords,
      first,
      lazyParams,
      loadLazyData,
      onPage,
      onSort,
      onFilter,
      getPreciosContrato,
      getPDFSII,
      periodo
    } = useDespachos()
  
    let periodoSelected =  new Date()
    let _periodo

    const selectPeriodo = (e) => {
      periodo.value.val = e !== undefined ? e.getFullYear() + ('0' + (e.getMonth() + 1).toString()).slice(-2) : null
      console.info(periodo)
      if(periodo.value.val !== null){
        periodo.value.label = [periodo.value.val.toString().slice(-2), "/", periodo.value.val.toString().slice(0, 4)].join('');

        let arrDate = periodo.value.label.split("/");
        
        periodoSelected.setDate("01");
        periodoSelected.setMonth(arrDate[0].toString());
        periodoSelected.setFullYear(arrDate[1].toString());
        _periodo = periodo.value.val
      }
      
      loadLazyData()
    }

    const frmchofer = reactive({
      'label' : '+ Chofer',
      'visible' : false,
      'color' : 'text-success mt-4'
    })

    const form = reactive({
      id: null,
      tipoDocumento: '',
      cliente: '',
      origen: '',
      destino: '',
      transportista: '',
      chofer: '',
      despachador: '',
      fsc: '',
      fsc_codigo: '',
      folio: 0,
      fecha: moment().toDate(),
      patente_camion: '',
      patente_carro: '',
      flete: 0,
      poriva: porciva.value,
      neto: '',
      iva: '',
      total: '',
      nro_oc: '',
      fecha_oc: '',
      observacion: '',
      es_venta: true,
      rut_dv: '',
      nombre: '',
      detalles: []
    })

    const rut_format = helpers.regex(/^(\d{1,3}(?:\d{1,3}){2}-[\dkK])$/)

    const rules = {
      tipoDocumento: { required: helpers.withMessage('El campo es requerido o no corresponde a la despacho', required), },
      cliente: { required: helpers.withMessage('El campo es requerido!', required), },
      es_venta: { required: helpers.withMessage('El campo es requerido!', required), },
      origen: { required: helpers.withMessage('El campo es requerido!', required), },
      destino: { required: helpers.withMessage('El campo es requerido!', required), },
      despachador: { required: helpers.withMessage('El campo es requerido!', required), },
      folio: { required: helpers.withMessage('El campo es requerido', required), },
      fecha: { required: helpers.withMessage('El campo es requerido', required), },
      flete: { required: helpers.withMessage('El campo es requerido', required), },
      neto: { required: helpers.withMessage('El campo es requerido', required), },
      iva: { required: helpers.withMessage('El campo es requerido', required), },
      total: { required: helpers.withMessage('El campo es requerido', required), },
      rut_dv: {
        rut_rule: helpers.withMessage('Ingrese un rut válido. Ej: 12345678-0', rut_format),
      },
    }

    const editDespacho = (emp) => {
      lineas.value = []
      despacho.value = { ...emp }
      despachoDialog.value = true
      form.value = {}
      let detalles = isProxy(despacho.value.productos) ? toRaw(despacho.value.productos) : []

      lineas.value = detalles.reduce((acc, ln) => {
        acc.push({
          'producto_obj' : {'id': ln.id, 'nombre': ln.nombre, 'umedida': ln.umedida},
          'producto' : ln.nombre,
          'espesor' : ln.pivot.espesor,
          'ancho' : ln.pivot.ancho,
          'largo' : ln.pivot.largo,
          'cant_despacho' : ln.pivot.cant_despacho,
          'mc_despacho' : ln.pivot.mc_despacho,
          'pulg_despacho' : ln.pivot.pulg_despacho,
          'cant_recepcion' : ln.pivot.cant_recepcion,
          'mc_recepcion' : ln.pivot.mc_recepcion,
          'pulg_recepcion' : ln.pivot.pulg_recepcion,
          'precio' : ln.pivot.precio,
          'tipo_cambio' : ln.pivot.tipo_cambio,
          'total' : ln.pivot.total,
          'total_mask' : ln.pivot.total
        })
        return acc;
      }, [])

      form.detalles = lineas.value
      form.id = despacho.value.id
      form.tipoDocumento = despacho.value.tipoDocumento.id + '-' + despacho.value.tipoDocumento.nombre
      form.cliente = despacho.value.cliente.rut_dv + '-' + despacho.value.cliente.razon_social
      form.origen = despacho.value.origen.id + '-' + despacho.value.origen.descripcion
      form.destino = despacho.value.destino.id + '-' + despacho.value.destino.descripcion
      form.transportista = despacho.value.transportista ? despacho.value.transportista.rut_dv + '-' + despacho.value.transportista.razon_social : null
      form.chofer = despacho.value.chofer ? despacho.value.chofer.rut_dv + '-' + despacho.value.chofer.nombre : null
      form.rut_dv = null
      form.nombre = null

      form.despachador = despacho.value.despachador.rut_dv + '-' +  despacho.value.despachador.nombre
      form.fsc = despacho.value.fsc ? despacho.value.fsc : null
      form.fsc_codigo = despacho.value.fsc_codigo ?? null
      form.folio = despacho.value.folio
      form.fecha = despacho.value.fecha
      form.patente_camion = despacho.value.patente_camion ?? null
      form.patente_carro = despacho.value.patente_carro ?? null
      form.flete = despacho.value.flete
      form.poriva = despacho.value.poriva
      form.neto = despacho.value.neto
      form.iva = despacho.value.iva
      form.total = despacho.value.total
      form.nro_oc = despacho.value.nro_oc
      form.fecha_oc = despacho.value.fecha_oc
      form.observacion = despacho.value.observacion
      form.es_venta = despacho.value.es_venta

      form.created_at = despacho.value.created_at
      form.created_id = despacho.value.created_id
      form.updated_at = despacho.value.updated_at
      form.updated_id = despacho.value.updated_id

      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false
    }

    const duplicarGuia = (gd) => {
      lineas.value = []
      despacho.value = { ...gd }
      despachoDialog.value = true
      form.value = {}
      form.rut_dv = null
      form.nombre = null
      
      let detalles = isProxy(despacho.value.productos) ? toRaw(despacho.value.productos) : []

      lineas.value = detalles.reduce((acc, ln) => {
        acc.push({
          'producto_obj' : {'id': ln.id, 'nombre': ln.nombre, 'metro3': ln.metro3, 'mm': ln.mm, 'mruma': ln.mruma, 'nulo': ln.nulo, 'pulgada': ln.pulgada,'unidad': ln.unidad  },
          'producto' : ln.nombre,
          'espesor' : ln.pivot.espesor,
          'ancho' : ln.pivot.ancho,
          'largo' : ln.pivot.largo,
          'cant_despacho' : ln.pivot.cant_despacho,
          'mc_despacho' : ln.pivot.mc_despacho,
          'pulg_despacho' : ln.pivot.pulg_despacho,
          'cant_recepcion' : ln.pivot.cant_recepcion,
          'mc_recepcion' : ln.pivot.mc_recepcion,
          'pulg_recepcion' : ln.pivot.pulg_recepcion,
          'precio' : ln.pivot.precio,
          'tipo_cambio' : ln.pivot.tipo_cambio,
          'total' : ln.pivot.total,
          'total_mask' : ln.pivot.total
        })
        return acc;
      }, [])

      form.detalles = lineas.value
      form.tipoDocumento = despacho.value.tipoDocumento.id ? toRaw(tipoDocumentos.value.filter(function (itm) { return [despacho.value.tipoDocumento.id].indexOf(itm.id) > -1 }))[0] : null
      form.cliente = despacho.value.cliente ? toRaw(clientes.value.filter(function (itm) { return [despacho.value.cliente.id]. indexOf(itm.id) > -1 }))[0] : null
      form.origen = despacho.value.origen ? toRaw(origenes.value.filter(function (itm) { return [despacho.value.origen.id].indexOf(itm.id) > -1 }))[0] : null
      form.destino = despacho.value.destino ? toRaw(origenes.value.filter(function (itm) { return [despacho.value.destino.id].indexOf(itm.id) > -1 }))[0] : null
      form.transportista = despacho.value.transportista ? toRaw(transportistas.value.filter(function (itm) { return [despacho.value.transportista.id].indexOf(itm.id) > -1 }))[0] : null
      form.chofer = despacho.value.chofer ? toRaw(choferes.value.filter(function (itm) { return [despacho.value.chofer.id].indexOf(itm.id) > -1 }))[0] : null
      form.despachador = despacho.value.despachador ? toRaw(despachadores.value.filter(function (itm) { return [despacho.value.despachador.id].indexOf(itm.id) > -1 }))[0] : null
      form.fsc = despacho.value.fsc !== null ? toRaw(fscs.value.filter(function (itm) { return [despacho.value.fsc.id].indexOf(itm.id) > -1 }))[0] : null
      form.fsc_codigo = despacho.value.fsc_codigo

      form.patente_camion = despacho.value.patente_camion ?? null
      form.patente_carro = despacho.value.patente_carro ?? null
      form.flete = despacho.value.flete
      form.poriva = despacho.value.poriva
      form.neto = despacho.value.neto
      form.iva = despacho.value.iva
      form.total = despacho.value.total
      form.nro_oc = despacho.value.nro_oc
      form.fecha_oc = despacho.value.fecha_oc
      form.observacion = despacho.value.observacion
      form.es_venta = despacho.value.es_venta

      form.created_at = despacho.value.created_at
      form.created_id = despacho.value.created_id
      form.updated_at = despacho.value.updated_at
      form.updated_id = despacho.value.updated_id

      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false

      //nullable para que duplique la guia y lo identifique como un nuevo registro 
      form.id = null
      form.folio = 0
      form.fecha = moment().toDate()
    }

    const openNew = () => {
      despacho.value = {}
      submitted.value = false
      despachoDialog.value = true
      saving.value = false
      lineas.value = []

      form.detalles = lineas.value
      form.id = null
      form.tipoDocumento = null
      form.cliente = null
      form.origen = null
      form.destino = null
      form.transportista = null
      form.chofer = null
      form.rut_dv = null
      form.nombre = null

      form.despachador = null
      form.fsc = null
      form.fsc_codigo = null
      form.folio = 0
      form.fecha = moment().toDate()
      form.patente_camion = null
      form.patente_carro = null
      form.flete = 0
      form.poriva = porciva.value
      form.neto = null
      form.iva = null
      form.total = null
      form.nro_oc = null
      form.fecha_oc = null
      form.observacion = null
      form.es_venta = true

      form.created_at = null
      form.created_id = null
      form.updated_at = null
      form.updated_id = null

      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false
    }

    const addChofer = () => {
      form.rut_dv = null
      form.nombre = null      
      frmchofer.label = frmchofer.visible ? '+ Chofer' : '- Chofer'
      frmchofer.color = frmchofer.visible ? 'text-success mt-4' : 'text-danger mt-4'
      frmchofer.visible = !frmchofer.visible
    }

    const confirmDeleteSelected = () => {
      deleteDespachosDialog.value = true
    }

    const confirmDeleteDespacho = (emp) => {
      despacho.value = emp
      deleteDespachoDialog.value = true
    }

    const confirmEsVentaDespacho = () => {
      if(form.id !== null)
        esVentaDespachoDialog.value = true
    }

    const hideDialog = () => {
      despacho.value = {}
      submitted.value = false
      despachoDialog.value = false
      saving.value = false
      lineas.value = []

      form.detalles = lineas.value
      form.id = null
      form.tipoDocumento = null
      form.cliente = null
      form.origen = null
      form.destino = null
      form.transportista = null
      form.chofer = null
      form.rut_dv = null
      form.nombre = null

      form.despachador = null
      form.fsc = null
      form.fsc_codigo = null
      form.folio = 0
      form.fecha = moment().toDate()
      form.patente_camion = null
      form.patente_carro = null
      form.flete = 0
      form.poriva = porciva.value
      form.neto = null
      form.iva = null
      form.total = null
      form.nro_oc = null
      form.fecha_oc = null
      form.observacion = null
      form.es_venta = true

      form.created_at = null
      form.created_id = null
      form.updated_at = null
      form.updated_id = null

      frmchofer.label = '+ Chofer'
      frmchofer.color = 'text-success mt-4'
      frmchofer.visible = false      
    }

    const searchTipoDocumento = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoDocumentos.value = [...tipoDocumentos.value]
        }
        else {
          filteredTipoDocumentos.value = tipoDocumentos.value.filter((tdocumento) => {
            return tdocumento._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchCliente = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredClientes.value = [...clientes.value]
        }
        else {
          filteredClientes.value = clientes.value.filter((cliente) => {
            return cliente._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchDespachador = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredDespachadores.value = [...despachadores.value]
        }
        else {
          filteredDespachadores.value = despachadores.value.filter((despachador) => {
            return despachador._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchFsc = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredFscs.value = [...fscs.value]
        }
        else {
          filteredFscs.value = fscs.value.filter((fsc) => {
            return fsc.nombre.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const setFscCodigo = (event) => {
      form.fsc_codigo = event.value.codigo
    }

    const searchChofer = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredChoferes.value = [...choferes.value]
        }
        else {
          filteredChoferes.value = choferes.value.filter((chofer) => {
            return chofer._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchDestino = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredDestinos.value = [...destinos.value]
        }
        else {
          filteredDestinos.value = destinos.value.filter((destino) => {
            return destino._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchOrigen = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredOrigenes.value = [...origenes.value]
        }
        else {
          filteredOrigenes.value = origenes.value.filter((origen) => {
            return origen._descripcion.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchTransportista = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTransportistas.value = [...transportistas.value]
        }
        else {
          filteredTransportistas.value = transportistas.value.filter((transportista) => {
            return transportista._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const buscarPrecios = () => {
      getPreciosContrato(form.cliente.id, parseInt(event.srcElement._value.split('-')[0]), form.destino.id)
    }

    const searchProducto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProductos.value = [...productos.value]
        }
        else {
          filteredProductos.value = productos.value.filter((producto) => {
            return producto._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const onCellEditComplete = (event) => {
      let { data, newValue, field } = event
      data[field] = newValue
      messages.value = []
      let { umedida, m3, pulg } = 0
      let prd = toRaw(data.producto_obj)

      //format values
      data['espesor'] = parseFloat((Math.round(parseFloat(data['espesor']) * 100) / 100).toFixed(2))
      data['ancho'] = parseFloat((Math.round(parseFloat(data['ancho']) * 100) / 100).toFixed(2))
      data['largo'] = parseFloat((Math.round(parseFloat(data['largo']) * 100) / 100).toFixed(2))
      data['cant_despacho'] = parseFloat((Math.round(parseFloat(data['cant_despacho']) * 1000) / 1000))
      data['cant_recepcion'] = parseFloat((Math.round(parseFloat(data['cant_despacho']) * 1000) / 1000).toFixed(3))
      data['tipo_cambio'] = parseFloat((Math.round(parseFloat(data['tipo_cambio']) * 1000) / 1000).toFixed(3))

      if(!field.includes('producto')){
        //define la unidad de medida del producto
        $.each(prd, function(key,val) {
          if("/pulgada|metro3|unidad|mm|mruma/".includes(key) && val === 1) umedida = key
        })
        
        //calcula los m3 y pulgadas
        if(umedida === 'pulgada') {
          // M3 = ((((espesor*ancho*largo)/32)/48.432)*cantidad) * tipo cambio	=    1,577
          m3 = parseFloat(((((data['espesor'] * data['ancho'] * data['largo']) / Number(32)) / parseFloat(48.432).toFixed(3)) * data['cant_despacho']) * data['tipo_cambio'])

          // Pulgadas	 =	(((espesor*ancho*largo)/32)*cantidad) * tipo cambio =   76,381
          pulg = parseFloat((((data['espesor'] * data['ancho'] * data['largo']) / Number(32)) *data['cant_despacho']) * data['tipo_cambio'])

        }else if(umedida === 'mm'){
          // M3 = (((espesor*ancho*largo)/1000000)*cantidad) * tipo cambio		  =    1,920
          m3 = parseFloat((((data['espesor'] * data['ancho'] * data['largo']) / Number(1000000)) * data['cant_despacho']) * data['tipo_cambio'])

          // Pulgadas	 =	(((espesor*ancho*largo)/1000000)*cantidad)* tipo cambio =   92,989
          pulg = parseFloat((((data['espesor'] * data['ancho']* data['largo']) / Number(1000000)) * parseFloat(48.432).toFixed(3) * data['cant_despacho']) * data['tipo_cambio'])
        }else if(umedida === 'mruma'){
          m3 = parseFloat(parseFloat(0).toFixed(2))
          pulg = parseFloat(parseFloat(0).toFixed(2))
        }else {
          m3 = parseFloat(parseFloat(0).toFixed(2))
          pulg = parseFloat(parseFloat(0).toFixed(2))
        }

        data['total'] = Number(data['precio']) * Number(data['cant_despacho'])
        data['total_mask'] = Number(Number(data['total'].toFixed(0)))

        data['mc_despacho'] = parseFloat((Math.round(m3 * 100 ) / 100).toFixed(2))
        data['pulg_despacho'] = parseFloat((Math.round(pulg * 100 ) / 100).toFixed(2))

        data['mc_recepcion'] = parseFloat((Math.round(m3 * 100 ) / 100).toFixed(2))
        data['pulg_recepcion'] = parseFloat((Math.round(pulg * 100 ) / 100).toFixed(2))

      }else
        if(newValue.nombre !== undefined)
          if (newValue.nombre.trim().length > 0) {
            data[field] = newValue.id +'-'+ newValue.nombre
            data['producto_obj'] = newValue
            data['precio'] = Number(precio.value.valor)
            messages.value = []
          }else event.preventDefault()
    }

    const formatCurrency = (value, style = true) => {
      return style  ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(Number(value))
                    : new Intl.NumberFormat('es-CL').format(Number(value.toFixed(0)))
    }

    const toggleDelete= (index) => {
      let row = {
        'producto_obj' : '',
        'producto' : '',
        'espesor' : 0,
        'ancho' : 0,
        'largo' : 0,
        'cant_despacho' : 0,
        'mc_despacho' : 0,
        'pulg_despacho' : 0,
        'cant_recepcion' : 0,
        'mc_recepcion' : 0,
        'pulg_recepcion' : 0,
        'precio' : 0,
        'tipo_cambio' : 1,
        'total' : 0,
        'total_mask' : 0
      }
      // lineas.value[index] = row
      lineas.value.splice(index, 1)
      messages.value = []
    }

    const upperCase = ($event, field) => {
      form[field] = $event.toUpperCase()
    }

    const addLinea = () => {
      let row = {
        'producto_obj' : '',
        'producto' : '',
        'espesor' : 0,
        'ancho' : 0,
        'largo' : 0,
        'cant_despacho' : 0,
        'mc_despacho' : 0,
        'pulg_despacho' : 0,
        'cant_recepcion' : 0,
        'mc_recepcion' : 0,
        'pulg_recepcion' : 0,
        'precio' : 0,
        'tipo_cambio' : 1,
        'total' : 0,
        'total_mask' : 0
      }

      if(lineas.value.length === 0)
        lineas.value.push(row)
      else
        if(lineas.value[lineas.value.length - 1].producto_obj !== '')
          lineas.value.push(row)
        else
          messages.value = [{ content: 'Debe seleccionar el producto antes de agregar nueva línea', id: lineas.value.length }]
    }

    const esVenta = () => {
      if(form.id !== null)
        esVentaDespacho(form.es_venta ? 1 : 0)
    }

    const saveDespacho = () => {
      submitted.value = true
      if(!v$._value.$invalid){
        if(lineas.value.length > 0){
          if(lineas.value[lineas.value.length - 1].producto_obj !== ''){
            messages.value = []
            form.detalles = lineas
            storeDespacho(form)
          }else
            messages.value = [{ content: 'Debe seleccionar el producto antes de guardar la guia', id: lineas.value.length }]
        }else
          messages.value = [{ content: 'Debe seleccionar el producto antes de guardar la guia', id: lineas.value.length }]
      }
    }

    const netoGuia = computed(() => {
      let neto = 0;
      for(let ln of lineas.value) {
        neto += Number(ln.total)
      }
      form.neto = neto
      return formatCurrency(neto)
    })

    const ivaGuia = computed(() => {
      let iva = 0;
      iva = form.neto * form.poriva / 100
      form.iva = iva
      return formatCurrency(iva)
    })

    const totalGuia = computed(() => {
      let total = 0;
      total = form.neto + form.iva
      form.total = form.neto + form.iva
      return formatCurrency(total)
    })

    const dummyClick = () => {
      document.getElementById('dummy').click()
    }

    const v$ = useVuelidate(rules, form)

    onMounted(() => {
      loading.value = true
      lazyParams.value = {
        first: dt.value.first,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }
      selectPeriodo()
      // loadLazyData()
    })    

    return {
      dt,
      loading,
      saving,
      despacho,
      chofer,
      fsc,
      fscs,
      despachos,
      productos,
      precio,
      lineas,
      columns,
      choferes,
      tipoDocumentos,
      clientes,
      despachadores,
      transportistas,
      filters,
      initFilters,
      selectedDespachos,
      deleteDespachosDialog,
      deleteDespachoDialog,
      esVentaDespachoDialog,
      despachoDialog,
      submitted,
      filteredTipoDocumentos,
      filteredClientes,
      filteredDespachadores,
      filteredFscs,
      filteredChoferes,
      filteredDestinos,
      filteredOrigenes,
      filteredProductos,
      dummyClick,
      filteredTransportistas,
      es_venta,
      porciva,
      v$,
      form,
      messages,
      netoGuia,
      ivaGuia,
      totalGuia,
      setFscCodigo,
      hideDialog,
      openNew,
      confirmDeleteDespacho,
      deleteSelectedDespachos,
      deleteDespacho,
      confirmDeleteSelected,
      searchTipoDocumento,
      searchProducto,
      searchCliente,
      searchDespachador,
      searchFsc,
      searchChofer,
      searchDestino,
      searchTransportista,
      searchOrigen,
      editDespacho,
      upperCase,
      saveDespacho,
      storeDespacho,
      formatCurrency,
      onCellEditComplete,
      addLinea,
      toggleDelete,
      esVenta,
      confirmEsVentaDespacho,
      frmchofer,
      addChofer,
      getFactura,
      totalRecords,
      first,
      lazyParams,
      loadLazyData,
      onPage,
      onSort,
      onFilter,
      getPreciosContrato,
      duplicarGuia,
      buscarPrecios,
      getPDFSII,
      periodo,
      selectPeriodo,
      periodoSelected,
    }
  },
}
</script>
