<template>
  <Toast />
  <div class="card-header">
    <h4>Listado de Origenes - Destinos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Usuarios -->
      <DataTable ref="dt" :value="origenDestinos" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" :filters="filters"
        v-model:selection="selectedOrigenDestinos" responsiveLayout="scroll" breakpoint="990px">

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>

            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
              &nbsp;
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectedOrigenDestinos || !selectedOrigenDestinos.length" />
              &nbsp;
              <Button label="Descargar" icon="pi pi-download" class="p-button-help" @click="exportCSV($event)" />
            </template>
          </Toolbar>
        </template>

        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

        <Column field="descripcion" header="Descripción" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.descripcion }}
            </span>
          </template>
        </Column>

        <Column field="flete" header="Flete" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.flete }}
            </span>
          </template>
        </Column>

        <Column field="vigente" header="Estado" >
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>   
        </Column>
        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editOrigenDestino(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteOrigenDestino(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario OrigenDestinos en modal -->
    <Dialog v-model:visible="origenDestinoDialog" :style="{ width: '50%' }" header="Datos del Origen - Destino" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-12 field">
            <label for="descripioc" class="mt-3">Descripción</label>
            <InputText id="descripioc" v-model="v$.descripcion.$model"
              :class="{ 'p-invalid': v$.descripcion.$invalid && submitted }" aria-describedby="descripcion-error" autofocus
              autocomplete="off" />
            <span v-if="v$.descripcion.$error && submitted">
              <span id="descripcion-error" v-for="(error, index) of v$.descripcion.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.descripcion.$invalid && submitted) || v$.descripcion.$pending.$response" class="p-error">
              {{ v$.descripcion.required.$message.replace('Value', 'RUT') }}</small>
          </div>        
        </div>

        <div class="row">
          <div class="col-md-12 field">
            <label for="flete" class="mt-3">Flete</label>
            <InputNumber id="flete" v-model="v$.flete.$model" :class="{ 'p-invalid': v$.flete.$invalid && submitted }"
              aria-describedby="flete-error" autofocus autocomplete="off" />
            <span v-if="v$.flete.$error && submitted">
              <span id="flete-error" v-for="(error, index) of v$.flete.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.flete.$invalid && submitted) || v$.flete.$pending.$response" class="p-error">
              {{ v$.flete.required.$message.replace('Value', 'Nombre') }}</small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <InputText id="observacion" v-model.trim="form.observacion" />
          </div>          
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveOrigenDestino" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un origenDestino -->
    <Dialog v-model:visible="deleteOrigenDestinoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="origenDestino"> ¿Está seguro de borrar el Origen Destino?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrigenDestinoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteOrigenDestino" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente origenDestinos -->
    <Dialog v-model:visible="deleteOrigenDestinosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedOrigenDestinos"> ¿Está seguro de borrar los origenes - destinos seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrigenDestinosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedOrigenDestinos" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useOrigenDestinos from '../../composables/cruds/odestinos'
import { reactive, onMounted } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      origenDestino,
      origenDestinos,
      dt, 
      loading, 
      saving,
      filters,
      selectedOrigenDestinos,
      deleteOrigenDestinosDialog,
      deleteOrigenDestinoDialog,
      origenDestinoDialog,
      submitted,
      storeOrigenDestino,      
      deleteSelectedOrigenDestinos,
      getCRUD,
      deleteOrigenDestino
    } = useOrigenDestinos()

    const form = reactive({
      descripcion: '',
      flete: '',
      observacion: '',
      vigente: ''
    })

    const rules = {
      descripcion: { required: helpers.withMessage('El campo es requerido', required)},
      flete: { required: helpers.withMessage('El campo es requerido', required), },
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      // console.info(dt.value)
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteOrigenDestinosDialog.value = true
    }

    const confirmDeleteOrigenDestino = (desp) => {
      origenDestino.value = desp
      deleteOrigenDestinoDialog.value = true
    }

    const openNew = () => {
      origenDestino.value = {}
      submitted.value = false
      origenDestinoDialog.value = true
      saving.value = false

      form.descripcion = ''
      form.flete = ''
      form.observacion = ''
      form.vigente = true

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const hideDialog = () => {
      origenDestinoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const editOrigenDestino = (desp) => {
      origenDestino.value = { ...desp }
      origenDestinoDialog.value = true

      form.descripcion = origenDestino.value.descripcion
      form.flete = origenDestino.value.flete
      form.observacion = origenDestino.value.observacion
      form.vigente = origenDestino.value.vigente
      
      form.created_at = origenDestino.value.created_at
      form.created_id = origenDestino.value.created_id
      form.updated_at = origenDestino.value.updated_at
      form.updated_id = origenDestino.value.updated_id
    }

    const saveOrigenDestino = () => {
      submitted.value = true
      if(!v$._value.$invalid)
        storeOrigenDestino(form)  
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      origenDestino,
      origenDestinos,
      filters,
      selectedOrigenDestinos,
      deleteOrigenDestinosDialog,
      deleteOrigenDestinoDialog,
      origenDestinoDialog,
      submitted,
      v$,
      form,
      hideDialog,
      openNew,
      confirmDeleteOrigenDestino,
      deleteSelectedOrigenDestinos,
      deleteOrigenDestino,
      exportCSV,
      confirmDeleteSelected,
      editOrigenDestino,
      saveOrigenDestino,
      storeOrigenDestino
    }
  },
}
</script>