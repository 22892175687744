<template>
	<div class="horizontal-menu">
		<nav class="navbar top-navbar">
			<div class="container bg_green">
				<div class="navbar-content">
					<nav class="bottom-navbar">
						<div class="container bg_green">
							<ul class="nav page-navigation">
								<li v-if="[0, 1, 3].includes(userRole)" class="nav-item" :class="(SM_despachos.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="truck"></i>
										<span class="menu-title">Despacho</span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item">
												<router-link to="/despachos" class="nav-link" :class="routeName === 'despachos' ? 'active' : ''">
													<span class="link-title">Listar / Crear</span>
												</router-link>
											</li>
											<li class="nav-item">
												<router-link to="/agdespachos" class="nav-link" :class="routeName === 'agdespachos' ? 'active' : ''">
													<span class="link-title">Actualizar Guías de Despacho</span>
												</router-link>
											</li>
										</ul>
									</div>
								</li>
								<li v-if="[0, 1, 2, 3].includes(userRole)" class="nav-item" :class="(SM_recepciones.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="package"></i>
										<span class="menu-title">Recepción</span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/recepciones" class="nav-link" :class="routeName === 'recepciones' ? 'active' : ''">
													<span class="link-title">Listar / Crear</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/recepcion/rpagos" class="nav-link" :class="routeName === 'rpagos' ? 'active' : ''">
													<span class="link-title">Gestionar Pagos</span>
												</router-link>
											</li><li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/agrecepcion" class="nav-link" :class="routeName === 'agrecepcion' ? 'active' : ''">
													<span class="link-title">Actualizar Recepción</span>
												</router-link>
											</li>
										</ul>
									</div>
								</li>
								<li v-if="[0, 1].includes(userRole)" class="nav-item" :class="(SM_procesos.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="zap"></i>
										<span class="menu-title">Procesos</span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item">
												<router-link to="/facturas" class="nav-link" :class="routeName === 'facturas' ? 'active' : ''">
													<span class="link-title">Facturación</span>
												</router-link>
											</li>											
											<li class="nav-item">
												<router-link to="/contratos" class="nav-link" :class="routeName === 'contratos' ? 'active' : ''">
													<span class="link-title">Contratos</span>
												</router-link>
											</li>
											<li class="nav-item">
												<router-link to="/demitidos" class="nav-link" :class="routeName === 'demitidos' ? 'active' : ''">
													<span class="link-title">Documentos Emitidos</span>
												</router-link>
											</li>
										</ul>
									</div>
								</li>
								<li v-if="[0, 1, 2, 3].includes(userRole)" class="nav-item" :class="(SM_rpacsii.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="file-minus"></i>
										<span class="menu-title">Compras</span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/rpacompras" class="nav-link" :class="routeName === 'rpacompras' ? 'active' : ''">
													<span class="link-title">Libro de Compras SII</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/cclasif" class="nav-link" :class="routeName === 'cclasif' ? 'active' : ''">
													<span class="link-title">Clasificación de Gastos</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/cpagos" class="nav-link" :class="routeName === 'cpagos' ? 'active' : ''">
													<span class="link-title">Estado de Pagos</span>
												</router-link>
											</li>
										</ul>
									</div>
								</li>
								<li v-if="[0, 1].includes(userRole)" class="nav-item" :class="(SM_rpavsii.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="file-plus"></i>
										<span class="menu-title">Ventas</span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item">
												<router-link to="/rpaventas" class="nav-link" :class="routeName === 'rpaventas' ? 'active' : ''">
													<span class="link-title">Libro de Ventas SII</span>
												</router-link>
											</li>
                      <li class="nav-item">
                        <router-link to="/vcobros" class="nav-link" :class="routeName === 'vcobros' ? 'active' : ''">
                          <span class="link-title">Estado de Cobros</span>
                        </router-link>
                      </li>											
										</ul>
									</div>
								</li>
								<li v-if="[0, 1, 3].includes(userRole)" class="nav-item" :class="(SM_produccion.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="calendar"></i>
										<span class="menu-title">Producción</span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item">
												<router-link to="/produccion" class="nav-link" :class="routeName === 'produccion' ? 'active' : ''">
													<span class="link-title">Ingreso Diario</span>
												</router-link>
											</li>
										</ul>
									</div>
								</li>
								<li v-if="[0, 1, 2, 3].includes(userRole)" class="nav-item" :class="(SM_cruds.match(routeName)) ? 'active' : ''">
									<a href="#" class="nav-link">
										<i class="link-icon" data-feather="settings"></i>
										<span class="menu-title"></span>
										<i class="link-arrow"></i>
									</a>
									<div class="submenu">
										<ul class="submenu-item">
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/users" class="nav-link" :class="routeName === 'users' ? 'active' : ''">
													<span class="link-title">Usuarios</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/empresas" class="nav-link" :class="routeName === 'empresas' ? 'active' : ''">
													<span class="link-title">Empresas</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/pprecios" class="nav-link" :class="routeName === 'pprecios' ? 'active' : ''">
													<span class="link-title">Valores Proveedores</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/choferes" class="nav-link" :class="routeName === 'choferes' ? 'active' : ''">
													<span class="link-title">Choferes</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/despachadores" class="nav-link" :class="routeName === 'despachadores' ? 'active' : ''">
													<span class="link-title">Despachadores</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1, 2, 3].includes(userRole)">
												<router-link to="/odestinos" class="nav-link" :class="routeName === 'odestinos' ? 'active' : ''">
													<span class="link-title">Origenes - Destinos</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/productos" class="nav-link" :class="routeName === 'productos' ? 'active' : ''">
													<span class="link-title">Productos</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1].includes(userRole)">
												<router-link to="/tdocumentos" class="nav-link" :class="routeName === 'tdocumentos' ? 'active' : ''">
													<span class="link-title">Tipos Documentos</span>
												</router-link>
											</li>
											<li class="nav-item" v-if="[0, 1, 2].includes(userRole)">
												<router-link to="/gastos" class="nav-link" :class="routeName === 'gastos' ? 'active' : ''">
													<span class="link-title">Tipo de Gastos</span>
												</router-link>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
	name: "Menu",
	data() {
		return {
			userRole: window.Laravel.user.seg_rol_id,
			routeName: null, //se usa para marcar la opcion activa
			//opciones por submenus, se usa para marcar el submenu activo
			SM_despachos: 'despachos agdespachos',
			SM_recepciones: 'recepciones agrecepcion /informes/recepcion rpagos',
			SM_procesos: 'contratos facturas demitidos',
			SM_rpacsii: 'rpacompras',
			SM_rpavsii: 'rpaventas',
			SM_gastos: 'cpagos cclasif',
			SM_ingresos: 'vcobros vclasif',
			SM_produccion: 'produccion /informes/prod',
			SM_informes: '/informes/recepcion /informes/prod /informes/despacho',
			SM_cruds: 'users empresas pprecios choferes despachadores odestinos productos tdocumentos gastos'
		}
	},
	watch: {
		'$route': 'currentRoute'
	},

	methods: {
		currentRoute(e) {
			this.$nextTick(() => {
				this.routeName = window.location.pathname.substring(1, 1000)
			})
		}
	},
}
</script>
