import { ref } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'

export default function useDashboards() {
  const spin = ref({})

  const kpiProdCant = ref({})
  const kpiRecMnt = ref({})
  const kpiRecPago = ref({})
  const kpiEdosVtas = ref({})
  const kpiEdosVtasDet = ref([])
  const kpiVtaUm = ref([])
  const kpiProdProm = ref({})
  const kpiGastos = ref({})
  const kpiCompraEdo = ref({})
  const style_estado = ref("background: #ccc !important;color: #000 !important;")
  const loading = ref(false)

  const kpiProdChart = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpiprod = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpiprod`).then(response => {
      kpiProdProm.value = response.data.data.promedio
      kpiProdCant.value = {
        animationEnabled: true,
        theme: "light2",
        title:{
          text: "VOLÚMEN PRODUCCIÓN DIARIA",
          fontSize: 16,
          margin: 20
        },        
        axisY:{
          gridThickness: 0
        },
        legend: {
          cursor:"pointer"
        },
        toolTip:{  
          enabled: false
        },
        width:590,
        height:300,
        dataPointWidth: 200,
        data: [
          {
            indexLabel: `${response.data.data.cantidad[0].y}`,
            type: "column",
            color: "#17a2b8",
            dataPoints: [response.data.data.cantidad[0]]
          },
          {
            indexLabel: `${response.data.data.cantidad[1].y}`,
            type: "column",
            color: "#28a745",
            dataPoints: [response.data.data.cantidad[1]]
          }        
        ]
      }
      spin.value.kpiprod = false
    })
    .catch(function (error) { 
      spin.value.kpiprod = false
    })
  }

  const kpiEdosVtasGet = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpiedovtas = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpiedovtas`).then(response => {
      kpiEdosVtas.value.pacant_docs = response.data.data[0]['PAGADA'] ? response.data.data[0]['PAGADA'].cant_docs : 0
      kpiEdosVtas.value.patotal = response.data.data[0]['PAGADA'] ? response.data.data[0]['PAGADA'].total : 0

      kpiEdosVtas.value.pecant_docs = response.data.data[0]['PENDIENTE'] ? response.data.data[0]['PENDIENTE'].cant_docs : 0
      kpiEdosVtas.value.petotal = response.data.data[0]['PENDIENTE'] ? response.data.data[0]['PENDIENTE'].total : 0

      kpiEdosVtas.value.vecant_docs = response.data.data[0]['VENCIDA'] ? response.data.data[0]['VENCIDA'].cant_docs : 0
      kpiEdosVtas.value.vetotal = response.data.data[0]['VENCIDA'] ? response.data.data[0]['VENCIDA'].total : 0

      kpiEdosVtas.value.secant_docs = response.data.data[0]['SESTADO'] ? response.data.data[0]['SESTADO'].cant_docs : 0
      kpiEdosVtas.value.setotal = response.data.data[0]['SESTADO'] ? response.data.data[0]['SESTADO'].total : 0

      spin.value.kpiedovtas = false
    })
    .catch(function (error) { 
      spin.value.kpiedovtas = false
    })
  }

  const kpiEdoVtasDetalles = async(periodo, estado) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpiedovtasdet = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/${estado}/kpiedovtasdet`).then(response => {
      kpiEdosVtasDet.value = response.data.data.detedo
      spin.value.kpiedovtasdet = false
    })
    .catch(function (error) { 
      spin.value.kpiedovtasdet = false
    })
  }

  const kpiVtaUmedida = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpivtaum = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpivtaum`).then(response => {
      kpiVtaUm.value = response.data.data.vtaum
      spin.value.kpivtaum = false
    })
    .catch(function (error) { 
      spin.value.kpivtaum = false
    })
  }

  const kpiRecepProdMonto = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpiprod = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpirecmnt`).then(response => {
      kpiRecMnt.value = {
        animationEnabled: true,
        theme: "light2",
        title:{
          text: "RECEPCIONES X PRODUCTO / MONTO",
          fontSize: 16,
          margin: 20
        },   
        legend: {
          cursor:"pointer"
        },
        axisY:{
          gridThickness: 0
        },                
        toolTip: {
          shared: true,
          contentFormatter: function (e) {
            var content = " ";
            for (var i = 0; i < e.entries.length; i++) {
              content += e.entries[i].dataSeries.label + " " + "<strong>" + e.entries[i].dataPoint.um + ":</strong> $" + new Intl.NumberFormat('es-CL').format(Number(e.entries[i].dataPoint.mnt)) 
              content += "<br/>";
            }
            return content;
          }
        },        
        width:900,
        height:360,
        data: [{
          type: "bar",
          indexLabel: "{y}",
          dataPoints: response.data.data.kpiprodmnt
        }]
      }
      spin.value.kpirecmnt = false
    })
    .catch(function (error) { 
      spin.value.kpirecmnt = false
    })
  }

  const kpiRecepPago = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpirecpago = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpirecpago`).then(response => {
      kpiRecPago.value = {
        animationEnabled: true,
        theme: "light2",
        title:{
          text: "RECEPCIONES ESTADOS DE PAGOS",
          fontSize: 16,
          margin: 20
        },   
        data: [{
          type: "pie",
          indexLabel: "{label} (#percent%)",
          yValueFormatString: "#,##0",
          toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y}(#percent%)",
          dataPoints: response.data.data.kpirecpago
        }]
      }
      spin.value.kpirecpago = false
    })
    .catch(function (error) { 
      spin.value.kpirecpago = false
    })
  }

  const kpiClasifGastos = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpigastos = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpigastos`).then(response => {
      kpiGastos.value = {
        animationEnabled: true,
        theme: "light2",
        title:{
          text: "CLASIFICACIÓN DE GASTOS",
          fontSize: 16,
          margin: 20
        },
        axisY:{
          gridThickness: 0
        },           
        data: [{
          type: "column",
          indexLabel: "{y}",
          dataPoints: response.data.data.kpigastos
        }]        
      }
      spin.value.kpigastos = false
    })
    .catch(function (error) { 
      spin.value.kpigastos = false
    })
  }

  const kpiCompraEdoPago = async(periodo) => {
    let arrDate = periodo.split("/");
    let fdesde = moment().format(arrDate[1].toString() + "-" + arrDate[0].toString() + "-01");
    let fhasta = moment().format(arrDate[1].toString()+"-" + arrDate[0].toString() + "-" + moment(fdesde, "YYYY-MM-DD").endOf('month').format('D'));
    spin.value.kpicedop = true

    axios.get(`/api/dashboard/${fdesde}/${fhasta}/kpicedop`).then(response => {
      kpiCompraEdo.value = {
        animationEnabled: true,
        theme: "light2",
        title:{
          text: "GASTOS ESTADOS DE PAGOS",
          fontSize: 16,
          margin: 20
        },   
        data: [{
          type: "pie",
          indexLabel: "{label} (#percent%)",
          yValueFormatString: "#,##0",
          toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y}(#percent%)",
          dataPoints: response.data.data.kpicedop
        }]
      }
      spin.value.kpicedop = false
    })
    .catch(function (error) { 
      spin.value.kpicedop = false
    })
  }

  const router = useRouter()
  return {
    style_estado, 
    loading,      
    router,
    spin,
    kpiProdChart,
    kpiProdCant,
    kpiProdProm,
    kpiEdosVtasGet,
    kpiEdosVtas,
    kpiEdosVtasDet,
    kpiEdoVtasDetalles,
    kpiVtaUmedida,
    kpiVtaUm,
    kpiRecepProdMonto,
    kpiRecMnt,
    kpiRecPago,
    kpiRecepPago,
    kpiClasifGastos,
    kpiGastos,
    kpiCompraEdoPago,
    kpiCompraEdo
  }
}