import { ref, isProxy, toRaw } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useAGDespachos() {

  const factura = ref([])
  const chofer = ref([])
  const fsc = ref([])
  const facturas = ref([])
  const choferes = ref([])
  const guias = ref([])
  const fscs = ref([])
  const facturasDelete = ref([])
  const un_producto = ref(false)
  const porciva = ref()
  const nextid = ref()
  const messages = ref([])

  const resultado = ref([])
  const client = useClient()
  const error = ref({})

  const clientes = ref([])
  const despachadores = ref([])
  const destinos = ref([])
  const origenes = ref([])
  const transportistas = ref([])
  const productos = ref([])
  const selectedProductos = ref([])
  const selectedGuias = ref([])
  const lineas = ref([])
  const codguias = ref([])

  const tipoDetalle = ref([
    {name: "Detalles según guia(s)", key: "G"},
    {name: "Detalles según productos", key: "P"}
  ])

  const detalleTipo = ref({name: "Detalles según guia(s)", key: "G"})

  const selectedTpago = ref([])
  const tpagos = ref([])

  const columns = ref([
    { field: 'idx', header: 'Ln.', hidden: false },
    { field: 'producto_obj', header: 'Cod.', hidden: true },
    { field: 'producto', header: 'Producto', hidden: false },
    { field: 'espesor', header: 'Espesor', hidden: false },
    { field: 'ancho', header: 'Ancho', hidden: false },
    { field: 'largo', header: 'Largo', hidden: false },
    { field: 'cant_factura', header: 'Cantidad', hidden: false },
    { field: 'mc_factura', header: 'M3', hidden: false },
    { field: 'pulg_factura', header: 'Pulgadas', hidden: false },
    { field: 'cant_recepcion', header: 'Cantidad', hidden: false },
    { field: 'mc_recepcion', header: 'M3', hidden: false },
    { field: 'pulg_recepcion', header: 'Pulgadas', hidden: false },
    { field: 'precio', header: 'Valor', hidden: false },
    { field: 'tipo_cambio', header: 'Tipo Cambio', hidden: false },
    { field: 'total', header: 'Total', hidden: true },
    { field: 'total_mask', header: 'Total', hidden: false }
  ])

  const dt = ref({})
  const dtg = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedFacturas = ref()
  const deleteFacturasDialog = ref(false)
  const deleteFacturaDialog = ref(false)
  const facturaDialog = ref(false)
  const submitted = ref(false)

  const filteredClientes = ref()
  const filteredFscs = ref()
  const filteredProductos = ref()
  const editingRows = ref([])

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/despachos/act/crud`).then(response => {
      clientes.value = response.data.data.clientes
      productos.value = response.data.data.productos
      loading.value = false
    })
  }

  const actualizaGuia = async (form, guias) => {
    saving.value = true
    //Esta editando el registro
    client.put(`/api/despachos/act/guias`, { 'data': { ...guias } })
    .then(() => {
      messages.value = []
      let cliente = isProxy(form.cliente) ? toRaw(form.cliente) : null
      let producto = isProxy(form.producto) ? toRaw(form.producto).id : 0
        getGuias(
        cliente.id,
        form.fdesde.toLocaleDateString("es-CL"),
        form.fhasta.toLocaleDateString("es-CL"),
        producto
      )
    })
  }

  const deleteFactura = async () => {
    client.remove(`/api/facturas/${factura.value.id}`)
      .then(response => {
        facturas.value = facturas.value.filter(val => val.id !== factura.value.id)
        deleteFacturaDialog.value = false
        factura.value = {}
      })
  }

  const deleteSelectedFacturas = async () => {
    facturasDelete.value = selectedFacturas.value.map(({ id }) => id)
    client.remove(`/api/facturas/destroy/mass`, { data: facturasDelete.value })
      .then(response => {
        facturas.value = facturas.value.filter(
          (val) => !selectedFacturas.value.includes(val)
        )
        deleteFacturasDialog.value = false
        selectedFacturas.value = null
        facturasDelete.value = null
      })
  }

  const getGuias = async (id,fdesde,fhasta,pid) => {
    loading.value = true
    client.get(`/api/despachos/${id}/${fdesde}/${fhasta}/act/guias/${pid}`).then(response => {
      guias.value = response.data.data.guias
      fscs.value = response.data.data.fscs
      porciva.value = response.data.data.porciva
      loading.value = false
    })   
  }

  const uploadCarga = (event, form) => {
    console.info('form', form) 
    resultado.value = [];
    if (event.files[0].type !== 'text/csv') {
      error.value.state = true;
      error.value.message = 'Tipo de archivo no válido, debe ser csv';
      error.value.progress = false;
    } else if ((event.files[0].size / 1024 / 1024) > 5) {
      error.value.state = true;
      error.value.message = 'Tamaño de archivo excedido Max. 5MB';
      error.value.progress = false;
    } else {
      error.value.state = false;
      error.value.message = '';

      let cliente = isProxy(form.cliente) ? toRaw(form.cliente) : null
      altaMasiva(event, cliente.id);
    }
  }

  const altaMasiva = async (event, cid) => {
    //Esta creando la carga mass de fletes
    error.value.progress = true
    let data = new FormData()
    data.append('file_guias', event.files[0])
    data.append('cliente_id', cid)

    client.post('api/despachos/actmass', { 'data': data, 'headers': { "Content-Type": "multipart/form-data" } })
      .then((response) => {
        if(response.status === 500){
          error.value.progress = false
          resultado.value[0] = response.data.data.error
        }else{
          resultado.value = response.data.data.result
          error.value.progress = false
        }
      })    
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    factura,
    fsc,
    chofer,
    un_producto,
    porciva,
    facturas,
    fscs,
    choferes,
    guias,
    clientes,
    despachadores,
    nextid,
    destinos,
    origenes,
    dt,
    dtg,
    loading,
    saving,
    filters,
    selectedFacturas,
    selectedTpago,
    tpagos,
    deleteFacturasDialog,
    deleteFacturaDialog,
    facturaDialog,
    submitted,
    filteredClientes,
    filteredFscs,
    filteredProductos,
    selectedProductos,
    selectedGuias,
    productos,
    transportistas,
    lineas,
    codguias,
    columns,
    messages,
    tipoDetalle,
    detalleTipo,
    editingRows,
    deleteSelectedFacturas,
    getCRUD,
    getGuias,
    error,
    actualizaGuia,
    deleteFactura,
    uploadCarga,
    resultado
  }
}