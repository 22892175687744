<template>
  <Toast />
  <div class="card-header">
    <h4>Informes de Recepciones</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-2 field mt-1">
        <label for="tipoInforme">Informe</label>
        <AutoComplete id="tipoInforme" name="tipoInforme"
          :suggestions="filteredTipos" field="label" v-model.trim="tipoInforme"
          @complete="searchTipo($event)" :dropdown="true" forceSelection
          autofocus autocomplete="off" @change="getProveedores($event)"/>
      </div>
      <div class="col-md-2 field mt-1" v-if="tipoInforme ? (tipoInforme.value === 'proveedor' || tipoInforme.value === 'proveedorxls' ? 'proveedor' : null) : null">
        <label for="proveedor">Proveedor</label>
        <AutoComplete id="proveedor" name="proveedor" v-model.trim="proveedor"
          :suggestions="filteredProveedores" field="_razon_social"
          @complete="searchProveedor($event)" :dropdown="true"
          autofocus autocomplete="off"/>
      </div>
      <div class="col-md-2 field mt-1">
        <label for="fdesde">Desde:&nbsp;</label>
        <Calendar
          id="fdesde"
          aria-describedby="fdesde-error"
          v-model="fdesde"
          inputClass="bg-input"
          autocomplete="off"
          dateFormat="dd-mm-yy"
          :showIcon="true"
        />&nbsp;
      </div>
      <div class="col-md-2 field mt-1">
        <label for="fhasta">Hasta:&nbsp;</label>
        <Calendar
          id="fhasta"
          aria-describedby="fhasta-error"
          v-model="fhasta"
          inputClass="bg-input"
          autocomplete="off"
          dateFormat="dd-mm-yy"
          :showIcon="true"
        />&nbsp;
      </div>
      <div class="col-md-2 field mt-1" v-if="tipoInforme.value !== 'proveedorxls'" >
        <label for="button">&nbsp;</label>
        <Button id="button" type="button" label="Generar Informe" icon="pi pi-search" :loading="loading" severity="info" @click="genInforme" style="margin-top: 1.3rem !important;"/>
      </div>
      <div class="col-md-2 field mt-4" v-else>
        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" class="me-4"/>
      </div>
      <div class="col-md-2 field mt-1" v-if="(tipoInforme.value === 'fsc' || tipoInforme.value === 'proveedor') && informeData.length > 0">
        <label for="button">&nbsp;</label>
        <download-excel class="btn btn-success" :data="informeData" :fields = "informeHeader" type="xlsx" name="recepcionesFSC.xlsx" style="margin-top: 1.3rem !important; border-radius: 0px !important; height: 40px !important; padding: .5rem .5rem; font-size: 1rem; font-weight: bold;" v-if="tipoInforme.value === 'fsc'">Excel FSC
        </download-excel>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 field mt-4">
        <InlineMessage v-for="msg of messages" :key="msg.id" severity="info" style="width: 100%;">{{ msg.content }}</InlineMessage>
      </div>
    </div>
  </div>
</template>

<script>
import useInformes from '../../composables/informes/index'
import { ref, toRaw } from 'vue'

export default {
  setup() {
    const {
      loading,
      messages,
      informeData,
      informeHeader,
      proveedores,
      informeRecepcionFSC,
      informeRecepcionProveedor,
      informeRecepcionProvXLS,
      informeRecepcionLampMruma,
      informeRecepcionTrozos,
      getProveedores
    } = useInformes()

    const fdesde = ref(new Date())
    const fhasta = ref(new Date())
    const tipoInforme = ref("")
    const proveedor = ref(null)

    const informesRecepcion = ref([
      { label: 'PROVEEDOR', value: 'proveedor' },
      { label: 'PROVEEDOR XLS', value: 'proveedorxls' },
      { label: 'LAMPAZO Y MRUMA', value: 'lmruma' },
      { label: 'TROZOS', value: 'trozos' },
      { label: 'FSC', value: 'fsc' },
    ])

    const filteredTipos = ref()
    const filteredProveedores = ref()

    const genInforme = () => {
      informeData.value = []
      messages.value = []

      if(toRaw(tipoInforme.value).value === 'fsc'){
        informeRecepcionFSC(fdesde.value, fhasta.value)
      }

      if(toRaw(tipoInforme.value).value === 'proveedor'){
        informeRecepcionProveedor(fdesde.value, fhasta.value, proveedor.value)
      }

      if(toRaw(tipoInforme.value).value === 'lmruma'){
        informeRecepcionLampMruma(fdesde.value, fhasta.value)
      }

      if(toRaw(tipoInforme.value).value === 'trozos'){
        informeRecepcionTrozos(fdesde.value, fhasta.value)
      }
    }

    const searchTipo = (event) => {
      messages.value = []
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipos.value = [...informesRecepcion.value]
        }
        else {
          filteredTipos.value = informesRecepcion.value.filter((t) => {
            return t.label.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProveedor = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProveedores.value = [...proveedores.value]
        }
        else {
          filteredProveedores.value = proveedores.value.filter((proveedor) => {
            return proveedor._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const exportCSV = (event) => {
      messages.value = []
      informeRecepcionProvXLS(fdesde.value, fhasta.value, proveedor.value)
    }

    return {
      loading,
      messages,
      fdesde,
      fhasta,
      informeData,
      informeHeader,
      tipoInforme,
      informesRecepcion,
      filteredTipos,
      proveedor,
      filteredProveedores,
      searchProveedor,
      getProveedores,
      genInforme,
      searchTipo,
      exportCSV
    }
  },
}
</script>
