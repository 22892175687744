<template>
  <Toast/>
  <div class="card-header">
    <div class="row">
      <h4 class="col-md-4">Clasificación de Gastos</h4>
      <div class="col-md-2 mt-2" style="text-align:right"><strong>Tipo Clasificación</strong></div>
      <div class="col-md-2 table-header flex flex-column md:flex-row">
        <span class="p-input-icon-right">
          <SelectButton v-model="clasifTipo" :options="tipoClasif" optionLabel="name" dataKey="name" @click="setClasif"/>
        </span>
      </div>
      <div class="col-md-1 mt-2" style="text-align:right"><label>Período</label></div>
      <div class="col-md-2" style="text-align:right">
        <!-- filtro calendario -->
        <Calendar
          id="periodo"
          aria-describedby="periodo-error"
          v-model="periodoSelected"
          inputClass="bg-input"
          autocomplete="off"
          view="month"
          dateFormat="mm/yy"
          :showIcon="true"
          @date-select="selectPeriodo($event, 0)"
        />
      </div>
      <div class="col-md-1 field" style="text-align: right">
        <download-excel class="btn btn-success" :data="informeCompras.data" :fields = "informeCompras.header" type="xlsx" name="informeCompras.xlsx">Descargar
        </download-excel>
      </div>
    </div>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>
      <DataTable v-model:expandedRows="expandedRows" ref="dt" :value="comprasSII" 
        :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort
        :globalFilterFields="['nombre','exento','iva_no_recupera','iva_recupera','neto','total']" sortMode="multiple" dataKey="gasto_id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        responsiveLayout="scroll" breakpoint="990px">        
        <Column expander/>
        <Column field="nombre" :header="'Gastos ' + clasifTipo.name" style="width:20%; font-weight: bold;" sortable></Column>
        <Column field="neto" header="NETO" style="width:15%;text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.neto) }}
          </template>
        </Column>                
        <Column field="exento" header="EXENTO" style="width:10%;text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.exento) }}
          </template>        
        </Column>
        <Column field="iva_recupera" header="IVA" style="width:15%;text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.iva_recupera) }}
          </template>
        </Column>      
        <Column field="total" header="TOTAL" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.total) }}
          </template>
        </Column>          
        <Column field="iva_no_recupera" header="IVA NO" style="width:10%;text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.iva_no_recupera) }}
          </template>
        </Column>                                                                                       
        <Column field="iva_uso_comun" header="IVA COMÚN" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.iva_uso_comun) }}
          </template>
        </Column>   

        <Column field="iva_no_retenido" header="INA NO.RET" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.iva_no_retenido) }}
          </template>
        </Column>  

        <Column field="impuesto_sin_credito" header="IMP S/C" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.impuesto_sin_credito) }}
          </template>
        </Column>  

        <Column field="cod_otro_impuesto" header="OTRO IMP. COD" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ slotProps.data.cod_otro_impuesto }}
          </template>
        </Column>  

        <Column field="tasa_otro_impuesto" header="OTRO IMP. TASA" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ slotProps.data.tasa_otro_impuesto }}
          </template>
        </Column>  

        <Column field="valor_otro_impuesto" header="OTRO IMP. MNT" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.valor_otro_impuesto) }}
          </template>
        </Column>  

        <Column field="iva_activo_fijo" header="IVA AF" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.iva_activo_fijo) }}
          </template>
        </Column>  

        <Column field="neto_activo_fijo" header="IVA NETO" style="text-align:right; font-weight: bold;">
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.neto_activo_fijo) }}
          </template>
        </Column>  

        <ColumnGroup type="footer">
          <Row>
            <Column footer="Total Período:" :colspan="2" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoNeto" footerStyle="background-color: LemonChiffon;"/>                    
            <Column :footer="periodoExento" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoIva" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoTotal" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoIvano" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoIvaComun" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoIvaNoRet" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoImpSC" footerStyle="background-color: LemonChiffon;"/>
            <Column footerStyle="background-color: LemonChiffon;"/>
            <Column footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoOtroImp" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoIvaAF" footerStyle="background-color: LemonChiffon;"/>
            <Column :footer="periodoNetoAF" footerStyle="background-color: LemonChiffon;"/>
          </Row>
        </ColumnGroup>
        <!-- DETALLES POR TIPO DE GASTO -->
        <template #expansion="slotProps">
          <DataTable :value="slotProps.data.detalles" 
            :paginator="true" :rows="10" style="zoom: 0.90;" :loading="loading" removableSort
            :globalFilterFields="['tipo_doc','fecha_docto','folio','rut_proveedor','razon_social','monto_neto','monto_exento','monto_iva_recupera','monto_total','monto_iva_no_recupera','iva_uso_comun','iva_no_retenido','impuesto_sin_credito','cod_otro_impuesto','tasa_otro_impuesto','valor_otro_impuesto', 'iva_activo_fijo','monto_neto_activo_fijo']" sortMode="multiple" dataKey="id" filterDisplay="row" 
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="dfilters"
            responsiveLayout="scroll" breakpoint="990px" tableStyle="padding-left:0px !important;padding-right:0px !important;">            
            <Column header="" :exportable="false" style="white-space: nowrap;text-align:center" >
              <template #body="slotProps">
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info"
                  @click="getPDFSII(slotProps.data)" title="Ver" />&nbsp;
                <Button :icon="slotProps.data.gasto_id < 0 ? 'pi pi-bookmark-fill' : 'pi pi-eye'" v-if="slotProps.data.tclasif !== 'P'" 
                :severity="slotProps.data.gasto_id < 0 ? 'secondary' : 'success'" class="p-button-rounded"  @click="setClasifGasto(slotProps.data)" title="Clasificar Gasto"/>
              </template>            
            </Column>

            <Column field="tipo_gasto" header="Tipo" :hidden="true">
              <template #body="slotProps">
                {{ slotProps.data.tipo_gasto }}
              </template>            
            </Column>

            <Column field="tipo_doc" header="Doc" style="width:2%;" sortable>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.tipo_doc }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>

            <Column field="fecha_docto" header="F.Docto" style="width:8%;" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatFecha(slotProps.data.fecha_docto) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>

            <Column field="folio" header="Folio" sortable>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.folio }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>

            <Column field="rut_proveedor" header="RUT" style="width:9%;" sortable>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.rut_proveedor }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>

            <Column field="razon_social" header="Proveedor" sortable>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.razon_social }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>
        
            <Column field="monto_neto" header="NETO" style="text-align:right" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_neto) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>  

            <Column field="monto_exento" header="EXENTO" style="text-align:right" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_exento) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>                 

            <Column field="monto_iva_recupera" header="IVA" style="text-align:right" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_iva_recupera) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>               

            <Column field="monto_total" header="TOTAL" style="text-align:right" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_total) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>                 
            
            <Column field="monto_iva_no_recupera" header="IVA NO MNT" style="white-space: wrap;text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_iva_no_recupera) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>             
            
            <Column field="iva_uso_comun" header="IVA COMÚN" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.iva_uso_comun) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>             
            
            <Column field="iva_no_retenido" header="IVA NO.RET" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.iva_no_retenido) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>                      
            
            <Column field="impuesto_sin_credito" header="IMP. S/C" style="text-align:right" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.impuesto_sin_credito) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column> 

            <Column field="cod_otro_impuesto" header="OTRO IMP. COD" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.cod_otro_impuesto }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>       
            
            <Column field="tasa_otro_impuesto" header="OTRO IMP. TASA" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.tasa_otro_impuesto }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>             
            
            <Column field="valor_otro_impuesto" header="OTRO IMP. MNT" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.valor_otro_impuesto) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column> 

            <Column field="iva_activo_fijo" header="IVA AF" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.iva_activo_fijo) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>    

            <Column field="monto_neto_activo_fijo" header="NETO AF" style="text-align:center" sortable>
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_neto_activo_fijo) }}
                </span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
              </template>              
            </Column>   
          </DataTable>
        </template>
      </DataTable>
    </div>
    <div>
      <Dialog v-model:visible="tgastoDialog" maximizable modal header="Distribución del Gasto" position="top">
        <div class="col-md-12 field row">
          <div class="col-md-3">
            Proveedor: {{ form.gasto.rut_proveedor +' '+ form.gasto.razon_social }} <br> 
            Folio: {{ form.gasto.tipo_doc +' '+ form.gasto.folio }}
          </div>
          <div class="col-md-2 mt-4" style="text-align:right">Monto Neto: {{ formatCurrency(compra.monto_neto) }}</div>
          <div class="col-md-2 mt-4" style="text-align:right">Monto Exento: {{ formatCurrency(compra.monto_exento) }}</div>
          <div class="col-md-2 mt-4" style="text-align:right">Monto Iva: {{ formatCurrency(compra.monto_iva_recupera) }}</div>
          <div class="col-md-3 mt-4" style="text-align:right">Monto Total: {{ formatCurrency(compra.monto_total) }}</div>
        </div>
        <div class="col-md-12 d-flex justify-content-start mt-4" v-if="compra.monto_iva_no_recupera || compra.monto_neto_activo_fijo || compra.iva_uso_comun || compra.impuesto_sin_credito || compra.iva_no_retenido || compra.valor_otro_impuesto">
          <strong>Otros Valores</strong>
        </div>
        <div class="col-md-12 d-flex justify-content-start mt-4">
          <div class="col-md-2 mt-2" style="text-align:right" v-if="compra.monto_iva_no_recupera">Iva No Recupera({{compra.codigo_iva_no_recupera}}): {{ formatCurrency(compra.monto_iva_no_recupera) }}</div>
          <div class="col-md-2 mt-2" style="text-align:right" v-if="compra.monto_neto_activo_fijo">Iva Activo Fijo({{compra.iva_activo_fijo}}): {{ formatCurrency(compra.monto_neto_activo_fijo) }}</div>
          <div class="col-md-2 mt-2" style="text-align:right" v-if="compra.iva_uso_comun">Iva Común: {{ formatCurrency(compra.iva_uso_comun) }}</div>
          <div class="col-md-2 mt-2" style="text-align:right" v-if="compra.impuesto_sin_credito">Imp.Sin Crédito: {{ formatCurrency(compra.impuesto_sin_credito) }}</div>
          <div class="col-md-2 mt-2" style="text-align:right" v-if="compra.iva_no_retenido">Iva N.Reten: {{ formatCurrency(compra.iva_no_retenido) }}</div>
          <div class="col-md-2 mt-2" style="text-align:right" v-if="compra.valor_otro_impuesto">Otro Imp. Neto({{compra.cod_otro_impuesto}}): {{ formatCurrency(compra.tasa_otro_impuesto) }} % {{ formatCurrency(compra.valor_otro_impuesto) }}</div>
        </div>          
        <div class="col-md-12 d-flex justify-content-end" style="text-align:right">
          <Button icon="pi pi-plus" class="p-button-rounded p-button" size="small" @click="addLinea()"/>
        </div>
        <div class="col-md-12 field">
          <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
        </div>
        <div class="card p-fluid table_modal">
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px"
                    editMode="cell" @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table"
                    tableStyle="min-width: 50%">
            <Column style="width: 1%;max-width: 1%; text-align: center">
              <template #body="{ data, index }">{{index}} {{data['id']}}
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" size="small"
                        @click="toggleDelete(index)" v-if="data['id'] === null"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDelete(index, data['id'])" v-else/>
              </template>
            </Column>
            <Column field="compra_id" header="ID" style="max-width: 14%" :hidden="true">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>

            <Column field="gasto_id" header="GastoID" :hidden="true">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>

            <Column field="tipo_gasto" header="Tipo" style="width: 20%">
              <template #editor="{ data, field }">
                <AutoComplete id="tipo_gasto" name="tipo_gasto" v-model="data[field]" :optionValue="data[field]" :suggestions="filteredGastos" @complete="searchGasto($event)" field="_nombre" dropdown style="width: 100%"/>
              </template>
            </Column>

            <Column field="monto_neto" header="Monto Neto" style="max-width: 15%;text-align:right">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" style=" width: 100%"/>
              </template>
            </Column>

            <Column field="porc_iva" header="% IVA" style="max-width: 15%;text-align:right">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" style=" width: 10%"/>
              </template>
            </Column>

            <Column field="monto_iva" header="Monto IVA" style="max-width: 15%;text-align:right">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_iva) }}
                </span>
              </template>
            </Column>

            <Column field="monto_total" header="Monto Total" style="max-width: 15%;text-align:right">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.monto_total) }}
                </span>
              </template>
            </Column>

            <Column field="observacion" header="Observación" style="max-width: 20%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]" style="width: 100%"/>
              </template>
            </Column>

            <ColumnGroup type="footer">
              <Row>
                <Column footer="" />
                <Column footer="TOTALES" footerStyle="text-align:right"/>
                <Column :footer="formatCurrency(totalNeto)" footerStyle="text-align:right"/>
                <Column footer="" />
                <Column :footer="formatCurrency(totalIVA)" footerStyle="text-align:right"/>
                <Column :footer="formatCurrency(totalTotal)" footerStyle="text-align:right"/>
                <Column footer="" />
              </Row>
            </ColumnGroup>

          </DataTable>
        </div>
        <div v-if="!saving" class="w-full md:w-20rem" style="text-align: right;">
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
          <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveTipoGasto" />
          <!-- v-if="form.gasto.gasto_id < 0" -->
        </div>
      </Dialog>
      <!-- Confirmar elimina un gasto calsificado ya almacenado -->
      <Dialog v-model:visible="deleteDialog.visible" :style="{ width: '470px' }" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
          <span> ¿Está seguro de borrar la clasificación?{{index}} {{id}}</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog.visible = false" />
          <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteClasif(index,id)" />
        </template>
      </Dialog>         
    </div>
  </div>
</template>

<script>
import useComprasClasif from "../../composables/gastos/cclasif";
import {ref, onMounted, computed, reactive, toRaw} from "vue";
import moment from "moment";

export default {
  setup() {
    const {
      comprasSII,
      rcompras,
      informeCompras,
      expandedRows,
      dt,
      loading,
      saving,
      filters,
      dfilters,
      filteredGastos,
      gastos,
      clasifTipo,
      tipoClasif,
      tgastoDialog,
      submitted,
      lineas,
      messages,
      storeTipoGastos,
      getCompraSII,
      getPDFSII,
      getCompra,
      compra,
      deleteDialog,
      deleteClasif
    } = useComprasClasif()

    moment.locale('es')

    const periodoSelected = ref(new Date())
    const periodo = ref({})
    let _periodo

    const form = reactive({
      compra_id: null,
      gasto_id: null,
      tipo_gastos: []
    })

    const formatFecha = (value) => {
      return moment(value).format("DD-MM-YY")
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL').format(value)
    }

    const periodoExento = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_exento;
      }

      return formatCurrency(total);
    })

    const periodoIva = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_iva_recupera;
      }
      return formatCurrency(total);
    })

    const periodoIvano = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_iva_no_recupera;
      }
      return formatCurrency(total);
    })

    const periodoNeto = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_neto;
      }

      return formatCurrency(total);
    })

    const totalNeto = computed(() => {
      let total = 0;
      for (let gasto of lineas.value) {
        total += gasto.monto_neto;
      }
      return total
    })

    const totalIVA = computed(() => {
      let total = 0;
      for (let gasto of lineas.value) {
        total += gasto.monto_iva;
      }
      return total
    })

    const totalTotal = computed(() => {
      let total = 0;
      for (let gasto of lineas.value) {
        total += gasto.monto_total;
      }
      return total

    })

    const periodoTotal = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_total;
      }

      return formatCurrency(total);
    })

    const periodoIvaComun = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_iva_uso_comun;
      }

      return formatCurrency(total);
    })

    const periodoIvaNoRet = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_iva_no_retenido;
      }

      return formatCurrency(total);
    })

    const periodoImpSC = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_impuesto_sin_credito;
      }

      return formatCurrency(total);
    })

    const periodoOtroImp = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_valor_otro_impuesto;
      }

      return formatCurrency(total);
    })

    const periodoIvaAF = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_iva_activo_fijo;
      }

      return formatCurrency(total);
    })

    const periodoNetoAF = computed(() => {
      let total = 0;
      for (let gasto of rcompras.value) {
        total += gasto.gasto_neto_activo_fijo;
      }

      return formatCurrency(total);
    })

    const selectPeriodo = (e, f5) => {
      let initDate = e === undefined ? new Date() : e
      let fecha = f5 === 1 ? periodoSelected.value : new Date()

      periodoSelected.value = (initDate instanceof Date) ? initDate : fecha
      periodo.value.val = periodoSelected.value.getFullYear() + ("0" + (periodoSelected.value.getMonth()).toString()).slice(-2)

      periodo.value.label = [
        periodo.value.val.toString().slice(-2),
        "/",
        periodo.value.val.toString().slice(0, 4),
      ].join("");

      _periodo = ("0" + (periodoSelected.value.getMonth() + 1).toString()).slice(-2) + '-' + periodoSelected.value.getFullYear()

      getCompraSII(_periodo, f5)
    }

    const exportCSV = () => {
      informeCompras.value.exportCSV();
    }

    const setClasif = () => {
      selectPeriodo(periodoSelected.value, 1)
    }

    const hideDialog = () => {
      tgastoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const setClasifGasto = (gasto) => {
      console.info('gasto: ', gasto)
      form.gasto = gasto
      form.compra_id = gasto.id
      form.tipo_gastos = []
      lineas.value = []
      messages.value = []

      let detalles = toRaw(gasto.compras_clasif)
      lineas.value = detalles.reduce((acc, ln) => {
        console.info('ln:', ln)
        acc.push({
          'id': ln.id,
          'compra_id': ln.compra_id,
          'gasto_id': ln.gasto_id,
          'tipo_gasto': toRaw(gastos.value.filter(function (itm) { return [ln.gasto_id].indexOf(itm.id) > -1; }))[0]._nombre,
          'monto_neto': ln.monto_neto,
          'porc_iva': ln.porc_iva,
          'monto_iva': ln.monto_iva,
          'monto_total': ln.monto_total,
          'observacion': ln.observacion
        })

        return acc;
      }, [])
      //Busca la compra original
      getCompra(gasto.id)
      console.info(lineas.value)
      form.tipo_gastos = lineas.value
      tgastoDialog.value = true
    }

    const searchGasto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredGastos.value = [...gastos.value]
        }
        else {
          filteredGastos.value = gastos.value.filter((gasto) => {
            return gasto._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const saveTipoGasto = () => {
      messages.value = []
      if (lineas.value.length > 0) {
        //valida que este el tipo de gasto
        lineas.value.map((ln) => {
          if (ln.tipo_gasto === null && ln.tipo_gasto === undefined)
            messages.value = [{
              content: 'Debe seleccionar los datos del tipo de gasto antes de guardar',
              id: lineas.value.length
            }]
        })
        // valida que las sumatorias de monto_neto, monto_iva y monto_total coincidan con el documento emitido

        if(totalNeto.value !== form.gasto.monto_neto)
          messages.value = [{
            content: 'La sumatorio de los Montos Netos no coincide con el documento ' + ' '+ ' ' + totalNeto.value  + ' vs ' + form.gasto.monto_neto,
            id: lineas.value.length
          }]


      } else
        messages.value = [{content: 'Debe clasificar el gasto antes de guardar', id: lineas.value.length}]

      if (messages.value.length === 0) {
        form.tipo_gastos = lineas
        storeTipoGastos(form, _periodo)
      }
    }

    const onCellEditComplete = (event) => {
      let {data, newValue, field} = event

      data[field] = newValue
      messages.value = []

      if (field.includes('tipo_gasto')){
        data[field] = newValue._nombre
        data['gasto_id'] = newValue.id
      }

      if (field.includes('monto_neto') || field.includes('porc_iva')) {
        data['monto_neto'] = parseFloat(Math.round(parseFloat(data['monto_neto'])).toFixed(0))//(Number(data['monto_neto']))
        data['monto_iva'] = data['monto_neto'] === form.gasto.monto_neto ? Number(form.gasto.monto_iva_recupera) : calculaIva(data['monto_neto'], data['porc_iva'])
        data['monto_total'] = data['monto_neto'] === form.gasto.monto_neto ? Number(form.gasto.monto_total) : parseInt(parseFloat(Math.round((Number(data['monto_neto']) + Number(data['monto_iva']))).toFixed(0)))
        // console.info('1: ', data['monto_neto'],data['monto_iva'],data['monto_total'])
        // console.info('2: ', form.gasto.monto_neto,form.gasto.monto_iva_recupera,form.gasto.monto_total)
        // console.info('3: ', totalNeto._value,totalIVA._value,totalTotal._value)
      }
    }

    const calculaIva = (neto,porc_iva) => {
      let _iva = parseInt(neto) * (Number(porc_iva)/100)   
      return parseFloat(_iva%1).toFixed(2) >= 0.55 ? Math.round(_iva) : Math.floor(_iva)
    }

    const addLinea = () => {
      let row = {
        'id': null,
        'compra_id': form.compra_id,
        'gasto_id': null,
        'tipo_gasto': '',
        'monto_neto': 0,
        'porc_iva': 19,
        'monto_iva': 0,
        'monto_total': 0,
        'observacion': ''
      }

      if (lineas.value.length === 0)
        lineas.value.push(row)
      else if (lineas.value[lineas.value.length - 1].tipo_gasto !== '')
        lineas.value.push(row)
      else
        messages.value = [{
          content: 'Debe seleccionar el tipo de gasto antes de agregar nueva línea',
          id: lineas.value.length
        }]
    }

    const toggleDelete = (index) => {
      let row = {
        'id': null,
        'compra_id': null,
        'gasto_id': null,
        'tipo_gasto': '',
        'monto_neto': 0,
        'porc_iva': 19,
        'monto_iva': 0,
        'monto_total': 0,
        'observacion': ''
      }

      lineas.value[index] = row
      lineas.value.splice(index, 1)
      if (lineas.value.length === 0) lineas.value.pop()
      messages.value = []
    }

    const confirmDelete = (index, id) => {
      deleteDialog.value.visible = true
      deleteDialog.value.index = index
      deleteDialog.value.id = id
    }

    onMounted(selectPeriodo)

    return {
      dt,
      loading,
      saving,
      comprasSII,
      filters,
      dfilters,
      periodo,
      periodoSelected,
      expandedRows,
      periodoExento,
      periodoIva,
      periodoIvano,
      periodoTotal,
      periodoNeto,
      periodoIvaComun,
      periodoIvaNoRet,
      periodoImpSC,
      periodoOtroImp,
      periodoIvaAF,
      periodoNetoAF,
      informeCompras,
      clasifTipo,
      tipoClasif,
      tgastoDialog,
      form,
      filteredGastos,
      gastos,
      messages,
      lineas,
      totalNeto,
      totalIVA,
      totalTotal,
      addLinea,
      onCellEditComplete,
      saveTipoGasto,
      searchGasto,
      hideDialog,
      setClasif,
      setClasifGasto,
      exportCSV,
      formatFecha,
      formatCurrency,
      selectPeriodo,
      getPDFSII,
      calculaIva,
      compra,
      toggleDelete,
      confirmDelete,
      deleteDialog,
      deleteClasif
    };
  },
};
</script>
