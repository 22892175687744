require('./bootstrap');

import { createApp } from 'vue';
import { PDFPlugin } from 'vue3-pdfmake';
import JsonExcel from 'vue-json-excel3';
import router from './router';

// Components Prime VUe
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Password from 'primevue/password';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import ToggleButton from 'primevue/togglebutton';
import FileUpload from 'primevue/fileupload';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import OverlayPanel from 'primevue/overlaypanel';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Tag from 'primevue/tag';
import Message from 'primevue/message';
// import Chart from 'primevue/chart';
import Tooltip from 'primevue/tooltip';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Divider from 'primevue/divider';
import InlineMessage from 'primevue/inlinemessage';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import InputMask from 'primevue/inputmask';
import MultiSelect from 'primevue/multiselect';
import CanvasJSChart from '@canvasjs/vue-charts';

import App from './App.vue'
const app = createApp(App)

app.use(PDFPlugin)
app.use(router)
app.use(PrimeVue, {
    locale: {
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        weekHeader: 'Wk',
        firstDayOfWeek: 0,
    }
});
app.use(ToastService)
app.component('Toast', Toast)
app.component('DataTable', DataTable)
app.component('ScrollPanel', ScrollPanel)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('Row', Row)
app.component('Button', Button)
app.component('Toolbar', Toolbar)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('Password', Password)
app.component('Dialog', Dialog)
app.component('Dropdown', Dropdown)
app.component('AutoComplete', AutoComplete)
app.component('ToggleButton', ToggleButton)
app.component('FileUpload', FileUpload)
app.component('Textarea', Textarea)
app.component('Calendar', Calendar)
app.component('OverlayPanel', OverlayPanel)
app.component('ProgressBar', ProgressBar)
app.component('ProgressSpinner', ProgressSpinner)
app.component('RadioButton', RadioButton)
app.component('Checkbox', Checkbox)
app.component('Badge', Badge)
app.directive('badge', BadgeDirective)
app.component('Tag', Tag);
app.component('Message', Message);
// app.component('Chart', Chart);
app.component('Tooltip', Tooltip);
app.component('SelectButton', SelectButton);
app.component('Divider', Divider);
app.component('InlineMessage', InlineMessage);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('InputMask', InputMask);
app.component("downloadExcel", JsonExcel);
app.component("MultiSelect", MultiSelect);
app.use(CanvasJSChart); 

app.mount('#app');
