import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import useClient from '../client/apiClient'

export default function useRpaVentas() {
  const ventasSII = ref([])

  const router = useRouter()
  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const getVentaSII = async (periodo,reload) => {
    loading.value = true
    let f5 = reload ?? 0
    client.get(`/api/sii/${periodo}/${f5}/venta`).then(response => {
      ventasSII.value = response.data.data.ventas
      loading.value = false
    })
  }

  const getPDFSII = async (d) => {
    // console.info('***********', d)
    loading.value = true
    // d.folio = 107
    d.rut_proveedor = '76492850-4'
    // d.codigo_sii = 33
    if(d.folio !== undefined)
      axios.get(`/api/sii/${d.codigo_sii}/${d.folio}/E/${d.rut_proveedor}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }
  
  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'tipo_doc': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'tipo_venta': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'fecha_docto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha_recepcion': { value: null, matchMode: FilterMatchMode.EQUALS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'rut_cliente': { value: null, matchMode: FilterMatchMode.EQUALS },
    'razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'monto_exento': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_total': { value: null, matchMode: FilterMatchMode.EQUALS }
  })

  return {
    router,
    ventasSII,
    dt,
    loading,
    saving,
    filters,
    getVentaSII,
    getPDFSII
  }
}