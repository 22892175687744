<template>
  <Toast />
  <div class="card-header">
    <h4>Listado de Productos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Usuarios -->
      <DataTable ref="dt" :value="productos" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort
        :globalFilterFields="['id','nombre','tipoMadera.nombre', 'pulgada', 'metro3', 'unidad', 'mm', 'mruma', 'nulo', 'vigente']" sortMode="multiple" dataKey="id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        v-model:selection="selectedProductos" responsiveLayout="scroll" breakpoint="990px">

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>
            <template #empty> Sin rergistros. </template>
            <template #loading> Cargando registros. </template>
            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
            </template>
          </Toolbar>
        </template>

        <Column field="id" header="Cod." sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.id }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="nombre" header="Producto" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>            
        </Column>        

        <Column field="tipoMadera._nombre" header="TipoMadera" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.tipoMadera._nombre ? slotProps.data.tipoMadera._nombre.toUpperCase() : '' }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>            
        </Column>

        <Column field="pulgada" header="Pulgadas" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi pi-center" :class="{ 'pi-check-circle text-success': slotProps.data.pulgada, 'pi-times-circle text-danger': !slotProps.data.pulgada}"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>                        
        </Column>

        <Column field="metro3" header="Metros 3" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi pi-center" :class="{ 'pi-check-circle text-success': slotProps.data.metro3, 'pi-times-circle text-danger': !slotProps.data.metro3 }"></i>
            </span>
          </template> 
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="unidad" header="Unidad" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi pi-center" :class="{ 'pi-check-circle text-success': slotProps.data.unidad, 'pi-times-circle text-danger': !slotProps.data.unidad }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>
        <Column field="mm" header="Milimetros" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi pi-center" :class="{ 'pi-check-circle text-success': slotProps.data.mm, 'pi-times-circle text-danger': !slotProps.data.mm }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="mruma" header="Metros Ruma" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi pi-center" :class="{ 'pi-check-circle text-success': slotProps.data.mruma, 'pi-times-circle text-danger': !slotProps.data.mruma }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>      

        <Column field="nulo" header="Nulo" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi pi-center" :class="{ 'pi-check-circle text-success': slotProps.data.nulo, 'pi-times-circle text-danger': !slotProps.data.nulo }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>   

        <Column field="vigente" header="Estado" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>             
        </Column>
        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editProducto(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteProducto(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario Productos en modal -->
    <Dialog v-model:visible="productoDialog" :style="{ width: '50%' }" header="Datos del Producto" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-6 row">
            <label for="nombre" class="mt-3">Nombre: </label>
            <InputNumber id="id" v-model="form.id" readonly disabled class="col-md-2"/>
            <InputText id="nombre" v-model="v$.nombre.$model" class="col-md-10" :class="{ 'p-invalid': v$.nombre.$invalid && submitted }"
              aria-describedby="nombre-error" autofocus autocomplete="off" />
            <span v-if="v$.nombre.$error && submitted">
              <span id="nombre-error" v-for="(error, index) of v$.nombre.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              {{ v$.nombre.required.$message.replace('Value', 'Razón Social') }}</small>
          </div>
          <div class="col-md-1 field"></div>
          <div class="col-md-5 field">
            <label for="tipo_madera" class="mt-3">Tipo Madera (<small class="text-danger fw-normal">&nbsp;Seleccione, sólo para usar en recepciones </small>)</label>
            <AutoComplete id="tipoMadera" v-model.trim ="form.tipoMadera" :suggestions="filteredTipoMaderas"
              @complete="searchTipoMadera($event)" field="_nombre" :dropdown="true" 
              autocomplete="off"/>
          </div>                                       
        </div>

        <div class="row">
          <div class="col-md-12 mt-3 field">
            <label for="umedida">Unidad Medida Producto</label>
            <div class="card flex flex-row justify-content-left gap-3">
              <div v-for="umedida of tipoUmedida" :key="umedida.key" class="flex align-items-center">
                <Checkbox v-model="selectedUmedidaProductos" :inputId="umedida.key" :value="umedida.key" class="me-2" />
                <label :for="umedida.key" class="mt-3">{{ umedida.name }}</label>
              </div>    
            </div>                         
          </div> 
        </div>        

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <InputText id="observacion" v-model.trim="form.observacion" />
          </div>          
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProducto" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un producto -->
    <Dialog v-model:visible="deleteProductoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="producto"> ¿Está seguro de borrar el producto?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteProducto" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente productos -->
    <Dialog v-model:visible="deleteProductosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedProductos"> ¿Está seguro de borrar los productos seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedProductos" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useProductos from '../../composables/cruds/productos'
import { reactive, onMounted } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers} from '@vuelidate/validators'

export default {
  setup() {
    const {
      producto,
      productos,
      tipoMaderas,
      dt, 
      loading, 
      saving,
      filters,
      selectedProductos,
      deleteProductosDialog,
      deleteProductoDialog,
      filteredTipoMaderas,
      productoDialog,
      submitted,
      storeProducto,      
      deleteSelectedProductos,
      getCRUD,
      deleteProducto,
      tipoUmedida,
      selectedUmedidaProductos
    } = useProductos()

    const form = reactive({
      id: null,
      nombre: '',
      tipoMadera: '',
      pulgada: '',
      metro3: '',
      unidad: '',
      mm: '',
      mruma: '',
      nulo: '',
      observacion: '',
      vigente: ''
    })
    
    const rules = {
      nombre: { required: helpers.withMessage('El campo es requerido', required), },
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteProductosDialog.value = true
    }

    const confirmDeleteProducto = (prod) => {
      producto.value = prod
      deleteProductoDialog.value = true
    }

    const openNew = () => {
      producto.value = {}
      submitted.value = false
      productoDialog.value = true
      saving.value = false

      form.id = null
      form.nombre = ''
      form.tipoMadera = null
      form.pulgada = ''      
      form.metro3 = ''
      form.unidad = ''
      form.mm = ''
      form.mruma = ''
      form.nulo = ''
      form.observacion = ''
      form.vigente = true

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const hideDialog = () => {
      productoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const editProducto = (prod) => {
      producto.value = { ...prod }
      productoDialog.value = true
      
      if(producto.value.pulgada === 1) selectedUmedidaProductos.value.push("P")
      if(producto.value.metro3 === 1) selectedUmedidaProductos.value.push("M")
      if(producto.value.unidad === 1) selectedUmedidaProductos.value.push("U")
      if(producto.value.mm === 1) selectedUmedidaProductos.value.push("N")
      if(producto.value.mruma === 1) selectedUmedidaProductos.value.push("R")
      if(producto.value.nulo === 1) selectedUmedidaProductos.value.push("X")

      form.id = producto.value.id
      form.nombre = producto.value.nombre
      form.tipoMadera = producto.value.tipoMadera
      form.umedida = selectedUmedidaProductos
      form.observacion = producto.value.observacion
      form.vigente = producto.value.vigente
      
      form.created_at = producto.value.created_at
      form.created_id = producto.value.created_id
      form.updated_at = producto.value.updated_at
      form.updated_id = producto.value.updated_id
    }

    const searchTipoMadera = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoMaderas.value = [...tipoMaderas.value]
        }
        else {
          filteredTipoMaderas.value = tipoMaderas.value.filter((tmadera) => {
            return tmadera._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const saveProducto = () => {
      submitted.value = true
      // console.info('>>>',selectedUmedidaProductos.value.length)
        if(!v$._value.$invalid)
          if(selectedUmedidaProductos.value.length > 0){
            form.umedida = selectedUmedidaProductos
            storeProducto(form)  
          }else 
            console.info('Seleccione al menos una unidad de medida')    
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      producto,
      productos,
      tipoMaderas,
      filters,
      filteredTipoMaderas,
      selectedProductos,
      deleteProductosDialog,
      deleteProductoDialog,
      productoDialog,
      submitted,
      v$,
      form,
      hideDialog,
      openNew,
      confirmDeleteProducto,
      deleteSelectedProductos,
      deleteProducto,
      exportCSV,
      confirmDeleteSelected,
      editProducto,
      saveProducto,
      storeProducto,
      tipoUmedida,
      selectedUmedidaProductos,
      searchTipoMadera
    }
  },
}
</script>