<template>
  <Toast/>
  <div class="card-header row">
    <div class="col-md-3">
      <h4>Recepciones - Estado de Pagos</h4>
      <div class="line_title" style="width: 20%;"></div>
    </div>
    <div class="col-md-2">
    </div>
    <div class="col-md-2">
      <label for="fdesde">Desde:&nbsp;</label>
      <Calendar
        id="fdesde"
        aria-describedby="fdesde-error"
        v-model="fdesde"
        inputClass="bg-input"
        autocomplete="off"
        dateFormat="dd-mm-yy"
        :showIcon="true"
      />&nbsp;
    </div>
    <div class="col-md-2">
      <label for="fhasta">Hasta:&nbsp;</label>
      <Calendar
        id="fhasta"
        aria-describedby="fhasta-error"
        v-model="fhasta"
        inputClass="bg-input"
        autocomplete="off"
        dateFormat="dd-mm-yy"
        :showIcon="true"
      />&nbsp;
    </div>
    <div style="text-align: left" class="col-md-1"><br>
      <Button icon="pi pi-search" class="p-button-primary" @click="getProveedoresPeriodo" outlined
              :disabled="(fdesde === null || fhasta === null)"/>
    </div>
    <div style="text-align: right" class="col-md-2"><br>
      <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" class="me-4"/>
      <!-- <Button icon="pi pi-print" class="p-button-rounded p-button" size="small" @click="informePagos()"/> -->
    </div>
    <div class="col-md-12 mt-2">
      <InlineMessage v-for="msg of messages" :key="msg.id" severity="error" class="mb-2" style="width: 100%;">
        {{ msg.content }}
      </InlineMessage>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-3">
        <!-- listado de Proveedores -->
        <DataTable ref="dtp" :value="proveedores" :paginator="true" :rows="20" style="zoom: 0.8" :loading="loading" 
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" :filters="filtersProv" @rowSelect="buscarRecepciones($event,1)" @rowUnselect="buscarRecepciones($event,0)" tableClass="editable-cells-table" 
          responsiveLayout="scroll" breakpoint="990px" selectionMode="single">
          <template #header>
            <!-- DT Toolbar-->
            <Toolbar class="">
              <template #start>
                <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filtersProv['global'].value" placeholder="Buscar..." />
                  </span>
                </div>
              </template>
              <template #end>
              </template>
            </Toolbar>
          </template>          
          <Column field="_razon_social" header="Proveedor" >
            <template #body="slotProps">
              <span>
                {{ slotProps.data._razon_social }}
              </span>
            </template>
          </Column>
        </DataTable>        
      </div>
      <div class="col-md-9">
        <!-- DT Recepciones SII -->
        <DataTable
          ref="dt"
          :value="recepcionesSII"
          v-model:selection="selectedRecepciones"
          :paginator="true"
          :rows="20"
          style="zoom: 0.8"
          :loading="loading"
          @rowSelect="onRowSelect" @rowUnselect="onRowUnselect"
          sortMode="multiple" removableSort 
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
          :filters="filters"
          responsiveLayout="scroll"
          breakpoint="990px">
          <template #header>
            <!-- DT Toolbar-->
            <Toolbar class="">
              <template #start>
                <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText v-model="filters['global'].value" placeholder="Buscar..."/>
                  </span>
                  <span class="ms-4 fs-4 p-1 text-white bg-success" v-if="proveedor !== undefined && proveedor !== null">{{ proveedor._razon_social.toUpperCase() }}</span>
                </div>
              </template>
              <template #end>
                <Button label="Registrar Abonos" icon="pi pi-wallet" severity="danger" @click="setPagosMasivo" :disabled="!selectedRecepciones || !selectedRecepciones.length" />
              </template>
            </Toolbar>
          </template>
        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info"
              @click="getPDFSII(slotProps.data)" title="Ver PDF" />
          </template>
        </Column>          
          <Column field="id" header="Cod.Recep." sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.id }}
              </span>
            </template>
          </Column>

          <Column field="tipo_madera" header="Tipo" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.tipo_madera.toUpperCase() }}
              </span>
            </template>
          </Column>

          <Column field="tipo_doc_recep" header="Tipo.Doc." sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.tipo_doc_recep }}
              </span>
            </template>
          </Column>

          <Column field="folio_doc_recep" header="Doc.Recep." sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.folio_doc_recep }}
              </span>
            </template>
          </Column>

          <Column field="fecha_doc_recep" header="F.Doc.Recep." style="white-space: nowrap;" sortable>
            <template #body="slotProps">
              <span>
                {{ formatFecha(slotProps.data.fecha_doc_recep) }}
              </span>
            </template>
          </Column>

          <Column field="folio_doc_pago" header="Doc.Pago" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.pagos.length > 0 ? slotProps.data.pagos[0].folio : '' }}
              </span>
            </template>
          </Column>

          <Column field="fecha_docto" header="Ref.Pago" style="white-space: nowrap;" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.pagos.length > 0 ? slotProps.data.pagos[0].referencia : '' }}
              </span>
            </template>
          </Column>

          <Column field="neto" header="Neto" style="max-width: 14%; text-align: right" sortable>
            <template #body="slotProps">
                  <span>
                    {{ formatCurrency(slotProps.data.neto) }}
                  </span>
            </template>
          </Column>

          <Column field="iva" header="I.V.A." style="max-width: 14%; text-align: right" sortable>
            <template #body="slotProps">
                  <span>
                    {{ formatCurrency(slotProps.data.iva) }}
                  </span>
            </template>
          </Column>

          <Column field="total" header="Total" style="text-align:right" sortable>
            <template #body="slotProps">
              <span>
                {{ formatCurrency(slotProps.data.total) }}
              </span>
            </template>
          </Column>

        <Column field="estado" header="Estado" :exportable="false" style="white-space: nowrap;text-align:center" sortable>
          <template #body="slotProps">
            <Tag severity="success" v-if="slotProps.data.estado === 'PAGADA'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="warning" v-if="slotProps.data.estado === 'PENDIENTE'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="danger" v-if="slotProps.data.estado === 'VENCIDA'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag style="background-color: #f1f5f9;color: #475569;border: 1px solid #475569;" v-if="slotProps.data.estado === null" rounded>
              <h5 style="text-aling-center;">{{slotProps.data.pagos.length === 0 ? '?' : 'ABONADO'}}</h5>
            </Tag>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>
          <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
        </DataTable>
      </div>
    </div>

    <!-- Formulario Pagos Recepciones en modal -->
    <Dialog v-model:visible="pagoDialog" header="Abono de Recepciones" :modal="true" class="p-fluid"
            :maximizable="true" @hide="hideDialog"
            position="topleft">
      <form class="p-fluid">
        <div class="col-md-12 row">
          <div class="col-md-5 fw-bold mt-3" style="text-align:left;font-size: 16px;">Proveedor: {{ proveedor._razon_social.toUpperCase() }}</div>
          <div class="col-md-5 fw-bold mt-3" style="text-align:center;font-size: 16px;">Folio Recepción:  {{ form.foliosRecep }}</div>
          <div class="col-md-1 fw-bold mt-3" style="text-align:right;font-size: 16px;"><label for="folio_pago">Folio Pago:</label></div>
          <div class="col-md-1 fw-bold mt-2">            
            <AutoComplete id="folio_pago" name="folio_pago" placeholder="Buscar..." :suggestions="filteredFolios" v-model.trim="form.folio_pago"
            @complete="searchFolios($event)" optionLabel="folio" autofocus autocomplete="off"/>            
          </div>
        </div>
        <div class="col-md-12 field row">
          <div class="col-md-4 fw-bold mt-3" style="text-align:left;font-size: 16px;">{{ 'Documentos Recibidos: del ' + formatFecha(fdesde) + ' al ' + formatFecha(fhasta) }}</div>
          <div class="col-md-2 fw-bold mt-3" style="text-align:right;font-size: 16px;"></div>
          <div class="col-md-2 fw-bold mt-3" style="text-align:right;font-size: 16px;"><mark>Monto Neto: {{ formatCurrency(totalNeto) }}</mark></div>
          <div class="col-md-2 fw-bold mt-3" style="text-align:right;font-size: 16px;"><mark>Monto Iva: {{ formatCurrency(totalIVA ) }}</mark></div>
          <div class="col-md-2 fw-bold mt-3" style="text-align:right;font-size: 16px;"><mark style="background-color:#F06779!important;">Monto Total: {{ formatCurrency(totalTotal) }}</mark></div>
        </div>
        <div class="row mt-4">
          <div class="col-md-10 field">
            <Divider align="left" type="solid">
              <b class="text-muted">Pagos</b>
            </Divider>
          </div>
          <div class="col-md-2 d-flex justify-content-end">
            <Button icon="pi pi-plus" class="p-button-rounded p-button" size="small" @click="addLinea()"/>
          </div>
          <div class="col-md-12 field">
            <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
          </div>
        </div>
        <div class="card p-fluid table_modal">
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px" editMode="cell" tableStyle="min-width: 50%"
          @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table" >
            <Column style="width: 1%;max-width: 1%; text-align: center">
              <template #body="{ data, index }">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" size="small"
                        @click="toggleDelete(index)" v-if="data['id'] === null"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePago(index, data['id'])" v-else/>
              </template>
            </Column>
            <Column field="fecha_pago" header="Fecha Pago" style="width: 10%;max-width: 10%;">
              <template #editor="{ data, field }">
                <Calendar v-model="data[field]" :showIcon="false" dateFormat="dd/mm/y"/>
              </template>
            </Column>
            <Column field="modalidad" header="Modalidad" style="max-width: 14%">
              <template #editor="{ data, field, index }">
                <AutoComplete v-model="data[field]" :optionValue="data[field]" :suggestions="filteredModalidades" @complete="searchModalidad($event)" @change="selectNota($event, index)" @focus="selAllText"/>
              </template>
            </Column>            
            <Column field="referencia" header="Referencia" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>
            <Column field="monto_abono" header="Monto Abono" style="max-width: 14%; text-align: right;">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" @click="onCellEditInit"/>
              </template>
            </Column>
            <Column field="entidad" header="Banco / Caja" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>
            <Column field="observacion" header="Observación" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="TOTALES" footerStyle="text-align:right;"/>
                <Column footer="FACTURADO" footerStyle="text-align:right; background-color:#F06779;"/>
                <Column :footer="formatCurrency(totalTotal)" footerStyle="text-align:right; background-color:#F06779;"/>
                <Column footer="ABONADO" footerStyle="text-align:right; background-color:#90BD76;"/>
                <Column :footer="formatCurrency(totalAbono)" footerStyle="text-align:right; background-color:#90BD76;"/>
                <Column footer="PENDIENTE" footerStyle="text-align:right; background-color:#FFC300;"/>
                <Column :footer="formatCurrency(totalPendiente)" footerStyle="text-align:right; background-color:#FFC300;"/>
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog"/>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePago"/>
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un pago ya almacenado -->
    <Dialog v-model:visible="deletePagoDialog.visible" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span> ¿Está seguro de borrar el pago?{{index}} {{id}}</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePagoDialog.visible = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deletePago(index,id)" />
      </template>
    </Dialog> 

    <!-- Para seleccionar la Nota CD -->
    <Dialog v-model:visible="dialogNDC.visible" style="width: 25rem !important'" header="Notas de Crédito / Débito" :modal="true">
      <div class="content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 1rem; vertical-align: middle;" />
        <span>Seleccione la Nota de Crédito / Débito:<br/></span>
      </div>
      
      <DataTable ref="dtnc" :value="lrebaja" showGridlines stripedRows scrollable 
      scrollHeight="400px" tableStyle="min-width: 50%" v-model:selection="selectedNotas" 
      @rowSelect="onNotaSelect" @rowUnselect="onNotaSelect">
        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>            
        <Column field="id" header="ID" style="max-width: 14%" :hidden="true">
          <template #editor="{ data, field }">
            <InputNumber v-model="data[field]"/>
          </template>
        </Column>          
        <Column field="fecha" header="Fecha">
          <template #body="slotProps">
            <span>
              {{ formatFecha(slotProps.data.fecha) }}
            </span>
          </template>
        </Column>    
        <Column field="tipo" header="Tipo">
          <template #body="slotProps">
            <Tag :value="slotProps.data.tipo" :severity="slotProps.data.tipo === 'NC' ? 'success' : 'danger'" />
          </template>
        </Column>             
        <Column field="folio" header="Folio">
          <template #editor="{ data, field }">
            <InputNumber v-model="data[field]"/>
          </template>
        </Column>          
        <Column field="monto_total" header="Monto">
          <template #editor="{ data, field }">
            <InputNumber v-model="data[field]"/>
          </template>
        </Column>

        <Column field="rebajada" header="Aplicada">
          <template #body="slotProps">
            <span>
              <i class="pi text-center" :class="{ 'pi-check-circle text-success': slotProps.data.aplicada, 'pi-times-circle text-danger': !slotProps.data.aplicada }"></i>
            </span>
          </template>           
        </Column>

        <Column field="disponible" header="Disponible">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.disponible ? 'SI' : 'NO' + slotProps.data.msj_disponible }}
            </span>
          </template>
        </Column>
                  
      </DataTable>      
      <!--  -->
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="noVinculaNDC()" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="vinculaNDC()"/>
      </template>
    </Dialog>       
  </div>
</template>

<script>
import useRecepcionesPago from "../../composables/recepciones/rpagos"
import { reactive, ref, onMounted, computed } from "vue"
import moment from "moment"

export default {
  setup() {
    const {
      recepcionesSII,
      dt,
      loading,
      saving,
      submitted,
      filters,
      filtersProv,
      pagoDialog,
      recepcion,
      tpagos,
      filteredTipoPagos,
      lineas,
      messages,
      modalidades,
      estados,
      filteredEstados,
      filteredModalidades,
      proveedores,
      proveedor,
      docsii,
      selectedRecepciones,
      getRecepcionSII,
      storePagos,
      getProveedores,
      getPDFSII,
      deletePagoDialog,
      deletePago,
      fromCompras,
      router,
      getProveedor,
      informePagos,
      selectedNotas
    } = useRecepcionesPago()

    moment.locale('es')

    // const filteredProveedores = ref()
    const dialogNDC = ref({visible:false, index:null, id: null})
    const lrebaja = ref()
    const filteredFolios = ref()
    const fdesde = fromCompras.value === true ? moment(`${router.currentRoute.value.params.fdesde}`, 'DD-MM-YYYY').toDate() : ref(moment().startOf('month').toDate())
    let fhasta = fromCompras.value === true ? moment(`${router.currentRoute.value.params.fhasta}`, 'DD-MM-YYYY').toDate() : ref(moment().endOf('month').toDate())

    const estado = ref(null)
    const totPagar = ref(0)
    const totNDNC = ref(0)
    const NDNCSelOri = ref({})

    const totalTotal = computed(() => {
      let total = 0
      if(selectedRecepciones.value !== undefined)
        for (let recep of selectedRecepciones.value) total += recep.total
      return total
    })

    const totalNeto = computed(() => {
      let total = 0
      if(selectedRecepciones.value !== undefined)
        for (let recep of selectedRecepciones.value) total += recep.neto
      return total
    })

    const totalIVA = computed(() => {
      let total = 0
      if(selectedRecepciones.value !== undefined)
        for (let recep of selectedRecepciones.value) total += recep.iva
      return total
    })

    const totalAbono = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let pago of lineas.value) {
          if(pago.monto_abono !== null)
            total += pago.monto_abono
        }
      return total
    })

    const totalPendiente = computed(() => {
      return (Number(Math.round(totalTotal.value)) - Number(Math.round(totalAbono.value)))
    })

    const form = reactive({
      folio_pago: null,
      foliosRecep: null,
      recepciones: [],
      notasdc: [],
      pagos: []
    })

    const searchModalidad = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredModalidades.value = [...modalidades.value]
        } else {
          filteredModalidades.value = modalidades.value.filter((modalidad) => {
            return modalidad.toUpperCase().startsWith(event.query.toUpperCase())
          })
        }
      }, 250)
    }

    const searchEstado = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredEstados.value = [...estados.value]
        } else {
          filteredEstados.value = estados.value.filter((estado) => {
            return estado.toUpperCase().startsWith(event.query.toUpperCase())
          })
        }
      }, 250)
    }

    const formatFecha = (value) => {
      return moment(value).format("DD-MM-YY")
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'}).format(value)
    }

    const hideDialog = () => {
      selectedRecepciones.value = []
      messages.value = []
      pagoDialog.value = false
      submitted.value = false
      saving.value = false
      form.folio_pago = null
      form.foliosRecep = null
      form.recepciones = []
      form.pagos = []
    }

    const searchTPago = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoPagos.value = [...tpagos.value]
        } else {
          filteredTipoPagos.value = tpagos.value.filter((tpago) => {
            return tpago.nombre.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchFolios = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredFolios.value = [...docsii.value]
        } else {
          filteredFolios.value = docsii.value.filter((doc) => {
            return doc.folio.toString().toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const getProveedoresPeriodo = () => {
      messages.value = []
      recepcionesSII.value = []
      selectedRecepciones.value = []
      filtersProv.value.global.value = null
      getProveedores(fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'))
    }

    const buscarRecepciones = (event, modo) => {
      messages.value = []
      selectedRecepciones.value = [];
      if(modo !== 0){ //Modo 0 es unSelect es decir no voy a backend
        proveedor.value = event.data
        let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))
        getRecepcionSII(proveedor.value.rut_dv, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'),edo)
      }
    }    

    const addLinea = () => {
      let row = {
        'id': null,
        'modalidad': 'TRANSFERENCIA',
        'entidad': null,
        'referencia': null,
        'monto_abono': null,
        'fecha_pago': moment().format("DD-MM-YY"),
        'observacion': ''
      }

      if (lineas.value.length === 0)
        lineas.value.push(row)
      else if (lineas.value[lineas.value.length - 1].modalidad !== '')
        lineas.value.push(row)
      else
        messages.value = [{
          content: 'Debe seleccionar la modalidad antes de agregar nueva línea',
          id: lineas.value.length
        }]
    }

    const toggleDelete = (index) => {
      freeNotas()
      let row = {
        'id': null,
        'modalidad': null,
        'referencia': 0,
        'porc_abono': 0,
        'monto_abono': null,
        'fecha_vence': null,
        'fecha_pago': null,
        'cuota': '',
        'entidad': '',
        'observacion': '',
        'estado': ''
      }
      lineas.value[index] = row
      lineas.value.splice(index, 1)
      if (lineas.value.length === 0) lineas.value.pop()
      messages.value = []
    }

    const savePago = () => {
      submitted.value = true
      messages.value = []

      if (lineas.value.length > 0) {
        // valida que esten modalidad / monto_abono / para cada linea
        lineas.value.map((ln) => {
          if (ln.modalidad === null) messages.value.push({
            content: 'Debe seleccionar la modalidad del pago antes de guardar',
            id: lineas.value.length
          })
          if (ln.monto_abono === null || ln.monto_abono === 0) messages.value.push({
            content: 'Debe ingresar los datos del pago antes de guardar',
            id: lineas.value.length
          })
        })

        //validar que cada linea de pago no supere el total
        let tabono = Number(Math.round(totalAbono.value))
        let ttotal = Number(Math.round(totalTotal.value))
        if(Math.abs(tabono-ttotal-totalIVA) > 150 ) messages.value.push({ content: 'El monto de los abonos es mayor al total de las recepciones seleccionadas: ' + formatCurrency(Math.abs(ttotal-tabono)) + ' ' + tabono + ' ' + ttotal + ' ' + totalIVA, id: lineas.value.length })

      } else
        messages.value.push({content: 'Debe seleccionar los datos del pago antes de guardar', id: lineas.value.length})

      if (messages.value.length === 0) {
        form.pagos = lineas
        storePagos(form, proveedor, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'))
      }
    }

    const setPagosMasivo = () => {
      lineas.value = []
      let idr = [], fdr = [], _lineas = []
      selectedRecepciones.value.map((lnr) => {

        fdr.push(lnr.tipo_doc_recep + '-' + lnr.folio_doc_recep)
        idr.push(lnr.id)

        if(lnr.recepciones !== null)
          lnr.pagos.map((ln) => {
            _lineas.push({
              'id': ln.id,
              'modalidad': ln.modalidad ?? null,
              'entidad': ln.entidad ?? null,
              'referencia': ln.referencia ?? null,
              'monto_abono': ln.monto_abono ?? null,
              'fecha_pago': ln.fecha_pago ? moment(ln.fecha_pago).format("DD-MM-YY") : moment().format("DD-MM-YY"),
              'observacion': ln.observacion ?? null
            })
          })
      })

      lineas.value = Object.values(_lineas.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}))

      form.foliosRecep = fdr.join(',')
      form.recepciones = idr

      messages.value = []
      pagoDialog.value = true
    }

    const onCellEditComplete = (event) => {
      let {data, newValue, field} = event
      data[field] = newValue
      messages.value = []

      if (field.includes('fecha_')) data[field] = formatFecha(newValue)
      if (field.includes('monto_abono')) {
        data['monto_abono'] = Math.round(data['monto_abono'])
      }
    }

    const onCellEditInit = (event) => {
      let {data, newValue, field} = event

      if (field.includes('monto_abono')) {
        data[field] = new Intl.NumberFormat({style: 'currency'}).format(Math.round(newValue))
      }
    }

    const onRowSelect = (event) => {
      if(event.data.tipo_doc_recep === 'FC' && selectedRecepciones.value.length > 1) {
        selectedRecepciones.value.pop()
        setPagosMasivo()
      } else if(event.data.tipo_doc_recep === 'FC'){
        setPagosMasivo()
      }
    }

    const onRowUnselect = (event) => {
    }

    const exportCSV = (event, modo) => {
      messages.value = []
      selectedRecepciones.value = []
      let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))
      informePagos(proveedor.value.rut_dv, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'),edo)
    }

    const getDocPago = (rec, field) => {
      if(rec.pagos.length > 0){
        if (field === 'folio_pago'){
          let dp = rec.pagos.filter(function (itm) { return [rec.id].indexOf(itm.folio) > -1 })[0]
          return dp
        }else if(field === 'fecha_docto'){
          let dp = rec.pagos.filter(function (itm) { return [rec.folio_doc_recep].indexOf(itm.folio) > -1 })[0]
          return dp
        }
      }
    }

    const confirmDeletePago = (index, id) => {
      deletePagoDialog.value.visible = true
      deletePagoDialog.value.index = index
      deletePagoDialog.value.id = id
    }

    const registrarAbonos = () => {
      messages.value = [{content: 'Cargando Datos ... ', id: lineas.value.length}]
      fromCompras.value = true
      loading.value = true

      let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))
      getRecepcionSII(`${router.currentRoute.value.params.rut}`, `${router.currentRoute.value.params.fdesde}`, `${router.currentRoute.value.params.fhasta}`,edo)
      messages.value = []
      loading.value = false
    }

    const dummyClick = () => {
      document.getElementById('dummy').click()
    }    

    const selectNota = (event, index) => {
      if(event.value === 'SALDO NOTA CRÉDITO/DÉBITO'){
        selectedNotas.value = []
        lrebaja.value = []
        messages.value = []
        totPagar.value = 0

        totPagar.value = selectedRecepciones.value.reduce((n, {total}) => n + total, 0)

        selectedRecepciones.value[0].notasdc.map((ln) => {

          if('60 61 112'.match(ln.tipo_doc)){
            lrebaja.value.push({
              'id': ln.id,
              'tipo': 'NC',
              'folio': ln.folio,
              'fecha' : ln.fecha_docto,
              'monto_total': ln.monto_total,
              'rebajada': ln.rebajada,
              'disponible' : ln.monto_total <= totPagar.value && ln.rebajada === 0 ? true : false,
              'msj_disponible' : ln.monto_total > totPagar.value ? ' - Total a pagar es menor al monto de la Nota' : (ln.rebajada !== 0 ? ' - La Nota ya esta aplicada' : '')
            })
          }else{
            lrebaja.value.push({
              'id': ln.id,
              'tipo': 'ND',
              'folio': ln.folio,
              'fecha' : ln.fecha_docto,
              'monto_total': ln.monto_total,
              'rebajada': ln.rebajada,
              'disponible': ln.rebajada === 0 ? true : false,
              'msj_disponible': ln.rebajada !== 0 ? ' - La Nota ya esta aplicada' : ''         
            }) 
          }         
        }) 

        if(lrebaja.value.length > 0){         
          dialogNDC.value.visible = true
          dialogNDC.value.index = index
        }else{
          toggleDelete(index)
          messages.value = [{content: 'No existen Notas Débito/Crédito para asociar', id: lineas.value.length}]
        }
      } 
    }

    const vinculaNDC = () => { 
      totPagar.value = selectedRecepciones.value.reduce((n, {total}) => n + total, 0)
      totNDNC.value = selectedNotas.value.reduce((n, {monto_total}) => n + monto_total, 0)
      NDNCSelOri.value = selectedNotas.value
      let referencias = ''

      recepcionesSII.value.map((ln) => {
        if(ln.notasdc !== null)
          ln.notasdc.map((nota) => {
            selectedNotas.value.map((nsel) => {
              if(nota.id === nsel.id){
                nota.rebajada = 1 
                nsel.rebajada = 1
                //aplica en las lineas de pago agregadas
                referencias = referencias.match(nsel.folio) ? referencias + ' ' : referencias + ('60 61 112'.match(selectedNotas.value.tipo_doc) ? 'NC: ' + nsel.folio : 'ND ' + nsel.folio) + ' '
              }              
            })
          })
      })

      lineas.value[dialogNDC.value.index].referencia = referencias.trim()
      lineas.value[dialogNDC.value.index].monto_abono = totNDNC.value
      lineas.value[dialogNDC.value.index].estado = totNDNC.value >= 0 ? 'PAGADO':'PENDIENTE'
      lineas.value[dialogNDC.value.index].porc_abono =  parseInt(Math.round((totNDNC.value * 100) / totPagar.value))

      form.notasdc = selectedNotas.value
      dialogNDC.value.visible = false
    }  

    const noVinculaNDC = () => { 
      totNDNC.value = 0
      freeNotas()      
      lineas.value[dialogNDC.value.index].referencia = ''
      lineas.value[dialogNDC.value.index].monto_abono = null
      lineas.value[dialogNDC.value.index].estado = null
      lineas.value[dialogNDC.value.index].porc_abono =  null

      dialogNDC.value.visible = false
    }  

    const onNotaSelect = (event) => {
      selectedNotas.value = selectedNotas.value.filter(item => item.disponible === true)
    }

    const freeNotas = () => {
      if(NDNCSelOri.value.length > 0)
        recepcionesSII.value.map((ln) => {
          if(ln.notasdc !== null)
            ln.notasdc.map((nota) => {
              NDNCSelOri.value.map((nsel) => {
                if(nota.id === nsel.id){
                  nota.rebajada = 0 
                  nsel.rebajada = 0
                }              
              })
            })
        })
    }

    const selAllText = (event) => {
      event.target.setSelectionRange(0, event.srcElement._value.length)
    }

    if (router.currentRoute.value.params.rut) {
      fromCompras.value = true
      fdesde.value = fromCompras.value === true ? moment(`${router.currentRoute.value.params.fdesde}`, 'DD-MM-YYYY').toDate() : ref(moment().startOf('month').toDate())
      fhasta.value = fromCompras.value === true ? moment(`${router.currentRoute.value.params.fhasta}`, 'DD-MM-YYYY').toDate() : ref(moment().endOf('month').toDate())      
      onMounted(registrarAbonos) 
    } else {
      fromCompras.value = false
      onMounted(getProveedoresPeriodo) 
    }    

    return {
      dt,
      form,
      loading,
      saving,
      recepcionesSII,
      filters,
      filtersProv,
      fdesde,
      fhasta,
      proveedor,
      proveedores,
      recepcion,
      lineas,
      pagoDialog,
      tpagos,
      filteredTipoPagos,
      submitted,
      messages,
      modalidades,
      estados,
      filteredEstados,
      filteredModalidades,
      estado,
      selectedRecepciones,
      totalTotal,
      totalIVA,
      totalNeto,
      totalAbono,
      totalPendiente,
      onRowUnselect,
      onRowSelect,
      formatCurrency,
      formatFecha,
      onCellEditComplete,
      onCellEditInit,
      searchTPago,
      setPagosMasivo,
      hideDialog,
      addLinea,
      searchEstado,
      searchModalidad,
      searchFolios,
      filteredFolios,
      toggleDelete,
      savePago,
      getProveedoresPeriodo,
      buscarRecepciones,
      exportCSV,
      getPDFSII,
      deletePago,
      confirmDeletePago,
      deletePagoDialog,
      fromCompras,
      proveedor,
      getProveedor,
      getDocPago,
      informePagos,
      dummyClick,
      selectNota,
      dialogNDC,
      vinculaNDC,
      noVinculaNDC,
      NDNCSelOri,
      lrebaja,
      selectedNotas,
      onNotaSelect,
      totPagar,
      freeNotas,
      selAllText
    }
  },
}
</script>
