import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useProductos() {
  const producto = ref([])
  const productos = ref([])
  const productosDelete = ref([])
  const tipoMaderas = ref([])

  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedProductos = ref()
  const deleteProductosDialog = ref(false)
  const deleteProductoDialog = ref(false)
  const productoDialog = ref(false)
  const submitted = ref(false)

  const filteredTipoMaderas = ref()

  const tipoUmedida = ref([
    {name: "Pulgada", key: "P"},
    {name: "Metro 3", key: "M"},
    {name: "Unidad", key: "U"},
    {name: "MM", key: "N"},
    {name: "Metro Ruma", key: "R"},
    {name: "Nulo", key: "X"}
  ])

  const selectedUmedidaProductos = ref([])

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/productos/all/crud`).then(response => {
      productos.value = response.data.data.productos
      tipoMaderas.value = response.data.data.tipoMaderas
      loading.value = false
    })
  }

  const storeProducto = async (form) => {
    saving.value = true
    let id = producto.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/productos/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          productoDialog.value = false
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/productos`, { 'data': { ...form } })
        .then(() => {
          productoDialog.value = client.dialog.value
          producto.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteProducto = async () => {
    client.remove(`/api/productos/${producto.value.id}`)
      .then(response => {
        productos.value = productos.value.filter(val => val.id !== producto.value.id)
        deleteProductoDialog.value = false
        producto.value = {}
      })
  }

  const deleteSelectedProductos = async () => {
    productosDelete.value = selectedProductos.value.map(({ id }) => id)
    client.remove(`/api/productos/destroy/mass`, { data: productosDelete.value })
      .then(response => {
        productos.value = productos.value.filter(
          (val) => !selectedProductos.value.includes(val)
        )
        deleteProductosDialog.value = false
        selectedProductos.value = null
        productosDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    id: { value: null, matchMode: FilterMatchMode.EQUALS },
    nombre: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'tipoMadera._nombre': { value: null, matchMode: FilterMatchMode.CONTAINS },
    pulgada: { value: null, matchMode: FilterMatchMode.EQUALS },
    metro3: { value: null, matchMode: FilterMatchMode.EQUALS },
    unidad: { value: null, matchMode: FilterMatchMode.EQUALS },
    mm: { value: null, matchMode: FilterMatchMode.EQUALS },
    mruma: { value: null, matchMode: FilterMatchMode.EQUALS },
    nulo: { value: null, matchMode: FilterMatchMode.EQUALS },
    vigente: { value: null, matchMode: FilterMatchMode.EQUALS }
  })

  return {
    producto,
    productos,
    dt,
    loading,
    saving,
    filters,
    selectedProductos,
    deleteProductosDialog,
    deleteProductoDialog,
    productoDialog,
    submitted,
    tipoUmedida,
    selectedUmedidaProductos,
    deleteSelectedProductos,
    getCRUD,
    storeProducto,
    deleteProducto,
    tipoMaderas,
    filteredTipoMaderas
  }
}