import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useEmpresas() {
  const empresa = ref([])
  const empresas = ref([])
  const empresasDelete = ref([])

  const client = useClient()
  const ciudades = ref([])
  const gastos = ref([])
  const comunas = ref([])
  const fscs = ref([])
  const disabledCodigo = ref([])

  const dt = ref({})
  const dt_fsc= ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedEmpresas = ref()
  const selectedFscs = ref([])
  const selectedCodigoFscs = ref([])
  const deleteEmpresasDialog = ref(false)
  const deleteEmpresaDialog = ref(false)
  const empresaDialog = ref(false)
  const submitted = ref(false)

  const filteredCiudades = ref()
  const filteredComunas = ref()
  const filteredGastos = ref()

  const tipoEmpresa = ref([
    {name: "Cliente", key: "C"},
    {name: "Proveedor", key: "P"},
    {name: "Transportista", key: "T"}
  ])

  const fscEmpresa = ref([])

  const empresaTipo = ref({ name: 'vigente', key: 'V' })

  const selectedTipoEmpresas = ref([])

  const getCRUD = async (tipo) => {
    loading.value = true
    let tempresa = tipo === 0 ? 'nada' : empresaTipo.value.name.toLowerCase()
    client.get(`/api/empresas/all/${tempresa}/crud`).then(response => {
      empresas.value = response.data.data.empresas
      ciudades.value = response.data.data.ciudades
      gastos.value = response.data.data.gastos
      comunas.value = response.data.data.comunas
      fscs.value = response.data.data.fscs
      loading.value = false
    })
  }

  const storeEmpresa = async (form) => {
    saving.value = true
    let id = empresa.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/empresas/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          empresaDialog.value = false
          //client.saving.value = false ?? getCRUD()
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/empresas`, { 'data': { ...form } })
        .then(() => {
          empresaDialog.value = client.dialog.value
          empresa.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteEmpresa = async () => {
    client.remove(`/api/empresas/${empresa.value.id}`)
      .then(response => {
        empresas.value = empresas.value.filter(val => val.id !== empresa.value.id)
        deleteEmpresaDialog.value = false
        empresa.value = {}
      })
  }

  const deleteSelectedEmpresas = async () => {
    empresasDelete.value = selectedEmpresas.value.map(({ id }) => id)
    client.remove(`/api/empresas/destroy/mass`, { data: empresasDelete.value })
      .then(response => {
        empresas.value = empresas.value.filter(
          (val) => !selectedEmpresas.value.includes(val)
        )
        deleteEmpresasDialog.value = false
        selectedEmpresas.value = null
        empresasDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },
    'razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'vigente': { value: null, matchMode: FilterMatchMode.EQUALS },
    'cliente': { value: null, matchMode: FilterMatchMode.EQUALS },
    'proveedor': { value: null, matchMode: FilterMatchMode.EQUALS },
    'transportista': { value: null, matchMode: FilterMatchMode.EQUALS },
  })

  return {
    empresa,
    empresas,
    ciudades,
    gastos,
    comunas,
    fscs,
    disabledCodigo,
    dt_fsc,
    dt,
    loading,
    saving,
    filters,
    selectedEmpresas,
    selectedFscs,
    selectedCodigoFscs,
    deleteEmpresasDialog,
    deleteEmpresaDialog,
    empresaDialog,
    submitted,
    filteredCiudades,
    filteredComunas,
    filteredGastos,
    tipoEmpresa,
    fscEmpresa,
    selectedTipoEmpresas,
    empresaTipo,
    deleteSelectedEmpresas,
    getCRUD,
    storeEmpresa,
    deleteEmpresa
  }
}