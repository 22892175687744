import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useGastos() {
  const gasto = ref([])
  const gastos = ref([])
  const tipoGastos = ref([])
  const gastosDelete = ref([])

  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedGastos = ref()
  const deleteGastosDialog = ref(false)
  const deleteGastoDialog = ref(false)
  const gastoDialog = ref(false)
  const submitted = ref(false)

  const filteredTipoGastos = ref()

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/gastos/all/crud`).then(response => {
      gastos.value = response.data.data.gastos
      tipoGastos.value = response.data.data.tgastos
      loading.value = false
    })
  }

  const storeGasto = async (form) => {
    saving.value = true
    let id = gasto.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/gastos/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          gastoDialog.value = false
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/gastos`, { 'data': { ...form } })
        .then(() => {
          gastoDialog.value = client.dialog.value
          gasto.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteGasto = async () => {
    client.remove(`/api/gastos/${gasto.value.id}`)
      .then(response => {
        gastos.value = gastos.value.filter(val => val.id !== gasto.value.id)
        deleteGastoDialog.value = false
        gasto.value = {}
      })
  }

  const deleteSelectedGastos = async () => {
    gastosDelete.value = selectedGastos.value.map(({ id }) => id)
    client.remove(`/api/gastos/destroy/mass`, { data: gastosDelete.value })
      .then(response => {
        gastos.value = gastos.value.filter(
          (val) => !selectedGastos.value.includes(val)
        )
        deleteGastosDialog.value = false
        selectedGastos.value = null
        gastosDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    gasto,
    gastos,
    tipoGastos,
    dt,
    loading,
    saving,
    filters,
    selectedGastos,
    deleteGastosDialog,
    deleteGastoDialog,
    gastoDialog,
    submitted,
    filteredTipoGastos,
    deleteSelectedGastos,
    getCRUD,
    storeGasto,
    deleteGasto
  }
}