import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useTipoDocumentos() {
  const tipoDocumento = ref([])
  const tipoDocumentos = ref([])
  const tipoDocumentosDelete = ref([])

  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedTipoDocumentos = ref()
  const deleteTipoDocumentosDialog = ref(false)
  const deleteTipoDocumentoDialog = ref(false)
  const tipoDocumentoDialog = ref(false)
  const submitted = ref(false)

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/tdocumentos/all/crud`).then(response => {
      tipoDocumentos.value = response.data.data.tipoDocumentos
      loading.value = false
    })
  }

  const storeTipoDocumento = async (form) => {
    saving.value = true
    let id = tipoDocumento.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/tdocumentos/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          tipoDocumentoDialog.value = false
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/tdocumentos`, { 'data': { ...form } })
        .then(() => {
          tipoDocumentoDialog.value = client.dialog.value
          tipoDocumento.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteTipoDocumento = async () => {
    client.remove(`/api/tdocumentos/${tipoDocumento.value.id}`)
      .then(response => {
        tipoDocumentos.value = tipoDocumentos.value.filter(val => val.id !== tipoDocumento.value.id)
        deleteTipoDocumentoDialog.value = false
        tipoDocumento.value = {}
      })
  }

  const deleteSelectedTipoDocumentos = async () => {
    tipoDocumentosDelete.value = selectedTipoDocumentos.value.map(({ id }) => id)
    client.remove(`/api/tdocumentos/destroy/mass`, { data: tipoDocumentosDelete.value })
      .then(response => {
        tipoDocumentos.value = tipoDocumentos.value.filter(
          (val) => !selectedTipoDocumentos.value.includes(val)
        )
        deleteTipoDocumentosDialog.value = false
        selectedTipoDocumentos.value = null
        tipoDocumentosDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    tipoDocumento,
    tipoDocumentos,
    dt,
    loading,
    saving,
    filters,
    selectedTipoDocumentos,
    deleteTipoDocumentosDialog,
    deleteTipoDocumentoDialog,
    tipoDocumentoDialog,
    submitted,
    deleteSelectedTipoDocumentos,
    getCRUD,
    storeTipoDocumento,
    deleteTipoDocumento
  }
}