import { ref, isProxy, toRaw } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'
import useInformes from '../../composables/informes/index'

export default function useAGRecepciones() {
  
  const fsc = ref([])
  const guias = ref([])
  const fscs = ref([])
  const un_producto = ref(false)
  const porciva = ref()
  const messages = ref([])

  const client = useClient()
  const informes = useInformes()

  const proveedores = ref([])
  const productos = ref([])
  const selectedProductos = ref([])
  const selectedGuias = ref([])
  const showcol = ref('')
  const colspan = ref(8)
  const hiddcol = ref(true)
  const dt = ref({})
  const dtg = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const submitted = ref(false)

  const filteredProveedores = ref()
  const filteredFscs = ref()
  const filteredProductos = ref()
  const editingRows = ref([])

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/recepciones/act/crud`).then(response => {
      proveedores.value = response.data.data.proveedores
      productos.value = response.data.data.productos
      loading.value = false
    })
  }

  const actualizaGuia = async (form, guias) => {
    saving.value = true
    // console.info(guias.va)
    //Esta editando el registro
    client.put(`/api/recepciones/act/guias`, { 'data': { ...guias } })
    
    .then(() => {
      messages.value = []
      let proveedor = isProxy(form.proveedor) ? toRaw(form.proveedor) : null
      let producto = isProxy(form.producto) ? toRaw(form.producto).id : 0
      //refresca vista
      getGuias(
        proveedor.id,
        form.fdesde.toLocaleDateString("es-CL"),
        form.fhasta.toLocaleDateString("es-CL"),
        producto
      )
      //generar la guia de recepcion actualizada
      informes.recepcionPDF(guias[0].recepcion_id)
    })
  }

  const getGuias = async (id,fdesde,fhasta,pid) => {
    loading.value = true
    client.get(`/api/recepciones/${id}/${fdesde}/${fhasta}/act/guias/${pid}`).then(response => {
      let recuentosTipoMadera = response.data.data.guias.reduce(function(obj, guia){
        switch (guia.tmadera) {
          case 9:
            // showcol.value = 'espesor,ancho,largo,pulg_recepcion,cant_recepcion'
            showcol.value = 'diametro,largo,mc_recepcion,cant_recepcion' + ',' + showcol.value
            colspan.value = 11
            break;          
          case 7:
            // showcol.value = 'espesor,ancho,largo,pulg_recepcion,cant_recepcion'
            showcol.value = 'pulg_recepcion,cant_recepcion' + ',' + showcol.value
            colspan.value = colspan.value === 11 ? 11 : 9
            break;
          case 10:
            // showcol.value = 'alto,ancho,largo,mc_recepcion,mruma_recepcion,cant_recepcion'
            showcol.value = 'mruma_recepcion,cant_recepcion' + ',' + showcol.value
            colspan.value = colspan.value === 11 ? 11 : 9
            break;
          case 11:
            // showcol.value = 'alto,ancho,largo,mc_recepcion,mruma_recepcion,cant_recepcion'
            showcol.value = 'mruma_recepcion' + ',' + showcol.value
            colspan.value = colspan.value === 11 ? 11 : 9
            break;
          default:
            showcol.value = 'mc_recepcion,cant_recepcion' + ',' + showcol.value
            colspan.value = colspan.value === 11 ? 11 : 9
        }
        return obj;
      }, {});

      guias.value = response.data.data.guias
      fscs.value = response.data.data.fscs
      porciva.value = response.data.data.porciva
      loading.value = false
    })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    fsc,
    un_producto,
    porciva,
    fscs,
    guias,
    proveedores,
    showcol,
    hiddcol,
    dt,
    dtg,
    loading,
    saving,
    filters,
    submitted,
    filteredProveedores,
    filteredFscs,
    filteredProductos,
    selectedProductos,
    selectedGuias,
    productos,
    editingRows,
    getCRUD,
    getGuias,
    actualizaGuia,
    colspan
  }
}