<template>
  <Toast />
  <div class="card-header">
    <h4>Clqsificación de Gastos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Usuarios -->
      <DataTable ref="dt" :value="gastos" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" :filters="filters"
        v-model:selection="selectedGastos" responsiveLayout="scroll" breakpoint="990px">

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>

            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
              &nbsp;
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectedGastos || !selectedGastos.length" />
              &nbsp;
              <Button label="Descargar" icon="pi pi-download" class="p-button-help" @click="exportCSV($event)" />
            </template>
          </Toolbar>
        </template>

        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>       

        <Column field="id" header="COD.Gasto" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.id }}
            </span>
          </template>
        </Column>

        <Column field="nombre" header="Gasto" >
          <template #body="slotProps">
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </template>
        </Column>

        <Column field="vigente" header="Estado" >
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>   
        </Column>
        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editGasto(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteGasto(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario Gastos en modal -->
    <Dialog v-model:visible="gastoDialog" :style="{ width: '50%' }" header="Datos del Origen - Destino" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">          
          <div class="col-md-1 field" v-if="form.id">
            <label for="id" class="mt-3">COD.Gasto</label>
            <InputNumber id="id" v-model="form.id" autofocus autocomplete="off" readonly/>
          </div>     
          <div class="col-md-11 field">
            <label for="nombre" class="mt-3">Gasto</label>
            <InputText id="nombre" v-model="v$.nombre.$model"
              :class="{ 'p-invalid': v$.nombre.$invalid && submitted }" aria-describedby="nombre-error" autofocus
              autocomplete="off" />
            <span v-if="v$.nombre.$error && submitted">
              <span id="nombre-error" v-for="(error, index) of v$.nombre.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response" class="p-error">
              {{ v$.nombre.required.$message.replace('Value', 'RUT') }}</small>
          </div>     
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <InputText id="observacion" v-model.trim="form.observacion" />
          </div>          
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveGasto" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un gasto -->
    <Dialog v-model:visible="deleteGastoDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="gasto"> ¿Está seguro de borrar el Gasto?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteGastoDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteGasto" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente gastos -->
    <Dialog v-model:visible="deleteGastosDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedGastos"> ¿Está seguro de borrar los gastos?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteGastosDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedGastos" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useGastos from '../../composables/cruds/gastos'
import { reactive, onMounted } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      gasto,
      gastos,
      tipoGastos,
      filteredTipoGastos,
      dt, 
      loading, 
      saving,
      filters,
      selectedGastos,
      deleteGastosDialog,
      deleteGastoDialog,
      gastoDialog,
      submitted,
      storeGasto,      
      deleteSelectedGastos,
      getCRUD,
      deleteGasto
    } = useGastos()

    const form = reactive({
      nombre: '',
      tgasto_id: '',
      observacion: '',
      vigente: ''
    })

    const rules = {
      nombre: { required: helpers.withMessage('El campo es requerido', required)},
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      // console.info(dt.value)
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteGastosDialog.value = true
    }

    const confirmDeleteGasto = (desp) => {
      gasto.value = desp
      deleteGastoDialog.value = true
    }

    const openNew = () => {
      gasto.value = {}
      submitted.value = false
      gastoDialog.value = true
      saving.value = false

      form.nombre = ''
      form.tipoGasto = ''
      form.observacion = ''
      form.vigente = true

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const hideDialog = () => {
      gastoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const editGasto = (desp) => {
      gasto.value = { ...desp }
      gastoDialog.value = true

      form.id = gasto.value.id
      form.nombre = gasto.value.nombre
      form.tipoGasto = gasto.value.tipoGasto
      form.observacion = gasto.value.observacion
      form.vigente = gasto.value.vigente
      
      form.created_at = gasto.value.created_at
      form.created_id = gasto.value.created_id
      form.updated_at = gasto.value.updated_at
      form.updated_id = gasto.value.updated_id
    }

    const searchTipoGasto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoGastos.value = [...tipoGastos.value]
        }
        else {
          filteredTipoDocumentos.value = tipoGastos.value.filter((tgasto) => {
            return tgasto._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const saveGasto = () => {
      submitted.value = true
      if(!v$._value.$invalid)
        storeGasto(form)  
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      gasto,
      gastos,
      tipoGastos,
      filteredTipoGastos,
      filters,
      selectedGastos,
      deleteGastosDialog,
      deleteGastoDialog,
      gastoDialog,
      submitted,
      v$,
      form,
      hideDialog,
      openNew,
      confirmDeleteGasto,
      deleteSelectedGastos,
      deleteGasto,
      exportCSV,
      confirmDeleteSelected,
      editGasto,
      saveGasto,
      storeGasto,
      searchTipoGasto
    }
  },
}
</script>