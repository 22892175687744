<template>
  <Toast />
  <div class="card-header">
    <h4>Listado de Empresas</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>      
      <!-- DT Empresas -->
      <DataTable ref="dt" :value="empresas" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort
        :globalFilterFields="['rut_dv','razon_social','email', 'vigente', 'cliente', 'proveedor', 'transportista']" sortMode="multiple" dataKey="id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        v-model:selection="selectedEmpresas" responsiveLayout="scroll" breakpoint="990px">        
        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
              <div class="table-header flex flex-column md:flex-row">
                <span class="p-input-icon-right">
                  <SelectButton v-model="empresaTipo" :options="tipoEmpresa" optionLabel="name" dataKey="name" @click="setFilter"/>
                </span>
              </div>
            </template>

            <template #end>
              <Button label="Crear" icon="pi pi-plus" class="p-button-success" @click="openNew" />
              &nbsp;
              <Button label="Borrar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedEmpresas || !selectedEmpresas.length" />
              &nbsp;
              <Button label="Descargar" icon="pi pi-download" class="p-button-help" @click="exportCSV($event)" />
            </template>                  
          </Toolbar>
        </template>

        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

        <Column field="rut_dv" header="RUT" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.rut_dv }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="razon_social" header="Razón Social" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="email" header="Contacto" sortable>
          <template #body="slotProps">
            <span v-if="slotProps.data.contacto">
              {{ slotProps.data.contacto }}<br/>
            </span>
            <span>
              {{ slotProps.data.email }}
            </span>
            <span v-if="slotProps.data.telefono">
              &nbsp;{{ slotProps.data.telefono }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="cliente" header="Cliente" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi text-center" :class="{ 'pi-check-circle text-success': slotProps.data.cliente, 'pi-times-circle text-danger': !slotProps.data.cliente }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="proveedor" header="Proveedor" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi text-center" :class="{ 'pi-check-circle text-success': slotProps.data.proveedor, 'pi-times-circle text-danger': !slotProps.data.proveedor }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column> 

        <Column field="transportista" header="Transportista" sortable>
          <template #body="slotProps">
            <span>
              <i class="pi text-center" :class="{ 'pi-check-circle text-success': slotProps.data.transportista, 'pi-times-circle text-danger': !slotProps.data.transportista }"></i>
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column field="vigente" header="Estado" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.vigente ? 'ACTIVO' : 'INACTIVO'" :severity="slotProps.data.vigente ? 'success' : 'danger'" />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>          
        </Column>

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
              @click="editEmpresa(slotProps.data)" />&nbsp;
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="confirmDeleteEmpresa(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Formulario Empresas en modal -->
    <Dialog v-model:visible="empresaDialog" :style="{ width: '50%' }" header="Datos de La Empresa" :modal="true"
      class="p-fluid" :maximizable="true">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-2 field">
            <label for="rut_dv" class="mt-3">RUT</label>
            <InputText id="rut_dv" v-model="v$.rut_dv.$model"
              :class="{ 'p-invalid': v$.rut_dv.$invalid && submitted }" aria-describedby="rut_dv-error" autofocus
              autocomplete="off" />
            <span v-if="v$.rut_dv.$error && submitted">
              <span id="rut_dv-error" v-for="(error, index) of v$.rut_dv.$errors" :key="index">
                <small class="p-error">{{ error.$message }} </small>
              </span>
            </span>
            <small v-else-if="(v$.rut_dv.$invalid && submitted) || v$.rut_dv.$pending.$response" class="p-error">
              {{ v$.rut_dv.required.$message.replace('Value', 'RUT') }}</small>
          </div>

          <div class="col-md-4 field" style="margin-top: 15px;">
            <label for="giro">Tipo Empresa</label>
            <div class="card flex flex-row justify-content-center gap-3" style="height: 40px; margin-top: 2px;">
              <div v-for="tipo of tipoEmpresa" :key="tipo.key" class="flex align-items-center">
                <Checkbox v-model="selectedTipoEmpresas" :inputId="tipo.key" :value="tipo.key" class="me-2" @change="esProveedor(tipo.key)"/>
                <label :for="tipo.key" class="mt-2">{{ tipo.name }}</label>
              </div>    
            </div>                         
          </div>

          <div class="col-md-6 field" v-if="form.proveedor === 1">
            <label for="gasto" class="mt-3">Clasif.Gasto</label>
            <AutoComplete id="gasto"  name="gasto" v-model="form.gasto" :suggestions="filteredGastos" :dropdown="true"
              @complete="searchGasto($event)" field="_nombre" forceSelection autofocus autocomplete="off" />
          </div>          
        </div>

        <div class="row">  
          <div class="col-md-8 field">
            <label for="razon_social" class="mt-3">Razón Social</label>
            <InputText id="razon_social" v-model="v$.razon_social.$model" :class="{ 'p-invalid': v$.razon_social.$invalid && submitted }"
              aria-describedby="razon_social-error" autofocus autocomplete="off" />
            <span v-if="v$.razon_social.$error && submitted">
              <span id="razon_social-error" v-for="(error, index) of v$.razon_social.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.razon_social.$invalid && submitted) || v$.razon_social.$pending.$response" class="p-error">
              {{ v$.razon_social.required.$message.replace('Value', 'Razón Social') }}</small>
          </div>

          <div class="col-md-4 field">
            <label for="giro" class="mt-3">Giro Comercial</label>
            <InputText id="giro" v-model.trim="form.giro" />
          </div>
        </div>

        <div class="row">  
          <div class="col-md-4 field">
            <label for="direccion" class="mt-3">Dirección</label>
            <InputText id="direccion" v-model="v$.direccion.$model" :class="{ 'p-invalid': v$.direccion.$invalid && submitted }"
              aria-describedby="direccion-error" autofocus autocomplete="off" />
            <span v-if="v$.direccion.$error && submitted">
              <span id="direccion-error" v-for="(error, index) of v$.direccion.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.direccion.$invalid && submitted) || v$.direccion.$pending.$response" class="p-error">
              {{ v$.direccion.required.$message.replace('Value', 'Dirección') }}</small>
          </div>

          <div class="col-md-4 field">
            <label for="ciudad" class="mt-3">Ciudad</label>
            <AutoComplete id="ciudad"  name="ciudad" v-model="v$.ciudad.$model"
              :class="{ 'p-invalid': v$.ciudad.$invalid && submitted }" :suggestions="filteredCiudades"
              @complete="searchCiudad($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="ciudad-error" autofocus autocomplete="off" />
            <span v-if="v$.ciudad.$error && submitted">
              <span id="ciudad-error" v-for="(error, index) of v$.ciudad.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.ciudad.$invalid && submitted) || v$.ciudad.$pending.$response)"
              class="p-error">
              {{ v$.ciudad.required.$message.replace('Value', 'Rol') }}</small>
          </div>

          <div class="col-md-4 field">
            <label for="comuna" class="mt-3">Comuna</label>
            <AutoComplete id="comuna" name="comuna"
              :suggestions="filteredComunas" v-model.trim="form.comuna"
              @complete="searchComuna($event)" field="_nombre" :dropdown="true" forceSelection
              autofocus autocomplete="off" />
          </div> 
        </div>

        <div class="row">
          <div class="col-md-4 field" >
            <label for="email" class="mt-3">Email</label>
            <InputText id="email" v-model="v$.email.$model"
              :class="{ 'p-invalid': v$.email.$invalid && submitted }" aria-describedby="email-error"
              autocomplete="off" />
            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
              {{ v$.email.required.$message.replace('Value', 'Email') }}</small>
          </div>

          <div class="col-md-4 field">
            <label for="telefono" class="mt-3">Teléfono</label>
            <InputText id="telefono" v-model.trim="form.telefono" />
          </div>
          
          <div class="col-md-4 field">
            <label for="contacto" class="mt-3">Contacto</label>
            <InputText id="contacto" v-model.trim="form.contacto" />
          </div>
        </div>
        <!-- Gestion FSC de proveedores  v-if="form.proveedor === 1"-->
        <div class="row" v-if="form.proveedor === 1">
          <label for="fsc" class="mt-3">FSC</label>
          <div class="card p-fluid p-2">
            <ScrollPanel style="width: 100%; height: 230px" class="custombar1">
              <!-- <div v-for="(fsc) in fscs" :key="fsc.id" class="row"> -->
              <div v-for="(fsc, index) in fscs" :key="fsc.id" class="row">
                <div class="col-md-3 field">
                  <label>{{ fsc.nombre }}</label>
                </div>
                <div class="col-md-4 field">
                  <!-- <InputText v-model="form.fscs[fsc.id]" :id="`codigo-${fsc.id}`" name="codigo" class="mb-2" :readonly="disabledCodigo[fsc.id]"/> -->
                  <InputText v-if="form.fscs !== null" v-model="form.fscs[index].codigo" @focus="selAllText"/>
                  <InputText v-else v-model="fscs[index].codigo" @focus="selAllText"/>
                </div>
              </div>
            </ScrollPanel>
          </div>          
        </div>

        <div class="row">
          <div class="col-md-8 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <InputText id="observacion" v-model.trim="form.observacion" style="height: 80px;" />
          </div>          
          <div class="col-md-4 field">
            <label for="vigente" class="mt-3">Estado</label><br>
            <ToggleButton id="vigente" v-model="v$.vigente.$model"
              :class="{ 'p-invalid': v$.vigente.$invalid && submitted }" onLabel="ACTIVO" offLabel="INACTIVO"
              onIcon="pi pi-check" offIcon="pi pi-times" aria-describedby="vigente-error" autofocus
              style="width: 100%" />
            <span v-if="v$.vigente.$error && submitted">
              <span id="vigente-error" v-for="(error, index) of v$.vigente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.vigente.$invalid && submitted) || v$.vigente.$pending.$response" class="p-error">
              {{ v$.vigente.required.$message.replace('Value', 'Estado') }}</small>
          </div>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveEmpresa" />
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un empresa -->
    <Dialog v-model:visible="deleteEmpresaDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="empresa"> ¿Está seguro de borrar el empresa?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEmpresaDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteEmpresa" />
      </template>
    </Dialog>

    <!-- Confirmar elimina masivamente empresas -->
    <Dialog v-model:visible="deleteEmpresasDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="selectedEmpresas"> ¿Está seguro de borrar los empresas seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEmpresasDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteSelectedEmpresas" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useEmpresas from '../../composables/cruds/empresas'
import { reactive, onMounted } from 'vue'

import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      empresa,
      empresas,
      ciudades,
      gastos,
      comunas,
      fscs,
      disabledCodigo,
      dt,
      dt_fsc, 
      loading, 
      saving,
      filters,
      selectedEmpresas,
      selectedFscs,
      selectedCodigoFscs,
      deleteEmpresasDialog,
      deleteEmpresaDialog,
      empresaDialog,
      submitted,
      filteredCiudades,
      filteredGastos,
      filteredComunas,
      tipoEmpresa,
      fscEmpresa,
      selectedTipoEmpresas,
      empresaTipo,
      storeEmpresa,      
      deleteSelectedEmpresas,
      getCRUD,
      deleteEmpresa
    } = useEmpresas()

    const form = reactive({
      rut_dv: '',
      tipo: '',
      razon_social: '',
      giro: '',
      direccion: '',
      gasto: '',
      ciudad: '',
      comuna: '',
      telefono: '',
      email: '',
      contacto: '',
      fscs: '',
      //disabledCodigo: '',
      observacion: '',
      vigente: '',
      proveedor: '',
    })

    const rut_format = helpers.regex(/^(\d{1,3}(?:\d{1,3}){2}-[\dkK])$/)
    
    const rules = {
      rut_dv: {
        required: helpers.withMessage('El campo es requerido o no corresponde a la empresa', required),
        rut_rule: helpers.withMessage('Ingrese un rut válido. Ej: 12345678-0', rut_format),
      },
      razon_social: { required: helpers.withMessage('El campo es requerido', required), },
      direccion: { required: helpers.withMessage('El campo es requerido', required), },
      email: {
        required: helpers.withMessage('El campo es requerido!', required),
        email: helpers.withMessage('Ingrese un email válido. Ej: buzon@transcon.com', email),
      },
      ciudad: { required: helpers.withMessage('El campo es requerido', required) },
      vigente: { required: helpers.withMessage('El campo es requerido', required) }
    }

    const exportCSV = () => {
      // console.info(dt.value)
      dt.value.exportCSV()
    }

    const confirmDeleteSelected = () => {
      deleteEmpresasDialog.value = true
    }

    const confirmDeleteEmpresa = (emp) => {
      empresa.value = emp
      deleteEmpresaDialog.value = true
    }

    const hideDialog = () => {
      empresaDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const esProveedor = (event) => {
      form.proveedor = event === 'P' && form.proveedor === 0 ? 1 : 0
    }

    const setFilter = () => {
      getCRUD()
    }

    const enabledCodigo = (idx) => {
      let selection = JSON.parse(JSON.stringify(selectedFscs.value[idx]))
      if(selection.length === 0){ // se desmarco
        disabledCodigo.value[idx] = true
        selectedCodigoFscs.value[idx]= ''
      } else{
        disabledCodigo.value[idx] = false        
      }     
    }

    const searchCiudad = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredCiudades.value = [...ciudades.value]
        }
        else {
          filteredCiudades.value = ciudades.value.filter((ciudad) => {
            return ciudad._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchGasto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredGastos.value = [...gastos.value]
        }
        else {
          filteredGastos.value = gastos.value.filter((gasto) => {
            return gasto._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchComuna = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredComunas.value = [...comunas.value]
        }
        else {
          filteredComunas.value = comunas.value.filter((comuna) => {
            return comuna._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }
    
    const openNew = () => {
      empresa.value = {}
      submitted.value = false
      empresaDialog.value = true
      saving.value = false

      selectedTipoEmpresas.value = []
      selectedFscs.value = []
      selectedCodigoFscs.value = []

      form.rut_dv = ''
      form.tipo = ''      
      form.razon_social = ''
      form.giro = ''
      form.direccion = ''
      form.ciudad = ''
      form.gasto = ''
      form.comuna = ''
      form.telefono = ''
      form.email = ''
      form.contacto = ''
      form.observacion = ''
      form.fscs = null
      // form.disabledCodigo = ''
      form.vigente = true
      form.proveedor = 0

      form.created_at = ''
      form.created_id = ''
      form.updated_at = ''
      form.updated_id = ''         
    }

    const editEmpresa = (emp) => {
      // console.info('fscs', fscs)
      empresa.value = { ...emp }
      empresaDialog.value = true

      selectedTipoEmpresas.value = []
      selectedFscs.value = []
      selectedCodigoFscs.value = []
      
      if(empresa.value.cliente === 1) selectedTipoEmpresas.value.push("C")
      if(empresa.value.proveedor === 1) selectedTipoEmpresas.value.push("P")
      if(empresa.value.transportista === 1) selectedTipoEmpresas.value.push("T")

      form.rut_dv = empresa.value.rut_dv
      form.tipo = selectedTipoEmpresas
      form.razon_social = empresa.value.razon_social
      form.giro = empresa.value.giro
      form.direccion = empresa.value.direccion
      form.ciudad = empresa.value.ciudad
      form.gasto = empresa.value.gasto
      form.comuna = empresa.value.comuna
      form.telefono = empresa.value.telefono      
      form.email = empresa.value.email
      form.contacto = empresa.value.contacto
      form.observacion = empresa.value.observacion
      form.vigente = empresa.value.vigente
      form.proveedor = empresa.value.proveedor
      form.fscs = empresa.value.fscs
      
      form.created_at = empresa.value.created_at
      form.created_id = empresa.value.created_id
      form.updated_at = empresa.value.updated_at
      form.updated_id = empresa.value.updated_id
    }    

    const saveEmpresa = () => {
      let i = 0
      let selection = JSON.parse(JSON.stringify(selectedFscs.value))
      let codSelection = JSON.parse(JSON.stringify(selectedCodigoFscs.value))
      
      $.each(selection, function(key,sel) {        
        if(sel !== null) {
          sel[0].codigo = codSelection[i] === undefined ? null : codSelection[i]
        }
        i++
      })

      // selectedFscs.value = selection
      submitted.value = true
        if(!v$._value.$invalid)
          if(selectedTipoEmpresas.value.length > 0){
            form.tipo = selectedTipoEmpresas
            if(form.fscs === null && form.proveedor === 1) form.fscs = fscs
            storeEmpresa(form)  
          }
            // console.info('Seleccione al menos un tipo de empresa')    
    }

    const selAllText = (event) => {
      event.target.setSelectionRange(0, event.srcElement._value.length)
    }

    const v$ = useVuelidate(rules, form)

    onMounted(getCRUD)

    return {
      dt, 
      dt_fsc,
      loading,
      saving,
      empresa,
      empresas,
      ciudades,
      gastos,
      comunas,
      fscs,
      disabledCodigo,
      filters,
      selectedEmpresas,
      selectedFscs,
      selectedCodigoFscs,
      deleteEmpresasDialog,
      deleteEmpresaDialog,
      empresaDialog,
      submitted,
      filteredCiudades,
      filteredGastos,
      filteredComunas,
      v$,
      form,
      tipoEmpresa,
      fscEmpresa,
      selectedTipoEmpresas,  
      empresaTipo,
      esProveedor,
      setFilter,
      hideDialog,
      enabledCodigo,
      openNew,
      confirmDeleteEmpresa,
      deleteSelectedEmpresas,
      deleteEmpresa,
      exportCSV,
      confirmDeleteSelected,
      searchCiudad,
      searchComuna,
      searchGasto,
      editEmpresa,
      saveEmpresa,
      storeEmpresa,
      selAllText
    }
  },
}
</script>