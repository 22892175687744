import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useDespachadores() {
  const despachador = ref([])
  const despachadores = ref([])
  const despachadoresDelete = ref([])

  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedDespachadores = ref()
  const deleteDespachadoresDialog = ref(false)
  const deleteDespachadorDialog = ref(false)
  const despachadorDialog = ref(false)
  const submitted = ref(false)

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/despachadores/all/crud`).then(response => {
      despachadores.value = response.data.data.despachadores
      loading.value = false
    })
  }

  const storeDespachador = async (form) => {
    saving.value = true
    let id = despachador.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/despachadores/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          despachadorDialog.value = false
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/despachadores`, { 'data': { ...form } })
        .then(() => {
          despachadorDialog.value = client.dialog.value
          despachador.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteDespachador = async () => {
    client.remove(`/api/despachadores/${despachador.value.id}`)
      .then(response => {
        despachadores.value = despachadores.value.filter(val => val.id !== despachador.value.id)
        deleteDespachadorDialog.value = false
        despachador.value = {}
      })
  }

  const deleteSelectedDespachadores = async () => {
    despachadoresDelete.value = selectedDespachadores.value.map(({ id }) => id)
    client.remove(`/api/despachadores/destroy/mass`, { data: despachadoresDelete.value })
      .then(response => {
        despachadores.value = despachadores.value.filter(
          (val) => !selectedDespachadores.value.includes(val)
        )
        deleteDespachadoresDialog.value = false
        selectedDespachadores.value = null
        despachadoresDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    despachador,
    despachadores,
    dt,
    loading,
    saving,
    filters,
    selectedDespachadores,
    deleteDespachadoresDialog,
    deleteDespachadorDialog,
    despachadorDialog,
    submitted,
    deleteSelectedDespachadores,
    getCRUD,
    storeDespachador,
    deleteDespachador
  }
}