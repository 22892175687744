import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import useClient from '../client/apiClient'

export default function useRpaCompras() {
  const comprasSII = ref([])

  const router = useRouter()
  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const getCompraSII = async (periodo,reload) => {
    loading.value = true
    let f5 = reload ?? 0
    client.get(`/api/sii/${periodo}/${f5}/compra`).then(response => {
      comprasSII.value = response.data.data.compras
      loading.value = false
    })
    .catch(function (error) { 
      loading.value = false
    })    
  }

  const getPDFSII = async (d) => {
    loading.value = true
    if(d.folio !== undefined)
      axios.get(`/api/sii/${d.codigo_sii}/${d.folio}/R/${d.rut_proveedor}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }
  
  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'tipo_doc': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'fecha_docto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'rut_proveedor': { value: null, matchMode: FilterMatchMode.EQUALS },
    'razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'monto_iva_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_exento': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_total': { value: null, matchMode: FilterMatchMode.EQUALS },
    'codigo_iva_no_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva_no_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_uso_comun': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_no_retenido': { value: null, matchMode: FilterMatchMode.EQUALS },
    'impuesto_sin_credito': { value: null, matchMode: FilterMatchMode.EQUALS },
    'cod_otro_impuesto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'tasa_otro_impuesto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'valor_otro_impuesto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva_activo_fijo': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto_activo_fijo': { value: null, matchMode: FilterMatchMode.EQUALS },
  })

  return {
    router,
    comprasSII,
    dt,
    loading,
    saving,
    filters,
    getCompraSII,
    getPDFSII
  }
}