import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'
import { useRouter } from 'vue-router'

export default function useRecepcionesPago() {
  const recepcionesSII = ref([])
  const messages = ref([])
  const proveedores = ref([])
  const proveedor = ref(null)
  const client = useClient()
  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)
  const deletePagoDialog = ref({visible:false, index:null, id: null})
  const submitted = ref(false)
  const router = useRouter()

  const pagoDialog = ref(false)
  const recepcion = ref([])
  const tpagos = ref([])
  const docsii = ref([])
  const pagos = ref([])
  const filteredTipoPagos = ref()
  const modalidades = ref(['TRANSFERENCIA', 'EFECTIVO', 'CHEQUE', 'TARJETA DEBITO', 'TARJETA CRÉDITO', 'SALDO NOTA CRÉDITO/DÉBITO'])
  const filteredModalidades = ref()
  const estados = ref(['PENDIENTE','PAGADO','VENCIDO'])
  const filteredEstados = ref()
  const lineas = ref([])
  const selectedRecepciones = ref()
  const fromCompras = ref(false)
  const selectedNotas = ref()

  const getRecepcionSII = async (rut,fdesde, fhasta, edo) => {
    loading.value = true

    client.get(`/api/recepciones/${rut}/${fdesde}/${fhasta}/${edo}/fcsii`).then(response => {
      recepcionesSII.value = response.data.data.recepciones
      docsii.value = response.data.data.docsii
      tpagos.value = response.data.data.tpagos

      if(fromCompras.value === true) {
        proveedores.value = response.data.data.empresas
        proveedor.value = response.data.data.empresas[0]
        loading.value = false 
      }
      // let notas
      response.data.data.recepciones.map((recepcion, idx) => {
        let notasdc = response.data.data.notas.filter(
          (nota) => {
            if('55 56 111 60 61 112'.match(nota.codigo_sii) && nota.rut_proveedor === recepcion.rut_proveedor) {
              return nota
            }
          }
        )
        recepcionesSII.value[idx] = {notasdc: notasdc, ...recepcion}
      })
      
      loading.value = false
    })
  }  

  const storePagos = async (form,proveedor,fdesde, fhasta) => {
    // console.info('form: ', form,proveedor.value.rut_dv, proveedor.value.id,fdesde, fhasta)
    saving.value = true
    client.post(`/api/recepciones/${proveedor.value.id}/pagos`, { 'data': { ...form } })
      .then(() => {
        saving.value = false
        getRecepcionSII(proveedor.value.rut_dv, fdesde, fhasta, 0)
        pagoDialog.value = false
      })
  }

  const getProveedores = async (fdesde, fhasta) => {
    loading.value = true
    client.get(`/api/empresas/prov/${fdesde}/${fhasta}`).then(response => {
      proveedores.value = response.data.data.empresas
      loading.value = false

    })
  }

  const getProveedor = async () => {
    let rut = `${router.currentRoute.value.params.rut}`
    loading.value = true
    client.get(`/api/empresas/${rut}/byrut`).then(response => {
      proveedores.value = response.data.data.empresas
      proveedor.value = response.data.data.empresas[0]
      console.info('2: ',proveedor.value, response.data.data.empresas[0])
    })
    loading.value = false
  }

  const getPDFSII = async (d) => {
    loading.value = true
    if(d.folio_doc_recep !== undefined)
      axios.get(`/api/sii/${d.codigo_sii}/${d.folio_doc_recep}/R/${d.rut_proveedor}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }

  const deletePago = async () => {
    console.info('deletePagoDialog: ', deletePagoDialog)
    client.remove(`/api/recepciones/${deletePagoDialog.value.id}/delpago`)
      .then(response => {
        let row = {
          'id': null,
          'modalidad': null,
          'referencia': 0,
          'porc_abono': 0,
          'monto_abono': null,
          'fecha_vence': null,
          'fecha_pago': null,
          'cuota': '',
          'entidad': '',
          'observacion': '',
          'estado': ''
        }
        lineas.value[deletePagoDialog.value.index] = row
        lineas.value.splice(deletePagoDialog.value.index, 1)
        if (lineas.value.length === 0) lineas.value.pop()
        messages.value = []
        deletePagoDialog.value.visible = false
      })
  }  
  
  const informePagos = async(rut,fdesde, fhasta, edo) => {
    loading.value = true
    axios.get(`/api/informes/${rut}/${fdesde}/${fhasta}/${edo}/rpagos`, { responseType: 'arraybuffer' }).then(response => {
      if(response.status === 200){
        let blob = new Blob([response.data], { type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `InformeRecePagos.xls`
        link.click()
      }
    })
    loading.value = false
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const filtersProv = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    recepcionesSII,
    dt,
    loading,
    saving,
    submitted,
    filters,
    filtersProv,
    pagoDialog,
    recepcion,
    lineas,
    tpagos,
    filteredTipoPagos,
    messages,
    modalidades,
    estados,
    filteredEstados,
    filteredModalidades,
    pagos,
    proveedores,
    docsii,
    selectedRecepciones,
    getRecepcionSII,
    storePagos,
    getProveedores,
    getPDFSII,
    deletePago,
    deletePagoDialog, 
    fromCompras,
    router,
    proveedor,
    getProveedor,
    informePagos,
    selectedNotas
  }
}
