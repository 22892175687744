<template>
  <Toast/>
  <div class="card-header row">
    <div class="col-md-3">
      <h4>Gastos - Estado de Pagos </h4>
      <div class="line_title" style="width: 20%;"></div>
    </div>
    <div class="col-md-2">
      <label for="proveedor">Proveedor:</label>
      <AutoComplete id="proveedor" name="proveedor" v-model.trim="proveedor"
        :suggestions="filteredProveedores" field="_razon_social"
        @complete="searchProveedor($event)" :dropdown="true"
        autofocus autocomplete="off"/>
    </div>
    <div class="col-md-2">
      <label for="fdesde">Desde:&nbsp;</label>
      <Calendar
        id="fdesde"
        aria-describedby="fdesde-error"
        v-model="fdesde"
        inputClass="bg-input"
        autocomplete="off"
        dateFormat="dd-mm-yy"
        :showIcon="true"
      />&nbsp;
    </div>
    <div class="col-md-2">
      <label for="fhasta">Hasta:&nbsp;</label>
      <Calendar
        id="fhasta"
        aria-describedby="fhasta-error"
        v-model="fhasta"
        inputClass="bg-input"
        autocomplete="off"
        dateFormat="dd-mm-yy"
        :showIcon="true"
      />&nbsp;
    </div>
    <div class="col-md-1">
      <label for="estado">Estado:</label>
      <AutoComplete id="estado" name="estado" v-model.trim="estado" :suggestions="filteredEstados"
                    @complete="searchEstado($event)" dropdown/>
    </div>
    <div style="text-align: left" class="col-md-1"><br>
      <Button icon="pi pi-search" class="p-button-primary" @click="buscarCompras" outlined :disabled="(fdesde === null || fhasta === null)"/>
    </div>
    <div style="text-align: right" class="col-md-1"><br>
      <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
    </div>
  </div>
  <div class="card-body">
    <div>
      <!-- DT Compras SII -->
      <DataTable ref="dt" :value="comprasSII" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort :globalFilterFields="['tipo_doc','tipo_venta','fecha_docto', 'fecha_recepcion','folio', 'rut_proveedor', 'razon_social','monto_exento','monto_iva_no_recupera','monto_iva_recupera','monto_neto','monto_total','estado']" sortMode="multiple" dataKey="id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        responsiveLayout="scroll" breakpoint="990px">
        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Buscar..."/>
                </span>
              </div>
            </template>
          </Toolbar>
        </template>

        <Column field="tipo_doc" header="T.Doc." sortable>
          <template #body="slotProps">
            <Tag :value="getTipoDocumento(slotProps.data.tipo_doc)" severity="info" v-if="/33|39/.test(slotProps.data.tipo_doc)" />
            <Tag :value="getTipoDocumento(slotProps.data.tipo_doc)" severity="warning" v-else />
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="tipo_venta" header="T.Venta" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.tipo_venta }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="fecha_docto" header="F.Docto" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatFecha(slotProps.data.fecha_docto) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="fecha_recepcion" header="F.Recep." style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatFecha(slotProps.data.fecha_recepcion) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="folio" header="Folio" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="rut_proveedor" header="RUT Proveedor" style="white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.rut_proveedor }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="razon_social" header="Razon Social" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="monto_exento" header="Exento" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.monto_exento) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="monto_iva_no_recupera" header="IVA.NR" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.monto_iva_no_recupera) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="monto_iva_recupera" header="IVA" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.monto_iva_recupera) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="monto_neto" header="Neto" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.monto_neto) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="monto_total" header="Total" style="text-align:right" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.monto_total) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="cestado" header="Estado" :exportable="true" :hidden="true">
          <template #body="slotProps">
            <span>{{ slotProps.data.estado ?? '' }}</span>
          </template>
        </Column>

        <Column field="estado" header="Estado" :exportable="false" style="white-space: nowrap;text-align:center" sortable>
          <template #body="slotProps">
            <Tag severity="success" v-if="slotProps.data.estado === 'PAGADA'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="warning" v-if="slotProps.data.estado === 'PENDIENTE'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag severity="danger" v-if="slotProps.data.estado === 'VENCIDA'" rounded><h5 style="text-aling-center;">
              {{ slotProps.data.estado }}</h5></Tag>
            <Tag style="background-color: #f1f5f9;color: #475569;border: 1px solid #475569;" v-if="slotProps.data.estado === null" rounded><h5 style="text-aling-center;">?</h5>
            </Tag>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column header="Acciones" :exportable="false" style="white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" @click="getPDFSII(slotProps.data)" title="Ver Doc" />&nbsp;
            <Button icon="pi pi-wallet" class="p-button-rounded p-button-primary" @click="setPagos(slotProps.data)" title="Registrar Pagos" v-if="slotProps.data.proveedor_id === '0'"/>
            <Button icon="pi pi-box" class="p-button-rounded p-button-success" @click="confirmGoRecepcion(slotProps.data)" title="Ir a Recepciones Pago" v-else/>
          </template>
        </Column>

      </DataTable>
    </div>

    <!-- Formulario Despachos en modal -->
    <Dialog v-model:visible="pagoDialog" header="Datos del Gasto" :modal="true" class="p-fluid" :maximizable="false" position="topleft">
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-6 field">
            <h4>Proveedor</h4>
            <span>{{ form.compra.rut_proveedor }} {{ form.compra.razon_social }}</span>
          </div>
          <div class="col-md-2 field">
            <h4>Folio</h4>
            <span>N°: {{ form.compra.folio }}</span>
          </div>
          <div class="col-md-2 field">
            <h4>Fecha</h4>
            <span>{{ formatFecha(form.compra.fecha_docto) }}</span>
          </div>
          <div class="col-md-2 field">
            <h4>Monto Total</h4>
            <span style="text-aling-right">{{ formatCurrency(form.compra.monto_total) }}</span>
          </div>
          <div class="col-md-2 field mt-3" style="text-aling-right">
            <h4>Tipo de Pago</h4>
            <AutoComplete id="tipo_pago" v-model="v$.tipo_pago.$model" v-if="form.compra.tpago === null"
              :class="{ 'p-invalid': v$.tipo_pago.$invalid && submitted }" :suggestions="filteredTipoPagos"
              @complete="searchTPago($event)" field="nombre" :dropdown="true" forceSelection
              aria-describedby="tipo_pago-error" autofocus autocomplete="off"
              @change="setTipoPago($event)"/>
            <span v-if="v$.tipo_pago.$error && submitted">
              <span id="tipo_pago-error" v-for="(error, index) of v$.tipo_pago.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.tipo_pago.$invalid && submitted) || v$.tipo_pago.$pending.$response)" class="p-error">
              {{ v$.tipo_pago.required.$message.replace('Value', 'Tipo Pago') }}</small>
            <Tag severity="info" rounded v-if="form.compra.tpago !== null">
              <h5 style="text-aling-center;"> {{form.compra.tpago.nombre.toUpperCase()}}</h5>
            </Tag>
          </div>
          <div class="col-md-8 field" style="text-aling-right"></div>
          <div class="col-md-2 field mt-3" style="text-aling-right" id="dummy">
            <h4>Estado</h4>
            <Tag severity="success" v-if="form.compra.estado === 'PAGADA'" rounded><h5 style="text-aling-center;">
              {{ form.compra.estado }}</h5></Tag>
            <Tag severity="warning" v-if="form.compra.estado === 'PENDIENTE'" rounded><h5 style="text-aling-center;">
              {{ form.compra.estado }}</h5></Tag>
            <Tag severity="danger" v-if="form.compra.estado === 'VENCIDA'" rounded><h5 style="text-aling-center;">
              {{ form.compra.estado }}</h5></Tag>
            <Tag severity="info" v-if="form.compra.estado === null" rounded><h5 style="text-aling-center;">?</h5></Tag>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-10 field">
            <Divider align="left" type="solid">
              <b class="text-muted">Pagos</b>
            </Divider>
          </div>
          <div class="col-md-2 d-flex justify-content-end">
            <Button icon="pi pi-plus" class="p-button-rounded p-button" size="small" @click="addLinea()" />
            <!-- v-if="(abono === 2 && (totalAbonos < form.compra.monto_total))" -->
          </div>
          <div class="col-md-12 field">
            <InlineMessage v-for="msg of messages" :key="msg.id" severity="warn">{{ msg.content }}</InlineMessage>
          </div>
        </div>
        <div class="card p-fluid table_modal">
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px" editMode="cell" @cell-edit-complete="onCellEditComplete" tableClass="editable-cells-table" tableStyle="min-width: 50%">            
            <Column field="id" header="ID" style="max-width: 14%" :hidden="true">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>
            <Column style="width: 1%;max-width: 1%; text-align: center">
              <template #body="{ data, index }">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" size="small" @click="toggleDelete(index)" v-if="data['id'] === null"/>
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePago(data['id'])" v-else/>
              </template>
            </Column>            
            <Column field="cuota" header="Cuota" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>     
            <Column field="monto_abono" header="Monto Abono" style="max-width: 14%; text-align: right;">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" @click="onCellEditInit"/>
              </template>
            </Column>  
            <Column field="porc_abono" header="% Abono" style="max-width: 14%; text-align: right">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>                             
            <Column field="modalidad" header="Modalidad" style="max-width: 14%">
              <template #editor="{ data, field, index }">
                <AutoComplete v-model="data[field]" :optionValue="data[field]" :suggestions="filteredModalidades" @complete="searchModalidad($event)" @change="selectNota($event, index)"/>
              </template>
            </Column>
            <Column field="referencia" header="Referencia" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]"/>
              </template>
            </Column>

            <Column field="entidad" header="Banco / Caja" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>

            <Column field="fecha_vence" header="Fecha Vencimiento" style="max-width: 14%">
              <template #editor="{ data, field }">
                <Calendar v-model="data[field]" :showIcon="false" dateFormat="dd-mm-yy"/>
              </template>
            </Column>
            <Column field="fecha_pago" header="Fecha Pago" style="max-width: 14%">
              <template #editor="{ data, field }">
                <Calendar v-model="data[field]" :showIcon="false" dateFormat="dd-mm-yy"/>
              </template>
            </Column>
            <Column field="observacion" header="Observación" style="max-width: 14%">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" style="max-width: 14%">
              <template #editor="{ data, field }"> <!--   -->
                <AutoComplete v-model="data[field]" :optionValue="data[field]" :suggestions="filteredEstados" @complete="searchEstado($event)" dropdown @change="dummyClick"/>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="TOTALES" :colspan="form.compra.tpago_id === 2 ? 3 : 3" footerStyle="text-align:left; background-color:#333;color:#FFF;"/>
                <Column footer="FACTURADO" footerStyle="text-align:right; background-color:#17A2B8;"/>
                <Column :footer="formatCurrency(form.compra.monto_total)" footerStyle="text-align:right; background-color:#17A2B8;"/>
                <Column footer="ABONADO" footerStyle="text-align:right; background-color:#90BD76;"/>
                <Column :footer="formatCurrency(totalPagado)" footerStyle="text-align:right; background-color:#90BD76;"/>
                <Column footer="PENDIENTE" footerStyle="text-align:right; background-color:#FFC300;"/>
                <Column :footer="formatCurrency(totalPendiente)" footerStyle="text-align:right; background-color:#FFC300;"/>
                <Column footer="VENCIDO" footerStyle="text-align:right; background-color:#F06779;"/>
                <Column :footer="formatCurrency(totalVencido)" footerStyle="text-align:right; background-color:#F06779;"/>                
              </Row>
            </ColumnGroup>            
          </DataTable>
        </div>
      </form>
      <template #footer>
        <div class="row">
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog"/>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePago"/>
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- indica que la compra es recepción -->
    <Dialog v-model:visible="goRecepcionDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span> El registro seleccionado corresponde a Recepción. ¿Desea gestionar el pago en el módulo de recepciones?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="goRecepcionDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="goPagosRecep()" />
      </template>
    </Dialog> 

    <!-- Confirmar elimina un pago ya almacenado -->
    <Dialog v-model:visible="deletePagoDialog.visible" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span> ¿Está seguro de borrar el pago?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePagoDialog.visible = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="pagoDelete(id)" />
      </template>
    </Dialog>

    <!-- Para seleccionar la Nota CD -->
    <Dialog v-model:visible="dialogNDC.visible" style="width: 25rem !important'" header="Notas de Crédito / Débito" :modal="true">
      <div class="content">
        <i class="pi pi-exclamation-triangle mr-2 text-warning" style="font-size: 1rem; vertical-align: middle;" />
        <span>Seleccione la Nota de Crédito / Débito:<br/></span>
      </div>
      <!--  -->
      <DataTable ref="dtnc" :value="lrebaja" showGridlines stripedRows scrollable scrollHeight="400px" tableStyle="min-width: 50%" v-model:selection="selectedNotas" >
        <Column selectionMode="single" style="width: 3rem" :exportable="false"></Column>           
        <Column field="id" header="ID" style="max-width: 14%" :hidden="true">
          <template #editor="{ data, field }">
            <InputNumber v-model="data[field]"/>
          </template>
        </Column>          
        <Column field="folio" header="Folio">
          <template #editor="{ data, field }">
            <InputNumber v-model="data[field]"/>
          </template>
        </Column>          
        <Column field="monto_total" header="Monto">
          <template #editor="{ data, field }">
            <InputNumber v-model="data[field]"/>
          </template>
        </Column>
                  
      </DataTable>      
      <!--  -->
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="dialogNDC.visible = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="vinculaNDC()"/>
      </template>
    </Dialog>                
  </div>
</template>

<script>
import useComprasPago from "../../composables/gastos/cpagos"
import {reactive, ref, onMounted, computed, toRaw} from "vue"
import moment from "moment"
import useVuelidate from '@vuelidate/core'
import {required, helpers} from '@vuelidate/validators'

export default {
  setup() {
    const {
      comprasSII,
      dt,
      loading,
      saving,
      submitted,
      filters,
      pagoDialog,
      compra,
      tpagos,
      filteredTipoPagos,
      lineas,
      messages,
      modalidades,
      estados,
      filteredEstados,
      filteredModalidades,
      proveedores,
      getCompraSII,
      storePagos,
      getProveedores,
      getPDFSII,
      deletePagoDialog,
      router,
      deletePago,
      tipoDocumentos,
      selectedNotas
    } = useComprasPago()

    moment.locale('es')

    const filteredProveedores = ref()
    const fdesde = ref(moment().startOf('month').toDate())
    const fhasta = ref(moment().endOf('month').toDate())
    const proveedor = ref(null)
    const estado = ref(null)
    const goRecepcionDialog = ref(false)
    const dialogNDC = ref({visible:false, index:null, id: null})
    const lrebaja = ref([])
    const form = reactive({
      compra: null,
      compra_id: null,
      pagos: [],
      tipo_pago: null
    })
    
    const abono = ref(0)

    const rules = {
      tipo_pago: {required: helpers.withMessage('El campo es requerido', required),},
    }

    const searchModalidad = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredModalidades.value = [...modalidades.value]
        } else {
          filteredModalidades.value = modalidades.value.filter((modalidad) => {
            return modalidad.toUpperCase().startsWith(event.query.toUpperCase())
          })
        }
      }, 250)
    }

    const searchEstado = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredEstados.value = [...estados.value]
        } else {
          filteredEstados.value = estados.value.filter((estado) => {
            return estado.toUpperCase().startsWith(event.query.toUpperCase())
          })    
        }
      }, 250)
    }

    const formatFecha = (value, format = 'YY') => {
      return moment(value).format("DD-MM-"+format)
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL', {style: 'currency', currency: 'CLP'}).format(value)
    }

    const setPagos = (compra) => {
      lineas.value = []
      form.compra = {...compra}
      form.tipo_pago = compra.tpago
      abono.value = compra.tpago ? compra.tpago.id : 0

      compra.compras_pagos.map((ln) => {
        lineas.value.push({
          'id': ln.id,
          'modalidad': ln.modalidad,
          'referencia': ln.referencia,
          'porc_abono': parseInt(Math.round(ln.porc_abono)),
          'monto_abono': ln.monto_abono,
          'fecha_vence': ln.fecha_vence ? moment(ln.fecha_vence,'YYYY-MM-DD').format('DD-MM-YYYY') : null,
          'fecha_pago': ln.fecha_pago ? moment(ln.fecha_pago,'YYYY-MM-DD').format('DD-MM-YYYY'): null,
          'cuota': ln.cuota,
          'entidad': ln.entidad,
          'observacion': ln.observacion,
          'estado': ln.estado
        })
      })
      messages.value = []
      pagoDialog.value = true
    }

    const hideDialog = () => {
      pagoDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const searchTPago = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoPagos.value = [...tpagos.value]
        } else {
          filteredTipoPagos.value = tpagos.value.filter((tpago) => {
            return tpago.nombre.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProveedor = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProveedores.value = [...proveedores.value]
        } else {
          filteredProveedores.value = proveedores.value.filter((proveedor) => {
            return proveedor._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const setTipoPago = (event) => {
      form.compra.tpago_id = event.value.id
      form.compra.tipo_pago = event.value.nombre
      while (lineas.value.length > 0) lineas.value.pop()
      addLinea()
    }

    const totalAbonos = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let pago of lineas.value) {
          if(pago.monto_abono !== null)
            total += pago.monto_abono
        }
      return total
    })

    const totalPagado = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let pago of lineas.value) {
          if(pago.monto_abono !== null && pago.estado === 'PAGADO')
            total += pago.monto_abono
        }
      return total
    })

    const totalPendiente = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let pago of lineas.value) {
          if(pago.monto_abono !== null && pago.estado === 'PENDIENTE')
            total += pago.monto_abono
        }
      return (total)
    })

    const totalVencido = computed(() => {
      let total = 0
      if(lineas.value !== undefined)
        for (let pago of lineas.value) {
          if(pago.monto_abono !== null && pago.estado === 'VENCIDO')
            total += pago.monto_abono
        }
      return total
    })

    const onCellEditComplete = (event) => {
      let {data, newValue, field} = event
      data[field] = newValue
      messages.value = []
      
      if (field.includes('fecha_vence')) {
        if(abono.value === 2) 
          if((moment(data['fecha_vence']).format("DD-MM-YYYY") < moment(new Date()).format("DD-MM-YYYY")) && data['fecha_pago'] === null)
            data['estado'] = 'VENCIDO'
          else if((moment(data['fecha_vence']).format("DD-MM-YYYY") > moment(new Date()).format("DD-MM-YYYY")) && data['fecha_pago'] === null)
            data['estado'] = 'PENDIENTE'        
          else if(data['fecha_pago'] !== null)
            data['estado'] = 'PAGADO'
      }
      
      if (field.includes('fecha_pago')) {
        if(abono.value === 2) 
          if(data['fecha_pago'] !== null)
            data['estado'] = 'PAGADO'
      }

      if (field.includes('fecha_')) {
        if(typeof newValue === 'string')
          data[field] = moment(data[field],'DD-MM-YYYY').format('DD-MM-YYYY')
        else if(typeof newValue === 'object'&& newValue !== null)
          data[field] = moment(newValue).format('DD-MM-YYYY')
        else
          data[field] = null
      }

      if (field.includes('monto_abono')) {
        data['monto_abono'] = Math.round(newValue)
        data['porc_abono'] = parseInt(Math.round((newValue * 100) / form.compra.monto_total))
      }
    }

    const onCellEditInit = (event) => {
      let {data, newValue, field} = event

      if (field.includes('monto_abono')) {
        data[field] = new Intl.NumberFormat({style: 'currency'}).format(Math.round(newValue))
      }
    }    

    const buscarCompras = () => {
      messages.value = []
      let rut = proveedor.value === undefined || proveedor.value === null ? '0' : proveedor.value.rut_dv
      let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))
      getCompraSII(rut, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'), edo)
    }

    const addLinea = () => {
      let row = {
        'id': null,
        'modalidad': '',
        'referencia': 0,
        'porc_abono': form.compra.tpago_id === 1 ? 100 : 0,
        'monto_abono': form.compra.tpago_id === 1 ? Math.round(form.compra.monto_total) : 0, 
        'fecha_vence': moment(new Date()).format("DD-MM-YYYY"),
        'fecha_pago': form.compra.tpago_id === 1 ? moment(new Date()).format("DD-MM-YYYY") : null,
        'cuota': form.compra.tpago_id === 1 ? 1 : null,
        'entidad': '',
        'observacion': '',
        'estado': form.compra.tpago_id === 1 ? 'PAGADO' : null
      }

      if (lineas.value.length === 0)
        lineas.value.push(row)
      else if (lineas.value[lineas.value.length - 1].modalidad !== '')
        lineas.value.push(row)
      else
        messages.value = [{
          content: 'Debe seleccionar la modalidad antes de agregar nueva línea',
          id: lineas.value.length
        }]
    }

    const pagoDelete = (id) => {
      let index = lineas.value.findIndex( pago => pago.id === id )      
      deletePago(index)
      lineas.value.splice(index, 1)
      buscarCompras()
    }

    const toggleDelete = (index) => {
      let row = {
        'id': null,
        'modalidad': '',
        'referencia': 0,
        'porc_abono': 0,
        'monto_abono': 0,
        'fecha_vence': null,
        'fecha_pago': null,
        'cuota': '',
        'entidad': '',
        'observacion': '',
        'estado': ''
      }
      lineas.value[index] = row
      lineas.value.splice(index, 1)
      if (lineas.value.length === 0) lineas.value.pop()
      messages.value = []
    }

    const confirmDeletePago = (id) => {
      deletePagoDialog.value.visible = true
      deletePagoDialog.value.id = id
    }

    const savePago = () => {
      submitted.value = true
      messages.value = []
      
      if (!v$._value.$invalid) {
        if(totalAbonos.value > form.compra.monto_total)
          messages.value = [{content: 'La suma de los abonos, no puede ser mayor al monto total del documento', id: lineas.value.length}] 
        
        if (lineas.value.length > 0) {
          //valida que esten modalidad / monto_abono / porc_abono para cada linea
          lineas.value.map((ln) => {
            if (ln.modalidad === '') messages.value = [{
              content: 'Debe seleccionar la modalidad del pago y el monto antes de guardar',
              id: lineas.value.length
            }]
          })
        } else
          messages.value = [{content: 'Debe seleccionar los datos del pago antes de guardar', id: lineas.value.length}]

        if (messages.value.length === 0) {
          let rut = proveedor.value === undefined || proveedor.value === null ? '0' : proveedor.value.rut_dv
          let edo = estado.value === undefined || estado.value === null ? '0' : (estado.value === 'PAGADO' ? 'PAGADA' : (estado.value === 'PENDIENTE' ? 'PENDIENTE' : 'VENCIDA'))          
          form.pagos = lineas
          form.compra_id = form.compra.id
          storePagos(form, rut, fdesde.value.toLocaleDateString('es-CL'), fhasta.value.toLocaleDateString('es-CL'), edo)
        }
      }
    }

    const confirmGoRecepcion = (data) => {
      goRecepcionDialog.value = true
      form.compra = {...data}
    }

    const goPagosRecep = async () => {    
      loading.value = true
      let rut = form.compra.rut_proveedor
      router.push({ name: "recepcion.pagos", params: { rut: rut, fdesde:fdesde.value.toLocaleDateString('es-CL'), fhasta: fhasta.value.toLocaleDateString('es-CL') }})   
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const dummyClick = () => {
      document.getElementById('dummy').click()
    }

    const selectNota = (event, index) => {
      if(event.value === 'SALDO NOTA CRÉDITO/DÉBITO'){
        lrebaja.value = []
        messages.value = []

        form.compra.notasdc.map((ln) => {
          if(ln.monto_total <= form.compra.monto_total && ln.rebajada === 0)
          lrebaja.value.push({
            'id': ln.id,
            'folio': ln.folio,
            'monto_total': ln.monto_total
          })
        }) 

        if(lrebaja.value.length > 0){
          dialogNDC.value.visible = true
          dialogNDC.value.index = index
        }else{
          toggleDelete(index)
          messages.value = [{content: 'No existen Notas Débito/Crédito para asociar', id: lineas.value.length}]
        }
      } 
    }

    const getTipoDocumento = (tdoc) => {
      return toRaw(tipoDocumentos.value.filter(function (itm) { return [tdoc].indexOf(itm.codigo_sii) > -1 }))[0].codigo              
    }

    const vinculaNDC = () => { 
      form.compra.notasdc.map((ln) => {
        if(ln.id === selectedNotas.value.id)
          ln.rebajada = 1
      })

      lineas.value[dialogNDC.value.index].referencia = 'NC '+selectedNotas.value.folio
      lineas.value[dialogNDC.value.index].monto_abono = selectedNotas.value.monto_total
      lineas.value[dialogNDC.value.index].estado = 'PAGADO'
      lineas.value[dialogNDC.value.index].porc_abono =  parseInt(Math.round((selectedNotas.value.monto_total * 100) / form.compra.monto_total))
      
      selectedNotas.value = []
      dialogNDC.value.visible = false
    }    

    const v$ = useVuelidate(rules, form)

    onMounted(getProveedores)

    return {
      dt,
      form,
      loading,
      saving,
      comprasSII,
      filters,
      fdesde,
      fhasta,
      proveedor,
      compra,
      lineas,
      pagoDialog,
      rules,
      tpagos,
      filteredTipoPagos,
      v$,
      submitted,
      messages,
      modalidades,
      estados,
      filteredEstados,
      filteredModalidades,
      filteredProveedores,
      estado,
      setTipoPago,
      formatCurrency,
      formatFecha,
      onCellEditComplete,
      onCellEditInit,
      searchTPago,
      setPagos,
      hideDialog,
      addLinea,
      searchEstado,
      searchModalidad,
      searchProveedor,
      toggleDelete,
      savePago,
      getProveedores,
      buscarCompras,
      exportCSV,
      abono,
      totalPagado,
      totalPendiente,
      totalVencido,
      totalAbonos,
      getPDFSII,
      dummyClick,
      goPagosRecep,
      goRecepcionDialog,
      confirmGoRecepcion,
      confirmDeletePago,
      deletePagoDialog,
      pagoDelete,
      getTipoDocumento,
      tipoDocumentos,
      selectNota,
      dialogNDC,
      lrebaja,
      selectedNotas,
      vinculaNDC
    }
  },
}
</script>
