import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'
import moment from "moment"

export default function useProdDias() {

  const prodDia = ref([])
  const prodDias = ref([])
  const printProd = ref([])
  const resumen = ref([])
  const messages = ref([])

  const client = useClient()

  const productos = ref([])
  const lineas = ref([])

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const deleteProdDiaDialog = ref(false)
  const prodDiaDialog = ref(false)
  const submitted = ref(false)

  const filteredProductos = ref()

  const columns = ref([
    { field: 'id', header: 'LnId.', hidden: true },
    { field: 'producto_obj', header: 'Cod.', hidden: true },
    { field: 'producto', header: 'Producto', hidden: false },
    { field: 'espesor', header: 'Espesor', hidden: false },
    { field: 'ancho', header: 'Ancho', hidden: false },
    { field: 'largo', header: 'Largo', hidden: false },
    { field: 'cantidad', header: 'Cantidad', hidden: false },
    { field: 'pulgadas', header: 'Pulgadas', hidden: false },
    { field: 'm3', header: 'M3', hidden: false },
    { field: 'glosa', header: 'Observación', hidden: false },
  ])

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/produccion/all/crud`).then(response => {
      resumen.value = response.data.data.resumen
      productos.value = response.data.data.productos
      loading.value = false
    })
    let date = moment(new Date()).format("DD-MM-YYYY")
    getProduccionDia(date)    
  }

  const getProduccionDia = async (fecha) => {
    loading.value = true
    client.get(`/api/produccion/${fecha}/fecha`).then(response => {
      prodDias.value = response.data.data.prodDias
      // let observ = ''
      lineas.value = []
      response.data.data.prodDias.map((ln) => {
        let umedida = 0
        $.each(ln.producto, function(key,val) {
          if("/pulgada|metro3|unidad|mm|mruma/".includes(key) && val === 1) umedida = key
        })       
        lineas.value. push({
          'id' : ln.id,
          'producto_obj' : {'id': ln.producto.id, 'nombre': ln.producto.nombre, 'umedida': umedida},
          'producto' : ln.producto.id+'-'+ln.producto.nombre,
          'espesor' : ln.espesor,
          'ancho' : ln.ancho,
          'largo' : ln.largo,
          'cantidad' : ln.cantidad,
          'pulgadas' : parseFloat(ln.pulgadas).toFixed(3),
          'm3' : parseFloat(ln.m3).toFixed(3),
          'glosa' : ln.glosa
        })
        // 
      })
      messages.value = lineas.value.length === 0 ? [{ content: 'No existen registros para la fecha seleccionada, pueda agregar haciendo click en +', id: lineas.value.length }] : []
      loading.value = false
    })
  }

  const storeProdDia = async (form) => {
    console.info(form)
    saving.value = true
    // data.fecha = fecha
    // data.observacion = glosa.value
    client.post(`/api/produccion`, { 'data': { ...form } })
      .then(() => {
        prodDiaDialog.value = client.dialog.value
        prodDia.value = client.entity.value
        saving.value = client.saving.value
        getProduccionDia(form.fecha) 
      })
  }

  const deleteProdDia = async () => {
    client.remove(`/api/produccion/${prodDia.value.id}`)
      .then(() => {
        prodDias.value = prodDias.value.filter(val => val.id !== prodDia.value.id)
        deleteProdDiaDialog.value = false
        prodDia.value = {}        
      })
  }

  const printProdDia = (fecha) => {    
    loading.value = true
    client.get(`/api/produccion/${fecha}/fecha`).then(response => {
      printProd.value = response.data.data.prodDias
      loading.value = false
    })    
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    prodDia,
    resumen,
    prodDias,
    printProd,
    productos,
    dt,
    loading,
    saving,
    filters,
    deleteProdDiaDialog,
    prodDiaDialog,
    submitted,
    filteredProductos,
    lineas,
    columns,
    messages,
    getCRUD,
    getProduccionDia,
    storeProdDia,
    deleteProdDia,
    printProdDia
  }
}