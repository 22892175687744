import { ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'

export default function useClient() {
  const toast = useToast()
  const router = useRouter()
  const loading = ref(false)
  const saving = ref(false)
  const dialog = ref(false)
  const entity = ref([])
  const method = ref('')

  const customAxios = axios.create({})

  customAxios.interceptors.response.use(
    res => {
      if (router.currentRoute.value.path !== '/home' && method.value !== 'get') {
        saving.value = false
        toast.add({
          severity: "success",
          summary: res.data.message,
          life: 5000,
        })
      }
      return res
    },

    err => {
      const statusCode = err.response.status
      let _error = err.response.data.data ? err.response.data.data : (err.response.data.byteLength ? 'No se encuentra el documento seleccionada intente de nuevo en 15 segundos' : '')

      if (statusCode !== 419) {
        if (router.currentRoute.value.path !== '/home') {
          if(_error.error === 'Unauthenticated.'){
            var cookies = document.cookie.split(";")
            for (var i = 0; i < cookies.length; i++) {
              var cookie = cookies[i];
              var eqPos = cookie.indexOf("=");
              var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie = name + "=;expires=Mon, 24 Oct 1977 00:00:00 GMT";
            }
            toast.add({
              severity: statusCode === 500 ? "error" : "warn",
              summary: _error.exception,
              detail: statusCode === 500 ? "Error al obtener los datos:\n" + _error.error : _error,
              // detail: _error.error,
              life: 10000,
            })      
            if (_error.message === 'Unauthenticated.' || _error.error === 'Unauthenticated.') window.location.href = window.location.origin + '/login'
          }else{
            toast.add({
              severity: statusCode === 500 ? "error" : "warn",
              summary: _error.exception,
              detail: statusCode === 500 ? "Error al obtener los datos:\n" + (_error.error ?? _error) : _error,
              // detail: _error.error,
              life: 10000,
            })
            if (_error.message === 'Unauthenticated.' || _error.error === 'Unauthenticated.') window.location.href = window.location.origin + '/login'
          }
        }else{
          toast.add({
            severity: statusCode === 500 ? "error" : "warn",
            summary: _error.exception,
            detail: statusCode === 500 ? "Error al obtener los datos:\n" + _error.error : _error,
            // detail: _error.error,
            life: 10000,
          }) 
          window.location.href = window.location.origin + '/login'       
        }

        loading.value = false
        saving.value = false
        dialog.value = true
      }
      if (statusCode === 419) {
        // Refresh session token
        // axios.get('/sanctum/csrf-token')
        // return axios(err.response.config)
        var cookies = document.cookie.split(";")

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }        
        window.location.href = window.location.origin + '/login'
      }
      return err.response
    }
  )

  const get = async (url, options) => {
    loading.value = true
    method.value = 'get'
    if(options!== undefined)
      return customAxios.get(url, { params: options.data })
      .then(response => {
        loading.value = false
        return response
      }) 
    else
      return customAxios.get(url)
      .then(response => {
        loading.value = false
        return response
      }) 
  }

  const put = async (url, options) => {
    saving.value = true
    dialog.value = options.dialog
    entity.value = options.entity
    method.value = 'put'
    return customAxios.put(url, { data: options.data })
      .then(response => {
        saving.value = true
        dialog.value = false
        entity.value = []
        return response
      })
  }

  const patch = async (url, options) => {
    saving.value = true
    return customAxios.patch(url, { data: options.data })
      .then(response => {
        toast.add({
          severity: "success",
          summary: response.data.message,
          life: 3000,
        })
        saving.value = false
        return response
      })
  }

  const post = async (url, options) => {
    saving.value = true
    dialog.value = options.dialog
    entity.value = options.entity
    method.value = 'post'    
    return customAxios.post(url, options.data, { headers: options.headers })
      .then(response => {
        saving.value = false
        dialog.value = false
        entity.value = []
        return response
      })
  }

  const remove = async (url, options) => {
    method.value = 'delete'    
    if (options !== undefined){
      return customAxios.delete(url, { data: options.data })
        .then(response => {
          return response
        })
    }else{
      return customAxios.delete(url)
        .then(response => {
          return response
        })
    }
  }

  return {
    loading,
    saving,
    dialog,
    entity,
    get,
    put,
    patch,
    post,
    remove
  }
}
