<template>
  <Toast />
  <div class="card-header">
    <h4>Informes de Producción</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-5 d-flex justify-content-start mt-4">
        <h4>Desde: &nbsp;</h4>
        <Calendar
          id="fdesde"
          aria-describedby="fdesde-error"
          v-model="fdesde"
          inputClass="bg-input"
          autocomplete="off"
          dateFormat="dd-mm-yy"
          :showIcon="true"
        />&nbsp;
      </div>          
      <div class="col-md-5 d-flex justify-content-start mt-4">
        <h4>Hasta: &nbsp;</h4>
        <Calendar
          id="fhasta"
          aria-describedby="fhasta-error"
          v-model="fhasta"
          inputClass="bg-input"
          autocomplete="off"
          dateFormat="dd-mm-yy"
          :showIcon="true"
        />&nbsp;
      </div>    
      <div class="col-md-2 d-flex justify-content-start mt-4">
        <Button type="button" label="Generar Informe" icon="pi pi-search" :loading="loading" severity="info" @click="genInforme"/>
      </div>
      <br>   
      <div class="col-md-12 field mt-4">
        <InlineMessage v-for="msg of messages" :key="msg.id" severity="info" style="width: 100%;">{{ msg.content }}</InlineMessage>
      </div>
    </div>                 
  </div>
</template>

<script>
import useInformes from '../../composables/informes/index'
import { ref } from 'vue'

export default {
  setup() {
    const {
      loading, 
      messages,     
      informeProduccion
    } = useInformes()

    const fdesde = ref(new Date())
    const fhasta = ref(new Date())

    const genInforme = () => {
      informeProduccion(fdesde.value, fhasta.value)
    }

    return {
      loading,
      messages,  
      fdesde,
      fhasta,
      genInforme
    }
  },
}
</script>