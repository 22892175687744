<template>
  <Toast />
  <div class="card-header">
    <h4>Documentos Emitidos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div>
      <!-- DT NotasDC -->
      <DataTable v-model:expandedRows="expandedRows" ref="dt" :value="facturas" :paginator="true" :rows="10" style="zoom: 0.80;" :loading="loading" removableSort
        :globalFilterFields="['cliente.rut_dv','cliente.razon_social','tipoDocumento.codigo', 'folio', 'fecha', 'neto', 'iva', 'total', 'debitoCreditoNotas']" sortMode="multiple" dataKey="id" filterDisplay="row" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" v-model:filters="filters"
        responsiveLayout="scroll" breakpoint="990px">        

        <template #header>
          <!-- DT Toolbar-->
          <Toolbar class="">
            <template #start>
              <label for="rango"><h4>Rango de Fechas:&nbsp;</h4></label>
              <Calendar id="rango" v-model="rango" dateFormat="dd/mm/y" selectionMode="range"
                autocomplete="cc-csc" :showIcon="true" :manualInput="false"
                :hideOnDateTimeSelect="true" />&nbsp;&nbsp;&nbsp;
              <Button icon="pi pi-search" class="p-button-primary" @click="getCRUD($event)"
                outlined :disabled="(rango.length < 2)"/>                            
            </template>   
            <template #end>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>              
            </template>                     
          </Toolbar>          
        </template>

        <Column expander/>
        <Column field="cliente.rut_dv" header="RUT" style="width:7%;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.cliente.rut_dv }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="cliente.razon_social" header="Cliente" style="width:28%;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.cliente.razon_social }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="tipoDocumento.codigo" header="Doc." style="width:2%;" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.tipoDocumento.codigo" severity="info" />
          </template>          
        </Column>         

        <Column field="folio" header="Folio"  style="width:5%;white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.folio }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="fecha" header="Fecha" style="width:8%;white-space: nowrap;" sortable>
          <template #body="slotProps">
            <span>
              {{ slotProps.data.fecha }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="neto" header="Mnt.Neto" style="width:10%;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.neto) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="iva" header="IVA" style="width:10%;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.iva) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>        

        <Column field="total" header="Mnt.Total" style="width:10%;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.total) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="debitoCreditoNotas" header="N.Crédito" style="width:10%;color:red;" sortable>
          <template #body="slotProps">
            <span>
              {{ formatCurrency(getSumNota(slotProps.data.debitoCreditoNotas, '5 19 23')) }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="" />
          </template>           
        </Column>

        <Column field="creditoDebitoNotas" header="N.Débito" style="width:10%;color:green;">
          <template #body="slotProps">
            <span>
              {{ formatCurrency(getSumNota(slotProps.data.debitoCreditoNotas, '4 18 22')) }}
            </span>
          </template>
        </Column>

        <Column header="calcTotal" style="color:blue;font-style: italic;width:10%;">
          <template #body="slotProps">
            <span>
              {{ formatCurrency(slotProps.data.total+getSumNota(slotProps.data.debitoCreditoNotas, '4 18 22')+getSumNota(slotProps.data.debitoCreditoNotas, '5 19 23')) }}
            </span>
          </template>
        </Column>

        <Column header="Acciones" :exportable="false" style="width:3%;white-space: nowrap;">
          <template #body="slotProps">
            <Button icon="pi pi-plus" label=" ND/NC " class="p-button-rounded p-button-success"
              @click="addNotaCD(slotProps.data)" title="Agregar ND/NC" />         
          </template>
        </Column>
        <!-- Expansion de Folio para ver las ND -NC asociadas -->
        <template #expansion="slotProps">
          <DataTable :value="slotProps.data.debitoCreditoNotas"
            tableStyle="padding-left:0px !important;padding-right:0px !important">                    

            <Column header="" :exportable="false" style="white-space: nowrap;text-align:center">
              <template>
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" title="Ver" />&nbsp;
                <Button icon="pi pi-bookmark-fill" class="p-button-rounded p-button-success" title="Clasificar Gasto"/>
              </template>
            </Column>

            <Column field="motivo" header="Motivo" style="width:33%;">>
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.motivo }}
                </span>
              </template>
            </Column>

            <Column field="tipo_documento_id" header="Tipo" style="width:2%;">
              <template #body="slotProps">
                <Tag :value="getTipoDocumento(slotProps.data.tipo_documento_id)" severity="info" />
              </template>
            </Column>

            <Column field="folio" header="Folio" style="width:5%;">
              <template #body="slotProps">
                <span>
                  {{ slotProps.data.folio }}
                </span>
              </template>
            </Column>

            <Column field="fecha" header="Fecha" style="width:8%;">
              <template #body="slotProps">
                <span>
                  {{ formatFecha(slotProps.data.fecha) }}
                </span>
              </template>
            </Column>

            <Column field="cantidad" header="Cantidad" style="width:5%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ Number(slotProps.data.cantidad) }}
                </span>
              </template>
            </Column>

            <Column field="valor" header="Valor" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.valor) }}
                </span>
              </template>
            </Column>

            <Column field="iva" header="IVA" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.iva) }}
                </span>
              </template>
            </Column>

            <Column field="neto" header="Neto" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.neto) }}
                </span>
              </template>
            </Column>

            <Column field="total" header="Total" style="width:10%;text-align:center">
              <template #body="slotProps">
                <span>
                  {{ formatCurrency(slotProps.data.total) }}
                </span>
              </template>
            </Column>

            <Column header="" :exportable="false" style="width:5%;white-space: nowrap;">
              <template #body="slotProps">
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info me-2"
                  @click="getPDFSII(slotProps.data)" title="Descarga Folio" />
                <Button icon="pi pi-eye" class="p-button-rounded p-button-warning"
                  @click="setNotaCD(slotProps.data)" title="Ver ND / NC" />&nbsp;              
                <Button icon="pi pi-plus" title=" ND/NC" class="p-button-rounded p-button-success"
                  @click="addNdNc(slotProps.data)" />
              </template>
            </Column>
          </DataTable>          
        </template>
      </DataTable>
    </div>

    <!-- Formulario Notas en modal -->
    <Dialog v-model:visible="notaDialog" :style="{ width: '50%' }" header="Datos del Documento" :modal="true"
      class="p-fluid" :maximizable="true">
      <div align="left" type="solid">
        <b class="text-muted">Antecedentes Documento</b>
      </div>
      <form class="p-fluid">
        <div class="row">
          <div class="col-md-3 field">
            <label for="tipoDocumento" class="mt-4">Tipo Documento</label>
            <AutoComplete id="tipoDocumento" v-model="v$.tipoDocumento.$model"
              :class="{ 'p-invalid': v$.tipoDocumento.$invalid && submitted }" :suggestions="filteredTipoDocumentos"
              @complete="searchTipoDocumento($event)" field="_nombre" :dropdown="true" forceSelection
              aria-describedby="tipoDocumento-error" autofocus autocomplete="off" @change="setMotivos($event, index)"/>
            <span v-if="v$.tipoDocumento.$error && submitted">
              <span id="tipoDocumento-error" v-for="(error, index) of v$.tipoDocumento.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.tipoDocumento.$invalid && submitted) || v$.tipoDocumento.$pending.$response)"
              class="p-error">
              {{ v$.tipoDocumento.required.$message.replace('Value', 'Tipo Documento') }}</small>
          </div>
          <div class="col-md-3 field">
            <label for="motivo" class="mt-4">Motivo</label>
            <Dropdown v-model="v$.motivo.$model" :options="motivos" optionLabel="name" placeholder="" class="w-full md:w-14rem"  @change="setDetalles($event)"/>
            <span v-if="v$.motivo.$error && submitted">
              <span id="motivo-error" v-for="(error, index) of v$.motivo.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.motivo.$invalid && submitted) || v$.motivo.$pending.$response" class="p-error">
              {{ v$.motivo.required.$message.replace('Value', 'Valor') }}</small>
          </div>
          <div class="col-md-3 field">
            <label for="folio" class="mt-4">Nro.Folio</label>
            <InputNumber id="folio" v-model="v$.folio.$model" :class="{ 'p-invalid': v$.folio.$invalid && submitted }"
              aria-describedby="folio-error" autofocus autocomplete="off" />
            <span v-if="v$.folio.$error && submitted">
              <span id="folio-error" v-for="(error, index) of v$.folio.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.folio.$invalid && submitted) || v$.folio.$pending.$response" class="p-error">
              {{ v$.folio.required.$message.replace('Value', 'Valor') }}</small>
          </div>
          <div class="col-md-3 field">
            <label for="fecha" class="mt-4">Fecha</label>
            <Calendar id="fecha" v-model="v$.fecha.$model" 
              :class="{ 'p-invalid': v$.fecha.$invalid && submitted }" aria-describedby="fecha-error"
              autocomplete="off" :showIcon="true" dateFormat="dd/mm/yy"/>
            <span v-if="v$.fecha.$error && submitted">
              <span id="fecha-error" v-for="(error, index) of v$.fecha.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.fecha.$invalid && submitted) || v$.fecha.$pending.$response"
              class="p-error">
              {{ v$.fecha.required.$message.replace('Value', 'Fecha') }}</small>
          </div>                 
        </div>        

        <div class="row">
          <div class="col-md-12 field">
            <label for="cliente" class="mt-3">Cliente</label>
            <AutoComplete id="cliente" v-model="v$.cliente.$model"
              :class="{ 'p-invalid': v$.cliente.$invalid && submitted }" :suggestions="filteredClientes"
              @complete="searchCliente($event)" field="_razon_social" :dropdown="true" forceSelection
              aria-describedby="cliente-error" autofocus autocomplete="off" disabled />
            <span v-if="v$.cliente.$error && submitted">
              <span id="cliente-error" v-for="(error, index) of v$.cliente.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="((v$.cliente.$invalid && submitted) || v$.cliente.$pending.$response)"
              class="p-error">
              {{ v$.cliente.required.$message.replace('Value', 'Cliente') }}</small>
          </div> 
        </div>
        <div align="left" type="solid" class="mt-4">
          <b class="text-muted">Documento Referencia</b>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 field">
            <label for="factura_folio" class="mt-3">Folio</label>
            <InputNumber id="factura_id" v-model.trim="form.factura_id" readonly :hidden="true"/>
            <InputNumber id="factura_folio" v-model.trim="form.factura_folio" readonly/>
          </div>          
          <div class="col-md-2 field">
            <label for="factura_fecha" class="mt-3">Fecha Emision</label>
            <Calendar id="factura_fecha" v-model.trim="form.factura_fecha" autocomplete="off" :showIcon="false" dateFormat="dd/mm/yy" readonly/>
          </div>
          <div class="col-md-3 field">
            <label for="factura_neto" class="mt-3">Monto Neto</label>
            <InputNumber id="factura_neto" v-model.trim="form.factura_neto" readonly/>
          </div>                   
          <div class="col-md-2 field">
            <label for="factura_iva" class="mt-3">Monto IVA</label>
            <InputNumber id="factura_iva" v-model.trim="form.factura_iva" readonly/>
          </div>                   
          <div class="col-md-3 field">
            <label for="factura_total" class="mt-3">Monto Total</label>
            <InputNumber id="factura_total" v-model.trim="form.factura_total" readonly/>
          </div>                   
        </div>

        <div class="card p-fluid table_modal mt-2 col-md-12 field">
          <InlineMessage v-for="msg of messages" :key="msg.id" severity="info" class="mb-2" style="width: 100%;">
            {{ msg.content }}
          </InlineMessage>          
          <DataTable :value="lineas" showGridlines stripedRows scrollable scrollHeight="400px"
            tableClass="editable-cells-table" tableStyle="min-width: 50rem" v-model:selection="selectedLineas"
            v-if="lineas.length > 0 && form.model !== 'notadc'">
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :hidden="col.hidden" style="width: 25%">
              <template #editor="{ data, field }">
                <template v-if="field === 'observacion'">
                  <InputText else v-model="data[field]" autofocus/>
                </template>
                <template v-else-if="(field !== 'producto' && field !== 'observacion')">
                  <InputNumber else v-model="data[field]" autofocus />
                </template>
                <template v-else>
                  <AutoComplete v-model="data[field]" optionLabel="_nombre" :optionValue="data[field]"
                    :suggestions="filteredProductos" @complete="searchProducto($event)" autofocus @focus="selAllText"/>
                </template>
              </template>
            </Column>                
          </DataTable>
        </div>

        <div align="left" type="solid" class="mt-4">
          <b class="text-muted">Valores Documento</b>
        </div>   

        <div class="row">
          <div class="col-md-3 field">
            <label for="cantidad" class="mt-3">Cantidad</label>
            <InputNumber id="cantidad" v-model="v$.cantidad.$model" :class="{ 'p-invalid': v$.cantidad.$invalid && submitted }"
              :maxFractionDigits="2" aria-describedby="cantidad-error" autofocus autocomplete="off"  @update:modelValue="onCellUpdateComplete"/>
            <span v-if="v$.cantidad.$error && submitted">
              <span id="cantidad-error" v-for="(error, index) of v$.cantidad.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.cantidad.$invalid && submitted) || v$.cantidad.$pending.$response" class="p-error">
              {{ v$.cantidad.required.$message.replace('Value', 'Cantidad') }}</small>

            <label for="valor" class="mt-4">Valor Unitario</label>
            <InputNumber id="valor" v-model="v$.valor.$model" :class="{ 'p-invalid': v$.valor.$invalid && submitted }"
              aria-describedby="valor-error" autofocus autocomplete="off"  @update:modelValue="onCellUpdateComplete"/>
            <span v-if="v$.valor.$error && submitted">
              <span id="valor-error" v-for="(error, index) of v$.valor.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.valor.$invalid && submitted) || v$.valor.$pending.$response" class="p-error">
              {{ v$.valor.required.$message.replace('Value', 'Precio unitario') }}</small>              
          </div>
          <div class="col-md-9 field">
            <label for="observacion" class="mt-3">Observaciones</label>
            <Textarea autoResize id="observacion" v-model.trim="v$.observacion.$model" rows="5" 
            :class="{ 'p-invalid': v$.observacion.$invalid && submitted }" aria-describedby="neto-error" />
            <span v-if="v$.observacion.$error && submitted">
              <span id="observacion-error" v-for="(error, index) of v$.observacion.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.observacion.$invalid && submitted) || v$.observacion.$pending.$response" class="p-error">
              {{ v$.observacion.required.$message.replace('Value', 'Observacion') }}</small>            
          </div>          
        </div>               
        <div class="row">
          <div class="col-md-4 field">
            <label for="neto" class="mt-3">Neto</label>
            <InputNumber id="neto" v-model="v$.neto.$model" :class="{ 'p-invalid': v$.neto.$invalid && submitted }"
              aria-describedby="neto-error" autofocus autocomplete="off" readonly/>
            <span v-if="v$.neto.$error && submitted">
              <span id="neto-error" v-for="(error, index) of v$.neto.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.neto.$invalid && submitted) || v$.neto.$pending.$response" class="p-error">
              {{ v$.neto.required.$message.replace('Value', 'Neto') }}</small>
          </div>
          <div class="col-md-1 field">
            <label for="porciva" class="mt-3">%IVA</label>
            <InputNumber id="porciva" v-model="porciva" readonly style="background-color : #d1d1d1;"/>
          </div>              
          <div class="col-md-3 field">
            <label for="iva" class="mt-3">IVA</label>
            <InputNumber id="iva" v-model="v$.iva.$model" :class="{ 'p-invalid': v$.iva.$invalid && submitted }"
              aria-describedby="iva-error" autofocus autocomplete="off" readonly />
            <span v-if="v$.iva.$error && submitted">
              <span id="iva-error" v-for="(error, index) of v$.iva.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.iva.$invalid && submitted) || v$.iva.$pending.$response" class="p-error">
              {{ v$.iva.required.$message.replace('Value', 'IVA') }}</small>
          </div>                                  
          <div class="col-md-4 field">
            <label for="total" class="mt-3">Total</label>
            <InputNumber id="total" v-model="v$.total.$model" :class="{ 'p-invalid': v$.total.$invalid && submitted }"
              aria-describedby="total-error" autofocus autocomplete="off" readonly />
            <span v-if="v$.total.$error && submitted">
              <span id="total-error" v-for="(error, index) of v$.total.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.total.$invalid && submitted) || v$.total.$pending.$response" class="p-error">
              {{ v$.total.required.$message.replace('Value', 'Total') }}</small>
          </div>                   
        </div>           
      </form>

      <template #footer>
        <div class="row">
          <div class="col-md-7 fw-lighter text-muted" style="text-align: left;">
            <small v-if="form.created_id" ><i class="pi pi-user-plus" style="font-size: 1rem"></i>: {{form.created_id.name}} el {{form.created_at}}</small><br/>
            <small v-if="form.updated_id"><i class="pi pi-user-edit" style="font-size: 1rem"></i>: {{form.updated_id.name}} el {{form.updated_at}}</small>    
          </div>  
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-if="!saving">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text text-danger" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveNota" v-if="!(form.id > 0)"/>
          </div>
          <div :class="form.created_id ? 'col-md-5 field' : 'col-md-12 field'" v-else>
            <ProgressBar mode="indeterminate"/>
          </div>
        </div>
      </template>
    </Dialog>

    <!-- Confirmar elimina un nota -->
    <Dialog v-model:visible="deleteNotaDialog" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-warning" style="font-size: 2rem; vertical-align: middle;" />
        <span v-if="nota"> ¿Está seguro de borrar el nota?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteNotaDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-text text-danger" @click="deleteNota" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useDocsEmitidos from '../../composables/procesos/docsEmitidos'
import { reactive, onMounted, toRaw, isProxy, watch } from 'vue'
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  setup() {
    const {
      nota,
      notas,
      deleteNotaDialog,
      notaDialog,
      clientes,
      tipoDocumentos,
      factura,
      facturas,
      porciva,
      dt,
      loading,
      saving,
      submitted,
      filters,
      filteredTipoDocumentos,
      filteredClientes,
      filteredFacturas,
      getCRUD,
      storeNota,
      deleteNota,
      selectedMotivo,
      motivos,
      columns,
      lineas,
      selectedLineas,
      getPDFSII,
      expandedRows,
      rango,
      messages
    } = useDocsEmitidos()
    
    moment.locale('es')
    const form = reactive({
      id: null,
      tipoDocumento: null,
      cliente: '',
      factura_id: null,
      factura_folio: null,
      factura_fecha: null,
      factura_neto: null,
      factura_iva: null,
      factura_total: null,
      folio: 0, //null,
      fecha: null,
      cantidad: 1,
      valor: null,
      porciva: null,
      neto: null,
      iva: null,
      total: null,
      motivo: null,
      detalles: [],
      lineas: [],
      observacion: '',
      model: null,
      despacho_id: null,
      notadc_id: null
    })
    
    const rules = {
      tipoDocumento: { required: helpers.withMessage('El campo es requerido o no corresponde a la despacho', required), },
      cliente: { required: helpers.withMessage('El campo es requerido!', required), },
      observacion: { required: helpers.withMessage('El campo es requerido!', required), },
      cantidad: { required: helpers.withMessage('El campo es requerido', required), },
      folio: { required: helpers.withMessage('El campo es requerido', required), },
      fecha: { required: helpers.withMessage('El campo es requerido', required), },         
      valor: { required: helpers.withMessage('El campo es requerido', required), },
      neto: { required: helpers.withMessage('El campo es requerido', required), },
      iva: { required: helpers.withMessage('El campo es requerido', required), },
      total: { required: helpers.withMessage('El campo es requerido', required), },
      motivo: { required: helpers.withMessage('El campo es requerido', required), },
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    const confirmDeleteNota = (not) => {
      nota.value = not
      deleteNotaDialog.value = true
    }

    const hideDialog = () => {
      notaDialog.value = false
      submitted.value = false
      saving.value = false
    }

    const setNotaCD = (data) => {         
      factura.value = toRaw(facturas.value.filter(function (itm) { return [data.factura_id].indexOf(itm.id) > -1 }))[0]
      notaDialog.value = true
      submitted.value = false
      saving.value = false
      selectedLineas.value = []
      lineas.value = []
      form.lineas = []

      let _motivo = data.motivo.split(": ")
      let _factura = data.factura_id ? toRaw(facturas.value.filter(function (itm) { return [data.factura_id].indexOf(itm.id) > -1 }))[0] : (data.despacho_id ? toRaw(facturas.value.filter(function (itm) { return [data.despacho_id].indexOf(itm.id) > -1 }))[0] : toRaw(facturas.value.filter(function (itm) { return [data.notadc_id].indexOf(itm.id) > -1 }))[0])

      form.factura_id = _factura.id
      form.factura_folio = _factura.folio
      form.factura_fecha = _factura.fecha
      form.factura_neto = _factura.neto
      form.factura_iva = _factura.iva
      form.factura_total = _factura.total      

      let detalles = isProxy(_factura.productos) ? toRaw(_factura.productos) : []

      lineas.value = detalles.reduce((acc, ln, index) => {
        acc.push({
          'idx': index + 1,
          'producto_obj' : {'id': ln.id, 'nombre': ln.nombre},
          'producto' : ln.nombre,
          'espesor' : ln.pivot.espesor,
          'ancho' : ln.pivot.ancho,
          'largo' : ln.pivot.largo,
          'cant_despacho' : ln.pivot.cant_despacho,
          'mc_despacho' : ln.pivot.mc_despacho,
          'pulg_despacho' : ln.pivot.pulg_despacho,
          'precio' : ln.pivot.precio,
          'tipo_cambio' : ln.pivot.tipo_cambio,
          'total' : ln.pivot.total,
          'total_mask' : ln.pivot.total
        })
        return acc;
      }, [])

      // form.detalles = lineas.value
      form.id = data.id
      form.tipoDocumento = toRaw(tipoDocumentos.value.filter(function (itm) { return [data.tipo_documento_id].indexOf(itm.id) > -1 }))[0]
      form.cliente = toRaw(clientes.value.filter(function (itm) { return [data.cliente_id].indexOf(itm.id) > -1 }))[0]      
      
      form.folio = data.folio ?? 0, //data.folio
      form.fecha = data.fecha ? moment(data.fecha).toDate() : moment().toDate()
      form.cantidad = data.cantidad ?? 1
      form.valor = data.valor
      form.porciva = data.poriva ?? porciva.value
      form.neto = data.neto
      form.iva = data.iva
      form.total = data.total
      form.observacion = data.observacion
      form.motivo = motivos.value.filter(function (itm) { return [_motivo[0]].indexOf(itm.code) > -1 })[0]
      
      form.created_at = data.created_at
      form.created_id = data.created_id
      form.updated_at = data.updated_at
      form.updated_id = data.updated_id
    }
    
    //agregar auna ND ndc a otra ndnc
    const addNdNc = (data) => {  
      console.info('data', data)
      nota.value = {}
      motivos.value = []
      messages.value = []
      notaDialog.value = true
      submitted.value = false
      saving.value = false
      selectedLineas.value = []
      lineas.value = []
      
      form.lineas = []
      form.id = nota.value.id ?? null
      form.cliente = toRaw(clientes.value.filter(function (itm) { return [data.cliente_id].indexOf(itm.id) > -1 }))[0]
      form.folio = nota.value.folio ?? 0, //nota.value.folio
      form.fecha = moment().toDate()
      form.cantidad = 1
      form.porciva = porciva.value
      form.model = 'notadc'

      // datos del docuemnto(notadc) sobre el que se crea la nota - solo lectura
      form.factura_id = data.factura_id ?? null
      form.factura_folio = data.folio
      form.factura_fecha = data.fecha
      form.factura_neto = data.neto
      form.factura_iva = data.iva
      form.factura_total = data.total      
      form.despacho_id = data.despacho_id ?? null 
      form.notadc_id = data.id ?? null    

      //limpia los campos
      form.tipoDocumento = null   
      form.valor = null
      form.porciva = porciva.value
      form.neto = null
      form.iva = null
      form.total = null
      form.observacion =null
      form.motivo = null
    }

    // Agregar NOTA DC a Factura
    const addNotaCD = (data) => {  
      messages.value = []
      motivos.value = []
      factura.value = { ...data }
      nota.value = {}

      notaDialog.value = true
      submitted.value = false
      saving.value = false
      selectedLineas.value = []
      lineas.value = []
      form.lineas = []

      let detalles = isProxy(factura.value.productos) ? toRaw(factura.value.productos) : []

      lineas.value = detalles.reduce((acc, ln, index) => {
        acc.push({
          'idx': index + 1,
          'producto_obj' : {'id': ln.id, 'nombre': ln.nombre},
          'producto' : ln.nombre,
          'espesor' : ln.pivot.espesor,
          'ancho' : ln.pivot.ancho,
          'largo' : ln.pivot.largo,
          'cant_despacho' : ln.pivot.cant_despacho,
          'mc_despacho' : ln.pivot.mc_despacho,
          'pulg_despacho' : ln.pivot.pulg_despacho,
          'precio' : ln.pivot.precio,
          'tipo_cambio' : ln.pivot.tipo_cambio,
          'total' : ln.pivot.total,
          'total_mask' : ln.pivot.total
        })
        return acc;
      }, [])
      
      form.tipoDocumento = null   
      form.detalles = lineas.value
      form.id = nota.value.id ?? null
      form.cliente = factura.value.cliente
      
      form.factura_folio = factura.value.folio
      form.factura_fecha = factura.value.fecha
      form.factura_neto = factura.value.neto
      form.factura_iva = factura.value.iva
      form.factura_total = factura.value.total

      form.factura_id = factura.value.model === 'factura' ? factura.value.id : null
      form.despacho_id = factura.value.model === 'despacho' ? factura.value.id : null
      form.notadc_id = null
      
      form.folio = nota.value.folio ?? 0, //nota.value.folio
      form.fecha = moment().toDate()
      form.cantidad = 1
      form.porciva = porciva.value
      form.model = factura.value.model
      
      //limpia los campos
      form.valor = null
      form.neto = null
      form.iva = null
      form.total = null
      form.observacion =null
      form.motivo = null      
      //si tiene una sola linea de detalle se selecciona 
      if(lineas.value.length === 1) selectedLineas.value = [lineas.value];
    }

    const saveNota = () => {
      if(form.model === "notadc" && selectedLineas.value.length === 0)
        selectedLineas.value.push({
          'producto_obj' : {'id': form.motivo.code, 'nombre': form.motivo.name + ' a folio #' + form.factura_folio },
          'cant_despacho' : form.cantidad,
          'precio' : form.valor,
          'total' : form.total
        })   

      messages.value = []
      submitted.value = true
      
      if(!v$._value.$invalid){
        if (selectedLineas.value.length > 0 && selectedLineas.value.length < 61) {
          form.lineas = selectedLineas.value
          storeNota(form)
        }else
          messages.value = [{content: 'Por normativa del SII debe seleccionar entre 1 y 60 lineas de detalles', id: lineas.value.length}]         
      } 
    }

    const searchTipoDocumento = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipoDocumentos.value = [...tipoDocumentos.value]
        }
        else {
          filteredTipoDocumentos.value = tipoDocumentos.value.filter((tdocumento) => {
            return tdocumento._nombre.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchCliente = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredClientes.value = [...clientes.value]
        }
        else {
          filteredClientes.value = clientes.value.filter((cliente) => {
            return cliente._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const formatFecha = (value) => {
      return moment(value).format("DD-MM-YY")
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('es-CL').format(value)
    }

    const onCellUpdateComplete = () => {
      form.neto = form.tipoDocumento.codigo.includes('NDE') ? Number(form.cantidad * form.valor) : (Number(form.cantidad * form.valor) > 0 ? Number(form.cantidad * form.valor)* -1 : 0)
      form.iva  = Number(Math.round(form.neto * form.porciva / 100))
      form.total = Number(Math.round(form.neto + form.iva))
    }

    const getTipoDocumento = (tdoc) => {
      return toRaw(tipoDocumentos.value.filter(function (itm) { return [tdoc].indexOf(itm.id) > -1 }))[0].codigo              
    }

    const getSumNota = (notas,tdocs) => {
      return notas.reduce(function(acc, nt) {return tdocs.match(nt.tipo_documento_id) ? acc + nt.total : acc + 0 }, 0)
    }

    const setDetalles = (e) => {
      if(e.value.code === 'NCA'){
        selectedLineas.value = [...lineas.value]
        form.cantidad = 1
        form.valor = form.factura_neto
        form.neto = form.factura_neto
        form.iva = form.factura_iva
        form.total = form.factura_total
      }else{
        //si tiene una sola linea de detalle se selecciona 
        selectedLineas.value = []
        if(lineas.value.length === 1) selectedLineas.value = [...lineas.value];
        form.cantidad = 1
        form.valor = null
        form.neto = null
        form.iva = null
        form.total = null    
      }
    }

    const setMotivos = (e) => {
      if(e.value.codigo === 'NDE')
        motivos.value = [
          { name: 'Nota de Débito para Corregir Montos y Cantidades', code: 'NDM' },
        ]
      else
        motivos.value = [
          { name: 'Nota de Crédito de Anulación', code: 'NCA' },
          { name: 'Nota de Crédito para Corregir Montos', code: 'NCM' },
          { name: 'Nota de Crédito para Corregir Texto', code: 'NCT' },
        ]      
      onCellUpdateComplete()
    }

    const v$ = useVuelidate(rules, form)

    watch(selectedLineas, (newSelection) => {
      if (lineas.value.length === 1 && newSelection.length === 0) {
        selectedLineas.value = [...lineas.value]
      }
    }) 

    onMounted(getCRUD)

    return {
      dt, 
      loading,
      saving,
      nota,
      notas,
      factura,
      facturas,
      porciva,
      filters,
      deleteNotaDialog,
      notaDialog,
      submitted,
      v$,
      form,
      filteredTipoDocumentos,
      filteredClientes,
      filteredFacturas,
      searchTipoDocumento,
      searchCliente,
      hideDialog,
      confirmDeleteNota,
      deleteNota,
      exportCSV,
      setNotaCD,
      saveNota,
      storeNota,
      formatCurrency,
      onCellUpdateComplete,
      selectedMotivo,
      motivos,
      lineas,
      columns,
      getPDFSII,
      selectedLineas,
      expandedRows,
      formatFecha,
      getTipoDocumento,
      addNotaCD,
      getSumNota,
      addNdNc,
      rango,
      getCRUD,
      setDetalles,
      setMotivos,
      messages
    }
  },
}
</script>
