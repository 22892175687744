import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import useClient from '../client/apiClient'

export default function useVentasCobro() {
  const ventasSII = ref([])
  const notadcSII = ref([])
  const messages = ref([])
  const clientes = ref([])
  const tipoDocumentos = ref([]) 

  const router = useRouter()
  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)
  const deletePagoDialog = ref({visible:false, index:null, id: null})
  const submitted = ref(false)
  const expandedRows = ref([])

  const cobroDialog = ref(false)
  const venta = ref([])
  const tcobros = ref([])
  const cobros = ref([])
  const filteredTipoCobros = ref()

  const modalidades = ref(['TRANSFERENCIA', 'EFECTIVO', 'CHEQUE', 'TARJETA DEBITO', 'TARJETA CRÉDITO', 'SALDO NOTA CRÉDITO/DÉBITO'])
  const filteredModalidades = ref()

  const estados = ref(['PENDIENTE','PAGADO','VENCIDO', 'REBAJADO'])
  const filteredEstados = ref()

  const lineas = ref([])

  const getVentaSII = async (rut,fdesde, fhasta, edo) => {
    loading.value = true
    ventasSII.value = []
    client.get(`/api/ventas/${rut}/${fdesde}/${fhasta}/${edo}/filtro`).then(response => {
      notadcSII.value = response.data.data.notas
      tcobros.value = response.data.data.tcobros
      tipoDocumentos.value = response.data.data.tipoDocumentos
      // let detalles
      response.data.data.ventas.map((venta, idx) => {
        let detalles = response.data.data.notas.filter(
          (nota) => 
          {
            if(Number(nota.folio_docto_ref) === Number(venta.folio) && nota.rut_cliente === venta.rut_cliente)
              if(Number(nota.tipo_docto_ref) === 33) return nota
              if('55 56 111 60 61 112'.match(nota.tipo_docto_ref) &&  nota.rut_cliente === venta.rut_cliente) {
                //TODO convertir en funcion recursiva
                let fpadre = response.data.data.notas.filter(function (itm) { return [nota.folio_docto_ref].indexOf(itm.folio) > -1 && nota.rut_cliente === venta.rut_cliente })[0].folio_docto_ref
                if(fpadre === venta.folio) return nota
              }
          }
          )
        ventasSII.value[idx] = {notasdc: detalles, ...venta}
      })
      
      loading.value = false
    })
  }

  const storeCobros = async (form,rut,fdesde, fhasta, edo) => {
    saving.value = true
    client.post(`/api/ventas`, { 'data': { ...form } })
      .then((response) => {
        if(response.status === 200){
          saving.value = false
          getVentaSII(rut,fdesde, fhasta, edo)
          cobroDialog.value = false
        }else{
          //Mostrar razon del error y habilita de nuevo el boton guardar
          cobroDialog.value = true
          saving.value = false          
        } 
      })       
  }

  const getClientes = async () => {
    client.get(`/api/empresas/all/cliente/get`).then(response => {
      clientes.value = response.data.data.empresas
    })
  }

  const getPDFSII = async (d) => {
    loading.value = true
    d.rut_proveedor = '76492850-4'
    // d.codigo_sii = 33
    if(d.folio !== undefined)
      axios.get(`/api/sii/${d.tipo_doc}/${d.folio}/E/${d.rut_proveedor}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }

  const deletePago = async () => { 
    client.remove(`/api/ventas/${deletePagoDialog.value.id}`)
      .then(response => {
        let row = {
          'id': null,
          'modalidad': '',
          'referencia': 0,
          'porc_abono': 0,
          'monto_abono': 0,
          'fecha_vence': null,
          'fecha_cobro': null,
          'cuota': null,
          'entidad': '',
          'observacion': '',
          'estado': null
        }        
        lineas.value[deletePagoDialog.value.index] = row
        lineas.value.splice(deletePagoDialog.value.index, 1)
        if (lineas.value.length === 0) lineas.value.pop()
        if(response.data.data.f5 === true){
          getVentaSII(deletePagoDialog.value.rut, deletePagoDialog.value.fdesde.toLocaleDateString('es-CL'), deletePagoDialog.value.fhasta.toLocaleDateString('es-CL'),0)
          cobroDialog.value = false      
        }  
        messages.value = []
        deletePagoDialog.value.visible = false
    })
  }  

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'tipo_doc': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'tipo_venta': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'fecha_docto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha_recepcion': { value: null, matchMode: FilterMatchMode.EQUALS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'rut_cliente': { value: null, matchMode: FilterMatchMode.EQUALS },
    'razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'monto_exento': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_total': { value: null, matchMode: FilterMatchMode.EQUALS },
    'estado': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    router,
    ventasSII,
    notadcSII,
    dt,
    loading,
    saving,
    submitted,
    filters,
    cobroDialog,
    venta,
    lineas,
    tcobros,
    filteredTipoCobros,
    messages,
    modalidades,
    estados,
    filteredEstados,
    filteredModalidades,
    cobros,
    clientes,
    getVentaSII,
    storeCobros,
    getClientes,
    getPDFSII,
    deletePago,
    deletePagoDialog,
    expandedRows,
    tipoDocumentos
  }
}