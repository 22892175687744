<template>
  <Toast />
  <div class="card-header">
    <h4>Informes de Despachos</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-2 field mt-1">
        <label for="tipoInforme">Informe</label>
        <AutoComplete id="tipoInforme" name="tipoInforme"
          :suggestions="filteredTipos" field="label" v-model.trim="tipoInforme"
          @complete="searchTipo($event)" :dropdown="true" forceSelection 
          autofocus autocomplete="off" @change="getClientes($event)"/>
      </div>        
      <div class="col-md-2 field mt-1" v-if="tipoInforme.value === 'cliente'">
        <label for="cliente">Cliente</label>
        <AutoComplete id="cliente" name="cliente" v-model.trim="cliente"
          :suggestions="filteredClientes" field="_razon_social" 
          @complete="searchCliente($event)" :dropdown="true"
          autofocus autocomplete="off"/>
      </div>           
      <div class="col-md-2 field mt-1">
        <label for="fdesde">Desde:&nbsp;</label>
        <Calendar
          id="fdesde"
          aria-describedby="fdesde-error"
          v-model="fdesde"
          inputClass="bg-input"
          autocomplete="off"
          dateFormat="dd-mm-yy"
          :showIcon="true"
        />&nbsp;
      </div>          
      <div class="col-md-2 field mt-1">
        <label for="fhasta">Hasta:&nbsp;</label>
        <Calendar
          id="fhasta"
          aria-describedby="fhasta-error"
          v-model="fhasta"
          inputClass="bg-input"
          autocomplete="off"
          dateFormat="dd-mm-yy"
          :showIcon="true"
        />&nbsp;
      </div>  
      <div class="col-md-2 field mt-1">
        <label for="button">&nbsp;</label>
        <Button id="button" type="button" label="Generar Informe" icon="pi pi-search" :loading="loading" severity="info" @click="genInforme" style="margin-top: 1.3rem !important;"/>
      </div>
      <div class="col-md-2 field mt-1" v-if="tipoInforme.value === 'fsc' && informeData.length > 0">
        <label for="button">&nbsp;</label>
        <download-excel v-if="informeData.length" class="btn btn-success" :data  = "informeData" :fields = "informeHeader" type  = "xlsx" name="despachosFSC.xlsx" style="margin-top: 1.3rem !important; border-radius: 0px !important; height: 40px !important; padding: .5rem .5rem; font-size: 1rem; font-weight: bold;">Descargar Excel FSC
        </download-excel>
      </div>   
    </div>
    <div class="row">
      <div class="col-md-12 field mt-4">
        <InlineMessage v-for="msg of messages" :key="msg.id" severity="info" style="width: 100%;">{{ msg.content }}</InlineMessage>
      </div>
    </div>           
  </div>
</template>

<script>
import useInformes from '../../composables/informes/index'
import { ref, toRaw } from 'vue'

export default {
  setup() {
    const {
      loading, 
      messages,     
      informeData,
      informeHeader,
      informeDespachoFSC,
      informeDespachoGral, 
      informeDespachoCliente,  
      clientes,
      getClientes  
    } = useInformes()

    const fdesde = ref(new Date())
    const fhasta = ref(new Date())
    const tipoInforme = ref("")
    const cliente = ref(null)

    const informesDespacho = ref([
      { label: 'FSC', value: 'fsc' },
      { label: 'GENERAL', value: 'general' },
      { label: 'NULAS', value: 'nulas' },
      { label: 'CLIENTE', value: 'cliente' },
    ]) 
    const filteredTipos = ref()
    const filteredClientes = ref()
      
    const genInforme = () => {
      informeData.value = []
      messages.value = []

      if(toRaw(tipoInforme.value).value === 'fsc'){
        informeDespachoFSC(fdesde.value, fhasta.value)
      }

      if(toRaw(tipoInforme.value).value === 'general'){
        informeDespachoGral(fdesde.value, fhasta.value,1)
      }

      if(toRaw(tipoInforme.value).value === 'nulas'){
        informeDespachoGral(fdesde.value, fhasta.value,0)
      }

      if(toRaw(tipoInforme.value).value === 'cliente'){
        informeDespachoCliente(fdesde.value, fhasta.value, cliente.value.id)
      }
    }

    const searchTipo = (event) => {
      messages.value = []
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredTipos.value = [...informesDespacho.value]
        }
        else {
          filteredTipos.value = informesDespacho.value.filter((t) => {
            return t.label.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }   

    const searchCliente = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredClientes.value = [...clientes.value]
        }
        else {
          filteredClientes.value = clientes.value.filter((cliente) => {
            return cliente._razon_social.toLowerCase().includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }
    
    return {
      loading,
      messages,  
      fdesde,
      fhasta,
      genInforme,
      informeData,
      informeHeader,
      tipoInforme,
      informesDespacho,
      filteredTipos,
      searchTipo,
      cliente,
      filteredClientes,
      searchCliente,
      getClientes
    }
  },
}
</script>