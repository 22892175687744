import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useContratos() {
  const contrato = ref([])
  const contratos = ref([])
  const contratosDelete = ref([])

  const client = useClient()

  const empresas = ref([])
  const productos = ref([])
  const destinos = ref([])

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedContratos = ref()
  const deleteContratosDialog = ref(false)
  const deleteContratoDialog = ref(false)
  const contratoDialog = ref(false)
  const submitted = ref(false)

  const filteredEmpresas = ref()
  const filteredProductos = ref()
  const filteredDestinos = ref()

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/contratos/all/crud`).then(response => {
      contratos.value = response.data.data.contratos
      empresas.value = response.data.data.empresas
      productos.value = response.data.data.productos
      destinos.value = response.data.data.odestinos

      loading.value = false
    })
  }

  const storeContrato = async (form) => {
    saving.value = true
    let id = contrato.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/contratos/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          contratoDialog.value = false
          //client.saving.value = false ?? getCRUD()
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/contratos`, { 'data': { ...form } })
        .then(() => {
          contratoDialog.value = client.dialog.value
          contrato.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteContrato = async () => {
    client.remove(`/api/contratos/${contrato.value.id}`)
      .then(response => {
        contratos.value = contratos.value.filter(val => val.id !== contrato.value.id)
        deleteContratoDialog.value = false
        contrato.value = {}
      })
  }

  const deleteSelectedContratos = async () => {
    contratosDelete.value = selectedContratos.value.map(({ id }) => id)
    client.remove(`/api/contratos/destroy/mass`, { data: contratosDelete.value })
      .then(response => {
        contratos.value = contratos.value.filter(
          (val) => !selectedContratos.value.includes(val)
        )
        deleteContratosDialog.value = false
        selectedContratos.value = null
        contratosDelete.value = null
      })
  }
  
  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'id': { value: null, matchMode: FilterMatchMode.EQUALS },
    'producto.nombre': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'empresa.razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'odestino.descripcion': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'nombre': { value: null, matchMode: FilterMatchMode.EQUALS },
    'valor': { value: null, matchMode: FilterMatchMode.EQUALS },
    'vigente': { value: null, matchMode: FilterMatchMode.EQUALS },
  })

  return {
    contrato,
    contratos,
    empresas,
    productos,
    destinos,
    dt,
    loading,
    saving,
    filters,
    selectedContratos,
    deleteContratosDialog,
    deleteContratoDialog,
    contratoDialog,
    submitted,
    filteredEmpresas,
    filteredProductos,
    filteredDestinos,
    deleteSelectedContratos,
    getCRUD,
    storeContrato,
    deleteContrato
  }
}