import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useChoferes() {
  const chofer = ref([])
  const choferes = ref([])
  const choferesDelete = ref([])

  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedChoferes = ref()
  const deleteChoferesDialog = ref(false)
  const deleteChoferDialog = ref(false)
  const choferDialog = ref(false)
  const submitted = ref(false)

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/choferes/all/crud`).then(response => {
      choferes.value = response.data.data.choferes
      loading.value = false
    })
  }

  const storeChofer = async (form) => {
    saving.value = true
    let id = chofer.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/choferes/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          choferDialog.value = false
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/choferes`, { 'data': { ...form } })
        .then(() => {
          choferDialog.value = client.dialog.value
          chofer.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteChofer = async () => {
    client.remove(`/api/choferes/${chofer.value.id}`)
      .then(response => {
        choferes.value = choferes.value.filter(val => val.id !== chofer.value.id)
        deleteChoferDialog.value = false
        chofer.value = {}
      })
  }

  const deleteSelectedChoferes = async () => {
    choferesDelete.value = selectedChoferes.value.map(({ id }) => id)
    client.remove(`/api/choferes/destroy/mass`, { data: choferesDelete.value })
      .then(response => {
        choferes.value = choferes.value.filter(
          (val) => !selectedChoferes.value.includes(val)
        )
        deleteChoferesDialog.value = false
        selectedChoferes.value = null
        choferesDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    chofer,
    choferes,
    dt,
    loading,
    saving,
    filters,
    selectedChoferes,
    deleteChoferesDialog,
    deleteChoferDialog,
    choferDialog,
    submitted,
    deleteSelectedChoferes,
    getCRUD,
    storeChofer,
    deleteChofer
  }
}