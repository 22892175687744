<template>
  <Toast />
  <div class="card-header">
    <h4>Actualización Guia de Recepción</h4>
    <div class="line_title"></div>
  </div>
  <div class="card-body">
    <form class="p-fluid">
      <div class="row">
        <div class="field col-md-8">
          <label for="proveedor">Proveedor</label>
          <AutoComplete
            id="proveedor"
            v-model="form.proveedor"
            :suggestions="filteredProveedores"
            @complete="searchProveedor($event)"
            field="_razon_social"
            :dropdown="true"
            forceSelection
            autofocus
            autocomplete="off"/>
        </div>
        <div class="col-md-2 field">
          <label for="fecha">Fecha Desde</label>
          <Calendar
            id="fdesde"
            v-model="form.fdesde"
            autocomplete="off"
            :showIcon="false"
            dateFormat="dd/mm/y"
            autofocus/>
        </div>
        <div class="col-md-2 field">
          <label for="fhasta">Fecha Hasta</label>
          <Calendar
            id="fhasta"
            v-model="form.fhasta"
            autocomplete="off"
            :showIcon="false"
            dateFormat="dd/mm/y"
            autofocus/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 field mt-4">
          <Checkbox
            v-model="un_producto"
            inputId="un_producto"
            name="un_producto"
            :binary="true"
            @input="unSelectProductos($event)"/>
          <label for="un_producto" class="ms-2 mt-2">Mostrar sólo un producto</label>
        </div>
        <div class="col-md-8 field mt-4">
          <AutoComplete
            id="producto"
            v-model.trim="form.producto"
            :suggestions="filteredProductos"
            @complete="searchProducto($event)"
            field="_nombre"
            :dropdown="true"
            autocomplete="off"
            placeholder="Seleccione Producto"
            :disabled="!un_producto"
            @update:modelValue="buscarGuia"/>
        </div>
        <div class="col-md-2 field mt-4">
          <Button
            icon="pi pi-search"
            class="p-button-primary float-end"
            label="Buscar"
            @click="buscarGuia"
            outlined
            :disabled="form.fdesde === null || form.fhasta === null || form.proveedor === null"/>
        </div>
      </div>
      <!-- Detalles -->
      <div class="row mt-4">
        <DataTable
          ref="dt"
          v-model:editingRows="editingRows"
          :value="guias"
          scrollable
          scrollHeight="600px"
          style="zoom: 0.80"
          :loading="loading"
          class="sm"
          showGridlines
          stripedRows
          editMode="row"
          sortMode="multiple" removableSort
          dataKey="detalle_id"
          @row-edit-save="onRowEditSave"
          @row-edit-init="onRowEditInit"
          tableClass="editable-cells-table">
          <Column field="recepcion_id" header="ID" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.recepcion_id }}
              </span>
            </template>
          </Column>
          <Column field="folio" header="FOLIO" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.folio }}
              </span>
            </template>
          </Column>
          <Column field="fecha" header="FECHA" sortable>
            <template #body="slotProps">
              <span>
                {{ formatFecha(slotProps.data.fecha) }}
              </span>
            </template>
          </Column>
          <Column field="nombre" header="PRODUCTO" sortable>
            <span>
              {{ slotProps.data.nombre }}
            </span>
          </Column>                   
          <Column field="origen" header="ORIGEN" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.origen.descripcion.slice(0, 23) }}
              </span>
            </template>
          </Column>
          <Column field="rol" header="ROL" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.rol }}
              </span>
            </template>
          </Column>
          <Column field="predio" header="PREDIO" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.predio }}
              </span>
            </template>
          </Column>

          <Column field="fsc" header="FSC" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.fsc ? slotProps.data.fsc.nombre : ''}}
              </span>
            </template>
            <template #editor="{ data, field, index }">
              <AutoComplete
                id="fsc"
                v-model="data[field]"
                :suggestions="filteredFscs"
                @complete="searchFsc($event)"
                field="nombre"
                :dropdown="true"
                forceSelection
                autofocus
                autocomplete="off"
                @change="setFscCodigo($event, index)"
              />
            </template>
          </Column>
          <Column field="fsc_codigo" header="COD.FSC" sortable>
            <span>
              <InputText v-model="data[field]" />
            </span>
          </Column>

          <Column field="espesor" header="ESPESOR" :hidden="!(/espesor/.test(showcol))" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>          
            </template>
          </Column>
          <Column field="ancho" header="ANCHO" :hidden="!(/ancho/.test(showcol))" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>
            </template>
          </Column>
          <Column field="diametro" header="DIAMETRO" :hidden="!(/diametro/.test(showcol))" sortable>
            <template #body="slotProps">
              <span>
                {{ slotProps.data.diametro }}
              </span>
            </template>            
          </Column>
          <Column field="largo" header="LARGO" :hidden="!(/largo/.test(showcol))" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>
            </template>
          </Column>
          <Column field="alto" header="ALTO" :hidden="!(/alto/.test(showcol))" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>
            </template>
          </Column>
          <Column field="volumen" header="VOL." :hidden="!(/volumen/.test(showcol))" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>
            </template>
          </Column>
          <Column field="cant_recepcion" header="CANT.REC." sortable>  <!-- :hidden="!(/cant_recepcion/.test(showcol))" -->
            <template #editor="{ data, field }"> <!-- trozos no editable -->
              <InputText v-model="data[field]" v-if="data['tmadera'] !== 9"/>
              <span v-else >
                {{ data['cant_recepcion'] }}
              </span>              
            </template>                      
          </Column>           
          <Column field="pulg_recepcion" header="PULG REC." :hidden="!(/pulg_recepcion/.test(showcol))">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>
            </template>
          </Column>
          <Column field="mc_recepcion" header="M3 REC." :hidden="!(/mc_recepcion/.test(showcol))">
            <template #editor="{ data, field }"> <!-- trozos no editable -->
              <InputText v-model="data[field]" v-if="data['tmadera'] !== 9" />
              <span v-else >
                {{ data['mc_recepcion'] }}
              </span> 
            </template>
          </Column>
          <Column field="mruma_recepcion" header="MR REC." :hidden="!(/mruma_recepcion/.test(showcol))">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]"/>               
            </template>
          </Column>

          <Column field="precio" header="Valor" sortable>
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" v-if="data['tmadera'] !== 9"/>
              <span v-else> {{ data['precio'] }} </span>               
            </template>            
          </Column>
          
          <Column field="total" header="Total" sortable>
            <span>
              {{ slotProps.data.total }}
            </span>
          </Column>                    
          <Column
            header="Editar"
            :rowEditor="true"
            style="width: 10% min-width: 8rem"
            bodyStyle="text-align:center">
            </Column>
          <ColumnGroup type="footer">
            <Row>
              <Column footer="Totales" :colspan="colspan" footerStyle="text-align:right"/>
              <Column :footer="totCantidad" footerStyle="text-align:right"/>
              <Column :footer="totM3Un" footerStyle="text-align:right"/>
              <Column :footer="totMRuma" v-if="colspan > 9" footerStyle="text-align:right"/>
              <Column v-else footerStyle="text-align:right"/>
              <Column v-if="colspan > 9" footer="" footerStyle="text-align:right"/>
              <Column :footer="totGuia" footerStyle="text-align:right"/>
              <Column footer="" footerStyle="text-align:right"/>
            </Row>
          </ColumnGroup>               
        </DataTable>
      </div>
    </form>
  </div>
</template>

<script>
import useAGRecepciones from "../../composables/recepciones/agrecepcion"
import { reactive, onMounted, isProxy, toRaw, computed } from "vue"
import moment from "moment"

export default {
  setup() {
    const {
      porciva,
      editingRows,
      fsc,
      proveedores,
      fscs,
      productos,
      showcol,
      dt,
      dtg,
      loading,
      saving,
      guias,
      submitted,
      filteredProveedores,
      filteredFscs,
      filteredProductos,
      selectedProductos,
      selectedGuias,
      messages,
      un_producto,
      actualizaGuia,
      getCRUD,
      getGuias,
      colspan
    } = useAGRecepciones()

    const form = reactive({
      proveedor: null,
      producto: null,
      origen: "",
      destino: "",
      transportista: "",
      chofer: "",
      despachador: "",
      fsc: "",
      fsc_codigo: "",
      folio: null,
      fecha: moment().toDate(),
      patente_camion: "",
      patente_carro: "",
      flete: null,
      poriva: porciva.value,
      descuento: 0,
      neto: null,
      iva: null,
      total: null,
      nro_oc: null,
      fecha_oc: "",
      observacion: "",
      monto_letras: null,
      detalles: [],
      tipo_pago: null,
      guias_recepcion: null,
      guias: [],
      //para busqueda de guias
      fdesde: null,
      fhasta: null,
    })

    const onRowEditInit = (event) => {
      let row = toRaw(event.data)
      showcol.value = ''
      switch (row.tmadera) {
        case 7:
          showcol.value = 'pulg_recepcion,cant_recepcion'
          colspan.value = 9
          break;
        case 9:
          showcol.value = 'diametro,mc_recepcion,cant_recepcion'
          colspan.value = 11
          break;
        case 10:
          showcol.value = 'mruma_recepcion,cant_recepcion'
          colspan.value = 9
          break;
        case 11:
          showcol.value = 'mruma_recepcion'
          colspan.value = 9
          break;      
        default:
          showcol.value = 'mc_recepcion,cant_recepcion'
          colspan.value = 9
      }
    }

    const onRowEditSave = (event) => {
      let { newData, index } = event  
      let guiasCambio = isProxy(guias.value) ? toRaw(guias.value) : []

      guias.value[index] = newData

			guias.value.forEach(function (guia, i) {
				guia = toRaw(guia)
				if(guia.folio === guias.value[index].folio){
          //FSC
          guias.value[i].fsc_codigo = newData.fsc_codigo
          guias.value[i].fsc = newData.fsc

          //aqui los calculos segun el tipo de madera seleccionado
          if(newData.tmadera === 7){//baza
            guias.value[i].espesor = parseFloat(newData.espesor).toFixed(2)
            guias.value[i].ancho = parseFloat(newData.ancho).toFixed(2)
            guias.value[i].largo = parseFloat(newData.largo).toFixed(3)
            guias.value[i].cant_despacho = parseInt(newData.cant_despacho)
            guias.value[i].pulg_despacho = parseFloat(((newData.espesor * newData.ancho)/10) * newData.cant_despacho).toFixed(3)
            guias.value[i].cant_recepcion = parseInt(newData.cant_recepcion)
            guias.value[i].pulg_recepcion = parseFloat(((newData.espesor * newData.ancho)/10) * newData.cant_recepcion).toFixed(3)
            guias.value[i].precio = parseInt(newData.precio)
            guias.value[i].total = parseFloat(newData.precio * newData.cant_recepcion * newData.pulg_recepcion).toFixed(3)
            guias.value[i].total_mask = newData.total.toLocaleString('es-CL')
      
          }else if(newData.tmadera === 10){ //lampazo
            guias.value[i].alto = parseFloat(newData.alto).toFixed(2)
            guias.value[i].ancho = parseFloat(newData.ancho).toFixed(2)
            guias.value[i].largo = parseFloat(newData.largo).toFixed(3)
            guias.value[i].cant_despacho = 1
            guias.value[i].mc_despacho = parseFloat((newData.alto * newData.ancho * newData.largo)).toFixed(3)
            guias.value[i].cant_recepcion = 1
            guias.value[i].mc_recepcion = parseFloat((newData.alto * newData.ancho * newData.largo)).toFixed(3)
            guias.value[i].precio = parseInt(newData.precio)
            guias.value[i].total = parseFloat(newData.precio * newData.cant_recepcion * newData.mc_recepcion).toFixed(3)
            guias.value[i].total_mask = newData.total.toLocaleString('es-CL')
          }else if(newData.tmadera === 11){ //mruma
            guias.value[i].alto = parseFloat(newData.alto).toFixed(2)
            guias.value[i].ancho = parseFloat(newData.ancho).toFixed(2)
            guias.value[i].largo = parseFloat(newData.largo).toFixed(3)
            guias.value[i].cant_despacho = 1
            guias.value[i].mruma_despacho = parseFloat((newData.alto * newData.ancho * newData.largo)/2.44).toFixed(3)
            guias.value[i].cant_recepcion = 1
            guias.value[i].mruma_recepcion = parseFloat((newData.alto * newData.ancho * newData.largo)/2.44).toFixed(3)
            guias.value[i].precio = parseInt(newData.precio)
            guias.value[i].total = parseFloat(newData.precio * newData.cant_recepcion * newData.mruma_recepcion).toFixed(3)
            guias.value[i].total_mask = newData.total.toLocaleString('es-CL')
          }else if(newData.tmadera !== 9){ //otros
            guias.value[i].mc_despacho = parseFloat(newData.mc_despacho).toFixed(3)
            guias.value[i].mc_recepcion = parseFloat(newData.mc_recepcion).toFixed(3)
            guias.value[i].cant_despacho = 1
            guias.value[i].cant_recepcion = 1
            guias.value[i].precio = parseInt(newData.precio)
            guias.value[i].total = parseFloat(newData.precio * newData.cant_recepcion * newData.mc_recepcion).toFixed(3)
            guias.value[i].total_mask = newData.total.toLocaleString('es-CL')          
          }
				}
			})
      //Store cambios solo para guias con cambios
      guiasCambio = []
      guias.value.forEach(function (guia, i) {
				guia = toRaw(guia)
				if(guia.folio === guias.value[index].folio){
					guiasCambio[i] = guia
        }
			})
      actualizaGuia(form, guiasCambio)
    }

    const setFscCodigo = (event, idx) => {  
      guias.value[idx].fsc_codigo = event.value.codigo
    }

    const searchFsc = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredFscs.value = [...fscs.value]
        } else {
          filteredFscs.value = fscs.value.filter((fsc) => {
            return fsc.nombre
              .toLowerCase()
              .startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const unSelectProductos = (idx) => {
      if (!idx) form.producto = []
    }

    const searchProveedor = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProveedores.value = [...proveedores.value]
        } else {
          filteredProveedores.value = proveedores.value.filter((proveedor) => {
            return proveedor._razon_social
              .toLowerCase()
              .includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const searchProducto = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredProductos.value = [...productos.value]
        } else {
          filteredProductos.value = productos.value.filter((producto) => {
            return producto._nombre
              .toLowerCase()
              .includes(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    const formatCurrency = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value)
    }

    const formatNumber = (value) => {
      return new Intl.NumberFormat('es-CL').format(Number(value))
    }

    const formatFecha = (value) => {
      return moment(value).format("DD-MM-YY")
    }

    const buscarGuia = () => {
      // messages.value = []
      showcol.value = ''
      let proveedor = isProxy(form.proveedor) ? toRaw(form.proveedor) : null
      let producto = isProxy(form.producto) ? toRaw(form.producto).id : 0
      getGuias(
        proveedor.id,
        form.fdesde.toLocaleDateString("es-CL"),
        form.fhasta.toLocaleDateString("es-CL"),
        producto
      )
    }

    const totCantidad = computed(() => {
      let tcantidad = 0;
      for(let ln of guias.value) {
        tcantidad += Number(ln.cant_recepcion)
      }
      return formatNumber(tcantidad)
    })    

    const totM3Un = computed(() => {
      let tum = 0;
      for(let ln of guias.value) {
        if(ln.tmadera === 7){
          tum += Number(ln.pulg_recepcion)
        }else if(ln.tmadera === 10){
          tum += Number(ln.mruma_recepcion)
        }else if(ln.tmadera === 11){  
          tum += Number(ln.mruma_recepcion) 
        }else{
          tum += Number(ln.mc_recepcion)
        }                 
      }
      return formatNumber(tum)
    })        

    const totMRuma = computed(() => {
      let tum = 0;
      for(let ln of guias.value)
        tum += Number(ln.mruma_recepcion)                  
      return formatNumber(tum)
    })        

    const totGuia = computed(() => {
      let tguia = 0;
      for(let ln of guias.value)
        tguia += Number(ln.total)
      return formatCurrency(tguia)
    }) 

    onMounted(getCRUD)

    return {
      dt,
      dtg,
      loading,
      saving,
      fsc,
      fscs,
      productos,
      guias,
      proveedores,
      showcol,
      submitted,
      filteredProveedores,
      filteredFscs,
      filteredProductos,
      selectedProductos,
      selectedGuias,
      porciva,
      form,
      messages,
      un_producto,
      editingRows,
      searchProveedor,
      onRowEditSave,
      onRowEditInit,
      searchProducto,
      unSelectProductos,
      searchFsc,
      setFscCodigo,
      actualizaGuia,
      formatCurrency,
      formatFecha,
      buscarGuia,
      totCantidad,
      totM3Un,
      totMRuma,
      totGuia,
      formatNumber,
      colspan    
    }
  },
}
</script>
