import { createRouter, createWebHistory } from "vue-router";
// Vistas
import RecepcionesPagos from '../components/recepciones/RecepcionesPagos'
// import VentasClasif from '../components/ingresos/IngresosClasif.vue'
import VentasCobro from '../components/ingresos/IngresosCobro.vue'
import RecepcionInforme from '../components/informes/RecepcionInforme'
import DespachoInforme from '../components/informes/DespachoInforme'
import ProduccionInforme from '../components/informes/ProduccionInforme'
import ProdDiaIndex from '../components/produccion/ProdDiaIndex'
import ComprasClasif from '../components/gastos/ComprasClasif'
import ComprasPago from '../components/gastos/ComprasPago'
import GastosIndex from '../components/cruds/GastosIndex'
import ComprasSII from '../components/rpasii/Compras'
import VentasSII from '../components/rpasii/Ventas'
import AGRecepcionesIndex from '../components/recepciones/AGRecepcionesIndex'
import AGDespachosIndex from '../components/despachos/AGDespachosIndex'
import FacturasIndex from '../components/procesos/FacturasIndex'
import ProveedorPreciosIndex from '../components/recepciones/ProveedorPreciosIndex'
import RecepcionesIndex from '../components/recepciones/RecepcionesIndex'
import DocsEmitidosIndex from '../components/procesos/DocsEmitidosIndex'
import DespachosIndex from '../components/despachos/DespachosIndex'
import ContratosIndex from '../components/cruds/ContratosIndex'
import TipoDocumentosIndex from '../components/cruds/TipoDocumentosIndex'
import ProductosIndex from '../components/cruds/ProductosIndex'
import OrigenDestinosIndex from '../components/cruds/OrigenDestinosIndex'
import DespachadoresIndex from '../components/cruds/DespachadoresIndex'
import ChoferesIndex from '../components/cruds/ChoferesIndex'
import EmpresasIndex from '../components/cruds/EmpresasIndex'
import UsersIndex from '../components/seguridad/UsersIndex'
import Dashboard from '../components/home/Dashboard'

const routes = [
	{
		path: '/home/',
		name: 'home.admin',
		component: Dashboard
	},
	{
		path: '/users',
		name: 'users.index',
		component: UsersIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/empresas',
		name: 'empresas.index',
		component: EmpresasIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/choferes',
		name: 'choferes.index',
		component: ChoferesIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/despachadores',
		name: 'despachadores.index',
		component: DespachadoresIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/odestinos',
		name: 'odestinos.index',
		component: OrigenDestinosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/productos',
		name: 'productos.index',
		component: ProductosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/tdocumentos',
		name: 'tdocumentos.index',
		component: TipoDocumentosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/contratos',
		name: 'contratos.index',
		component: ContratosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/despachos',
		name: 'despachos.index',
		component: DespachosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/demitidos',
		name: 'demitidos.index',
		component: DocsEmitidosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/pprecios',
		name: 'pprecios.index',
		component: ProveedorPreciosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/facturas/:guia?',
		name: 'facturas.index',
		component: FacturasIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/agdespachos',
		name: 'agdespachos.index',
		component: AGDespachosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/rpacompras',
		name: 'rpacompras.index',
		component: ComprasSII,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/rpaventas',
		name: 'rpaventas.index',
		component: VentasSII,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/produccion',
		name: 'produccion.index',
		component: ProdDiaIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/gastos',
		name: 'gastos.index',
		component: GastosIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/cpagos',
		name: 'cpagos.index',
		component: ComprasPago,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
  {
    path: '/vcobros',
    name: 'vcobros.index',
    component: VentasCobro,
    beforeEnter: (to, from, next) => {
      if ([0, 1].includes(window.Laravel.user.seg_rol_id))
        next()
      else
        next(false)
    }
  },
  {
    path: '/cclasif',
    name: 'cclasif.index',
    component: ComprasClasif,
    beforeEnter: (to, from, next) => {
      if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
        next()
      else
        next(false)
    }
  },
	//PERFIL RECEPCIONISTA
	{
		path: '/recepciones',
		name: 'recepciones.index',
		component: RecepcionesIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/agrecepcion',
		name: 'agrecepcion.index',
		component: AGRecepcionesIndex,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
  {
		path: '/recepcion/rpagos/:rut?/:fdesde?/:fhasta?',
		name: 'recepcion.pagos',
		component: RecepcionesPagos,
		beforeEnter: (to, from, next) => {
			if ([0, 1].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/informes/recepcion',
		name: 'informes.recepcion',
		component: RecepcionInforme,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 2, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	//PERFIL CONSULTAS
	{
		path: '/informes/prod',
		name: 'informes.produccion',
		component: ProduccionInforme,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	},
	{
		path: '/informes/despacho',
		name: 'informes.despacho',
		component: DespachoInforme,
		beforeEnter: (to, from, next) => {
			if ([0, 1, 3].includes(window.Laravel.user.seg_rol_id))
				next()
			else
				next(false)
		}
	}
]

export default createRouter({
	mode: 'history',
	history: createWebHistory(),
	routes
})
