import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useRecepciones() {

  const recepcion = ref([])
  const chofer = ref([])
  const tipoMadera = ref([])
  const fsc = ref([])
  const recepciones = ref([])
  const choferes = ref([])
  const tipoMaderas = ref([])
  const fscs = ref([])
  const recepcionesDelete = ref([])
  const porciva = ref()
  const nextid = ref()
  const messages = ref([])

  const client = useClient()

  const proveedores = ref([])
  const recepcionistas = ref([])
  const tipoDocReceps = ref([])
  const destinos = ref([])
  const origenes = ref([])
  const transportistas = ref([])
  const productos = ref([])
  const selectedProductos = ref([])
  const lineas = ref([])
  const first = ref(0)
  const lazyParams = ref({})

  const columns = ref([
    { field: 'producto_obj', header: 'Cod.', hidden: true },
    { field: 'producto', header: 'Producto', hidden: false },
    { field: 'diametro', header: 'Diametro', hidden: true },
    { field: 'espesor', header: 'Espesor', hidden: true },
    { field: 'alto', header: 'Alto', hidden: true },
    { field: 'largo', header: 'Largo', hidden: true },
    { field: 'ancho', header: 'Ancho', hidden: true },
    { field: 'cant_despacho', header: 'Cant.Despacho', hidden: true },
    { field: 'mc_despacho', header: 'M3.Despacho', hidden: true },
    { field: 'mruma_despacho', header: 'MRuma.Despacho', hidden: true },
    { field: 'pulg_despacho', header: 'Pulg.Despacho', hidden: true },
    { field: 'cant_recepcion', header: 'Cant.Recepción', hidden: true },
    { field: 'mc_recepcion', header: 'M3.Recepción', hidden: true },
    { field: 'mruma_recepcion', header: 'MRuma.Recepción', hidden: true },
    { field: 'pulg_recepcion', header: 'Pulg.Recepción', hidden: true },
    { field: 'precio', header: 'Valor', hidden: true },
    { field: 'total', header: 'Total', hidden: true },
    { field: 'total_mask', header: 'T.Mask', hidden: true }
  ])

  const dt = ref({})
  const loading = ref(false)
  const totalRecords = ref(0)
  const saving = ref(false)
  const csr1 = ref(0)
  const csr2 = ref(0)

  const selectedRecepciones = ref()
  const deleteRecepcionesDialog = ref(false)
  const deleteRecepcionDialog = ref(false)
  const recepcionDialog = ref(false)
  const submitted = ref(false)

  const filteredProveedores = ref()
  const filteredRecepcionistas = ref()
  const filteredFscs = ref()
  const filteredChoferes = ref()
  const filteredTipoMaderas = ref()
  const filteredTipoDocReceps = ref()
  const filteredDestinos = ref()
  const filteredOrigenes = ref()
  const filteredProductos = ref()
  const filteredTransportistas = ref()

  const storeRecepcion = async (form) => {
    saving.value = true
    let id = form.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/recepciones/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          recepcionDialog.value = false
          loadLazyData()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/recepciones`, { 'data': { ...form } })
        .then(() => {
          recepcionDialog.value = client.dialog.value
          recepcion.value = client.entity.value
          saving.value = client.saving.value
          loadLazyData()
        })
    }
  }

  const deleteRecepcion = async () => {
    client.remove(`/api/recepciones/${recepcion.value.id}`)
      .then(response => {
        recepciones.value = recepciones.value.filter(val => val.id !== recepcion.value.id)
        deleteRecepcionDialog.value = false
        recepcion.value = {}
      })
  }

  const deleteSelectedRecepciones = async () => {
    recepcionesDelete.value = selectedRecepciones.value.map(({ id }) => id)
    client.remove(`/api/recepciones/destroy/mass`, { data: recepcionesDelete.value })
      .then(response => {
        recepciones.value = recepciones.value.filter(
          (val) => !selectedRecepciones.value.includes(val)
        )
        deleteRecepcionesDialog.value = false
        selectedRecepciones.value = null
        recepcionesDelete.value = null
      })
  }

  const getCodFsc = async (id) => {
    loading.value = true
    client.get(`/api/empresas/${id}/fsccode`).then(response => {
      fscs.value = response.data.data.fscs
      loading.value = false
    }) 
  }

  const getProdsRecep = async (oid,pid,tid) => {
    loading.value = true
    if(oid !== undefined && pid !== undefined && tid !== undefined)
      client.get(`/api/productos/${oid}/${pid}/${tid}/precios`).then(response => {
        productos.value = response.data.data.productos
        selectedProductos.value = response.data.data.productos
        loading.value = false
      })
  }

  const loadLazyData = (event) => {
    lazyParams.value = { ...lazyParams.value, first: event?.first || first.value }

    client.get(`/api/recepciones/all/${lazyParams.value.first}/${lazyParams.value.rows}`, { data: lazyParams.value }).then(response => {
      recepciones.value = response.data.data.recepciones
      recepcionistas.value = response.data.data.recepcionistas
      choferes.value = response.data.data.choferes
      tipoMaderas.value = response.data.data.tipoMaderas
      tipoDocReceps.value = response.data.data.tipoDocReceps
      destinos.value = response.data.data.origenes
      origenes.value = response.data.data.origenes
      fscs.value = response.data.data.fscs
      proveedores.value = response.data.data.proveedores
      transportistas.value = response.data.data.transportistas
      
      porciva.value = response.data.data.porciva
      totalRecords.value = response.data.data.totalRecords
      loading.value = false
    })   
    .catch(function (error) { 
      loading.value = false
    })         
  }

  const onPage = (event) => {
    lazyParams.value = event
    loadLazyData(event)
  }

  const onSort = (event) => {
    lazyParams.value = event
    loadLazyData(event)
  }

  const onFilter = (event) => {
    lazyParams.value.filters = filters.value 
    loadLazyData(event)
  }

  const getPDFSII = async (d) => {
    loading.value = true
    if(d.folio_doc_recep !== undefined)
      axios.get(`/api/sii/${d.tipoDocRecep.codigo_sii}/${d.folio_doc_recep}/R/${d.proveedor.rut_dv}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }
  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'id': { value: null, matchMode: FilterMatchMode.EQUALS },
    'tipoMadera': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'tipoDocRecep': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'folio_doc_recep': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha_doc_recep': { value: null, matchMode: FilterMatchMode.EQUALS },    
    'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },    
    'proveedor': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'origen': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'destino': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'transportista': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'neto': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'iva': { value: null, matchMode: FilterMatchMode.EQUALS },  
    'total': { value: null, matchMode: FilterMatchMode.EQUALS },  
  })

  const initFilters = () => {
    filters.value = {
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

      'id': { value: null, matchMode: FilterMatchMode.EQUALS },
      'tipoMadera': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'tipoDocRecep': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'folio_doc_recep': { value: null, matchMode: FilterMatchMode.EQUALS },
      'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },
      'fecha_doc_recep': { value: null, matchMode: FilterMatchMode.EQUALS },    
      'rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },    
      'proveedor': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'origen': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'destino': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'transportista': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'neto': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'iva': { value: null, matchMode: FilterMatchMode.EQUALS },  
      'total': { value: null, matchMode: FilterMatchMode.EQUALS },  
    }
    lazyParams.value.filters = filters.value
    loadLazyData()
  }

  return {
    recepcion,
    fsc,
    chofer,
    tipoMadera,
    porciva,
    nextid,
    recepciones,
    fscs,
    choferes,
    tipoMaderas,
    proveedores,
    recepcionistas,
    tipoDocReceps,
    destinos,
    origenes,
    dt,
    loading,
    saving,
    csr1,
    csr2,
    filters,
    initFilters,
    selectedRecepciones,
    deleteRecepcionesDialog,
    deleteRecepcionDialog,
    recepcionDialog,
    submitted,
    filteredProveedores,
    filteredFscs,
    filteredRecepcionistas,
    filteredChoferes,
    filteredTipoMaderas,
    filteredTipoDocReceps,
    filteredDestinos,
    filteredOrigenes,
    filteredProductos,
    filteredTransportistas,
    selectedProductos,
    productos,
    transportistas,
    lineas,
    columns,
    messages,
    getCodFsc,
    getProdsRecep,
    deleteSelectedRecepciones,
    storeRecepcion,
    deleteRecepcion,
    totalRecords,
    first,
    lazyParams,
    loadLazyData,
    onPage,
    onSort,
    onFilter,
    getPDFSII
  }
}