import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import useClient from '../client/apiClient'

export default function useComprasPago() {
  const comprasSII = ref([])
  const notadcSII = ref([])
  const messages = ref([])
  const proveedores = ref([])
  const tipoDocumentos = ref([])   

  const router = useRouter()
  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)
  const deletePagoDialog = ref({visible:false, index:null, id: null})
  const submitted = ref(false)
  const selectedNotas = ref()

  const pagoDialog = ref(false)
  const compra = ref([])
  const tpagos = ref([])
  const pagos = ref([])
  const filteredTipoPagos = ref()

  const modalidades = ref(['TRANSFERENCIA', 'EFECTIVO', 'CHEQUE', 'TARJETA DEBITO', 'TARJETA CRÉDITO', 'SALDO NOTA CRÉDITO/DÉBITO'])
  const filteredModalidades = ref()

  const estados = ref(['PENDIENTE','PAGADO','VENCIDO'])
  const filteredEstados = ref()

  const lineas = ref([])

  const getCompraSII = async (rut,fdesde, fhasta, edo) => {
    loading.value = true
    comprasSII.value = []
    client.get(`/api/compras/${rut}/${fdesde}/${fhasta}/${edo}/filtro`).then(response => {
      notadcSII.value = response.data.data.notas
      tpagos.value = response.data.data.tpagos
      tipoDocumentos.value = response.data.data.tipoDocumentos
      comprasSII.value = response.data.data.compras
      // let detalles
      response.data.data.compras.map((compra, idx) => {
        let detalles = response.data.data.notas.filter(
          (nota) => {
            if('55 56 111 60 61 112'.match(nota.codigo_sii) && nota.rut_proveedor === compra.rut_proveedor) {
              return nota
            }
          }
        )
        comprasSII.value[idx] = {notasdc: detalles, ...compra}
      })
      loading.value = false
    })
  }

  const storePagos = async (form,rut, fdesde, fhasta, edo=0) => {
    saving.value = true
    client.post(`/api/compras`, { 'data': { ...form } })
      .then((response) => {
        if(response.status === 200){
          saving.value = false
          getCompraSII(rut, fdesde, fhasta, edo)
          pagoDialog.value = false
        }else{
          //Mostrar razon del error y habilita de nuevo el boton guardar
          pagoDialog.value = true
          // pagoDialog.value = client.entity.value
          saving.value = false          
        } 
      })     
  }

  const getProveedores = async () => {
    client.get(`/api/empresas/prov/getgto`).then(response => {
      proveedores.value = response.data.data.empresas
    })
  }

  const getPDFSII = async (d) => {
    loading.value = true
    if(d.folio !== undefined)
      axios.get(`/api/sii/${d.codigo_sii}/${d.folio}/R/${d.rut_proveedor}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${d.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }

  const deletePago = async (index) => {
    client.remove(`/api/compras/${deletePagoDialog.value.id}/delpago`)
      .then(response => {
        let row = {
          'id': null,
          'modalidad': null,
          'referencia': 0,
          'porc_abono': 0,
          'monto_abono': null,
          'fecha_vence': null,
          'fecha_pago': null,
          'cuota': '',
          'entidad': '',
          'observacion': '',
          'estado': ''
        }

        lineas.value[index] = row
        lineas.value.splice(index, 1)
        if(lineas.value.length === 0) lineas.value.pop()

        messages.value = []
        deletePagoDialog.value.visible = false
        pagoDialog.value = false
      })      
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'tipo_doc': { value: null, matchMode: FilterMatchMode.EQUALS },
    'tipo_venta': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'fecha_docto': { value: null, matchMode: FilterMatchMode.EQUALS }, 
    'fecha_recepcion': { value: null, matchMode: FilterMatchMode.EQUALS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS }, 
    'rut_proveedor': { value: null, matchMode: FilterMatchMode.EQUALS }, 
    'razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'monto_exento': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva_no_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_iva_recupera': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'monto_total': { value: null, matchMode: FilterMatchMode.EQUALS },
    'estado': { value: null, matchMode: FilterMatchMode.CONTAINS }
  })

  return {
    router,
    comprasSII,
    notadcSII,
    dt,
    loading,
    saving,
    submitted,
    filters,
    pagoDialog,
    compra,
    lineas,
    tpagos,
    filteredTipoPagos,
    messages,
    modalidades,
    estados,
    filteredEstados,
    filteredModalidades,
    pagos,
    proveedores,
    getCompraSII,
    storePagos,
    getProveedores,
    getPDFSII,
    deletePagoDialog,
    deletePago,
    tipoDocumentos,
    selectedNotas
  }
}
