import { ref, toRaw } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'
import axios from 'axios'

export default function useDocsEmitidos() {
  const client = useClient()
  
  const nota = ref([])
  const notas = ref([])
  const rango = ref([])
  const notasDelete = ref([])
  const selectedNotas = ref()
  const deleteNotasDialog = ref(false)
  const deleteNotaDialog = ref(false)
  const notaDialog = ref(false)
  const porciva = ref()
  const factura = ref({})
  const expandedRows = ref([])
  const messages = ref([])

  const clientes = ref([])
  const despachadores = ref([])
  const tipoDocumentos = ref([]) 
  const facturas = ref([])

  const filteredClientes = ref()
  const filteredDespachadores = ref()
  const filteredFacturas = ref()
  const filteredTipoDocumentos = ref()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)
  const submitted = ref(false)
  const selectedMotivo = ref()
  const lineas = ref([])
  const selectedLineas = ref([])

  const motivos = ref([])

  const columns = ref([
    { field: 'idx', header: 'Ln.', hidden: false },
    { field: 'producto_obj', header: 'Cod.', hidden: true },
    { field: 'producto', header: 'Producto', hidden: false },
    { field: 'observacion', header: 'Observ.', hidden: false },
    { field: 'espesor', header: 'Espesor', hidden: false },
    { field: 'ancho', header: 'Ancho', hidden: false },
    { field: 'largo', header: 'Largo', hidden: false },
    { field: 'cant_despacho', header: 'Cant.Despacho', hidden: false },
    { field: 'mc_despacho', header: 'M3.Despacho', hidden: false },
    { field: 'pulg_despacho', header: 'Pulg.Despacho', hidden: false },
    { field: 'precio', header: 'Valor', hidden: false },
    { field: 'tipo_cambio', header: 'Tipo Cambio', hidden: false },
    { field: 'total', header: 'Total', hidden: true },
    { field: 'total_mask', header: 'Total', hidden: false }
  ])  

  const getCRUD = async (event) => {
    if(event && rango.value.length > 1){
      loading.value = true
      facturas.value = []

      let fdesde = rango.value[0].toLocaleDateString('es-CL')
      let fhasta = rango.value[1] ? rango.value[1].toLocaleDateString('es-CL') : fdesde

      client.get(`/api/demitidos/${fdesde}/${fhasta}/all`).then(response => {
        facturas.value = response.data.data.facturas
        tipoDocumentos.value = response.data.data.tipoDocumentos
        clientes.value = response.data.data.clientes
        porciva.value = response.data.data.porciva

        loading.value = false
      })
    }
  }

  const storeNota = async (form) => {
    saving.value = true
    client.post(`/api/demitidos`, { 'data': { ...form } })
    .then((response) => {
      if(response.status === 200){
        notaDialog.value = client.dialog.value
        nota.value = client.entity.value
        saving.value = client.saving.value
        getCRUD()
      }else{
        //Mostrar razon del error y habilita de nuevo el boton guardar
        notaDialog.value = true
        nota.value = client.entity.value
        saving.value = false          
      }
    })    
  }

  const deleteNota = async () => {
    client.remove(`/api/demitidos/${nota.value.id}`)
      .then(response => {
        notas.value = notas.value.filter(val => val.id !== nota.value.id)
        deleteNotaDialog.value = false
        nota.value = {}
        getCRUD()
      })
  }

  const deleteSelectedNotas = async () => {
    notasDelete.value = selectedNotas.value.map(({ id }) => id)
    client.remove(`/api/demitidos/destroy/mass`, { data: notasDelete.value })
      .then(response => {
        notas.value = notas.value.filter(
          (val) => !selectedNotas.value.includes(val)
        )
        deleteNotasDialog.value = false
        selectedNotas.value = null
        notasDelete.value = null
      })
  }
  
  const getPDFSII = async (data) => {
    loading.value = true
    let tdoc = data.tipo_documento_id ? toRaw(tipoDocumentos.value.filter(function (itm) { return [parseInt(data.tipo_documento_id)].indexOf(itm.id) > -1 }))[0] : null
    let rut_dv = '76492850-4'

    if(data.folio !== undefined)
      axios.get(`/api/demitidos/${tdoc.codigo_sii}/${data.folio}/E/${rut_dv}/pdfsii`, { responseType: 'arraybuffer' }).then(response => {
        if(response.status === 200){
          let blob = new Blob([response.data], { type:"application/pdf" })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${data.folio}.pdf`
          link.click()
        }
      })
      loading.value = false
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

    'cliente.rut_dv': { value: null, matchMode: FilterMatchMode.EQUALS },
    'cliente.razon_social': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'tipoDocumento.codigo': { value: null, matchMode: FilterMatchMode.EQUALS },
    'folio': { value: null, matchMode: FilterMatchMode.EQUALS },
    'fecha': { value: null, matchMode: FilterMatchMode.EQUALS },
    'neto': { value: null, matchMode: FilterMatchMode.EQUALS },
    'iva': { value: null, matchMode: FilterMatchMode.EQUALS },
    'total': { value: null, matchMode: FilterMatchMode.EQUALS },
    'debitoCreditoNotas': { value: null, matchMode: FilterMatchMode.IN }
  })

  return {
    nota,
    notas,
    notasDelete,
    selectedNotas,
    deleteNotasDialog,
    deleteNotaDialog,
    notaDialog,
    clientes,
    despachadores,
    tipoDocumentos,
    factura,
    facturas,
    porciva,
    dt,
    loading,
    saving,
    submitted,
    filters,
    filteredClientes,
    filteredFacturas,
    filteredDespachadores,
    filteredTipoDocumentos,
    getCRUD,
    storeNota,
    deleteNota,
    deleteSelectedNotas,
    selectedMotivo,
    motivos,
    columns,
    lineas,
    selectedLineas,
    getPDFSII,
    expandedRows,
    rango,
    messages
  }
}