import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'
import useClient from '../client/apiClient'

export default function useOrigenDestinos() {
  const origenDestino = ref([])
  const origenDestinos = ref([])
  const origenDestinosDelete = ref([])

  const client = useClient()

  const dt = ref({})
  const loading = ref(false)
  const saving = ref(false)

  const selectedOrigenDestinos = ref()
  const deleteOrigenDestinosDialog = ref(false)
  const deleteOrigenDestinoDialog = ref(false)
  const origenDestinoDialog = ref(false)
  const submitted = ref(false)

  const getCRUD = async () => {
    loading.value = true
    client.get(`/api/odestinos/all/crud`).then(response => {
      origenDestinos.value = response.data.data.origenDestinos
      loading.value = false
    })
  }

  const storeOrigenDestino = async (form) => {
    saving.value = true
    let id = origenDestino.value.id
    if (id) {
      //Esta editando el registro
      client.put(`/api/odestinos/${id}`, { 'data': { ...form } })
        .then(() => {
          saving.value = !saving.value
          origenDestinoDialog.value = false
          getCRUD()
        })
    } else {
      //Esta creando el registro
      client.post(`/api/odestinos`, { 'data': { ...form } })
        .then(() => {
          origenDestinoDialog.value = client.dialog.value
          origenDestino.value = client.entity.value
          saving.value = client.saving.value
          getCRUD()
        })
    }
  }

  const deleteOrigenDestino = async () => {
    client.remove(`/api/odestinos/${origenDestino.value.id}`)
      .then(response => {
        origenDestinos.value = origenDestinos.value.filter(val => val.id !== origenDestino.value.id)
        deleteOrigenDestinoDialog.value = false
        origenDestino.value = {}
      })
  }

  const deleteSelectedOrigenDestinos = async () => {
    origenDestinosDelete.value = selectedOrigenDestinos.value.map(({ id }) => id)
    client.remove(`/api/odestinos/destroy/mass`, { data: origenDestinosDelete.value })
      .then(response => {
        origenDestinos.value = origenDestinos.value.filter(
          (val) => !selectedOrigenDestinos.value.includes(val)
        )
        deleteOrigenDestinosDialog.value = false
        selectedOrigenDestinos.value = null
        origenDestinosDelete.value = null
      })
  }

  const filters = ref({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  return {
    origenDestino,
    origenDestinos,
    dt,
    loading,
    saving,
    filters,
    selectedOrigenDestinos,
    deleteOrigenDestinosDialog,
    deleteOrigenDestinoDialog,
    origenDestinoDialog,
    submitted,
    deleteSelectedOrigenDestinos,
    getCRUD,
    storeOrigenDestino,
    deleteOrigenDestino
  }
}